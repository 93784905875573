import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBTextArea,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import { getImageURL } from "../../../_helper/image";
import Select from "react-select";
import AddressModel from "../modals/AddressModel";
import {
  useUserBusinessProfileCreate,
  useUserBusinessProfileUpdate,
} from "../../../_helper/actions";
import { secureAxios } from "../../../_helper/auth";
import { useNavigate } from "react-router-dom";
import TaxanomyCategory from "./TaxanomyComponent";

export default function EditInfo({ bid }) {
  const navigate = useNavigate(); 
  const hiddenFileInput = React.useRef(null);
  const [avatar, setAvtar] = useState("");
  const [businessname, setBusinessName] = useState("");
  const [businesstype, setbusinessType] = useState([]);
  const [organisationcategory, setOrganisationCategory] = useState([]);
  const [buisnessCategory, setBuisnessCategory] = useState([]);
  const [buisnessCategorytemp, setBuisnessCategorytemp] = useState([]);
  const [businessdescription, setbusinessDescription] = useState("");
  const [yearofincorporation, setYearofIncorporation] = useState("");
  const [noemployee, setNoEmployee] = useState("");
  const [gst, setGST] = useState("");
  const [url, setUrl] = useState("");
  const [turnover, setTurnover] = useState("");
  const [turnoverdocs, setTurnoverDocs] = useState("");

  const [locationopenModal, setLocationOpenModal] = useState(false);
  const [addressopenModal, setAddressOpenModal] = useState(false);

  const [locationeditid, setlocationEditID] = useState("");
  const [addresseditid, setaddressEditID] = useState("");

  const [locationdata, setLocationData] = useState([]);
  const [addressdata, setAddressData] = useState([]);

  const [groupedOptions, setgroupedOptions] = useState([]);

  const [businessdata1, setbusinessdata1] = useState({});

  const [datastatus, setDataStatus] = useState(false);
  const [userid, setUserId] = useState("");
  const [currentbid, setCurrentBId] = useState("");

  const [data, setData] = useState({
    street_no_1: "",
    street_no_2: "",
    city: "",
    pincode: "",
    country: "",
    state: "",
  });

  const [add1data, setadd1Data] = useState({
    street_no_1: "",
    street_no_2: "",
    city: "",
    pincode: "",
    country: "",
    state: "",
  });

  //CategoryData();

  const Type = [
    { label: "Product", value: 1 },
    { label: "Services", value: 2 },
    { label: "Manufacturing", value: 3 },
    { label: "Outlet", value: 4 },
    { label: "Showroom", value: 5 },
    { label: "Service Centre", value: 6 },
    { label: "Construction & Real Estate", value: 7 },
    { label: "Import Export", value: 8 },
    { label: "Producer", value: 9 },
  ];

  const OrganisationCategory = [
    { label: "Individual", value: 1 },
    { label: "Professional", value: 2 },
    { label: "Corporate", value: 3 },
    { label: "Society", value: 4 },
    { label: "Non Government", value: 5 },
    { label: "Small Business", value: 6 },
    { label: "Micro Business", value: 7 },
    { label: "Startup", value: 8 },
  ];

  const onChange1 = (data) => {
    setData(data);
  };

  const onChange2 = (data) => {
    setadd1Data(data);
  };

  const handleBusinessName = (eve) => {
    setbusinessdata1({ ...businessdata1, business_name: eve.target.value });
    setBusinessName(eve.target.value);
  };

  const handleBusinesstype = (eve) => {
    setbusinessdata1({ ...businessdata1, business_type: eve });
    setbusinessType(eve);
  };

  const handleOrganisationCategory = (eve) => {
    setbusinessdata1({ ...businessdata1, organisation_category: eve });
    setOrganisationCategory(eve);
  };

  const handlebuissnessCategory = (eve) => {
    setBuisnessCategory(eve);
  };

  const handlebuissnessCategorytemp = (eve) => {
    setbusinessdata1({ ...businessdata1, category_temp: eve.target.value });
    setBuisnessCategorytemp(eve.target.value);
  };

  const handleBusinessDescription = (eve) => {
    setbusinessdata1({
      ...businessdata1,
      business_description: eve.target.value,
    });
    setbusinessDescription(eve.target.value);
  };

  const handleOfIncorporation = (eve) => {
    //console.log(new Date(eve.target.value).toISOString())
    setbusinessdata1({
      ...businessdata1,
      Year_of_incorporation: new Date(eve.target.value).toISOString(),
    });
    setYearofIncorporation(new Date(eve.target.value).toISOString());
  };

  const handleNoofEmployee = (eve) => {
    setbusinessdata1({
      ...businessdata1,
      no_of_employee: Number(eve.target.value),
    });
    setNoEmployee(eve.target.value);
  };

  const handleGST = (eve) => {
    setbusinessdata1({ ...businessdata1, gst: eve.target.value });
    setGST(eve.target.value);
  };

  const handleURL = (eve) => {
    setbusinessdata1({ ...businessdata1, url: eve.target.value });
    setUrl(eve.target.value);
  };

  // console.log(businessdata1)

  const sentData = () => {
    // console.log(addressdata)

    if (locationdata.length === 0) {
      setLocationData([data]);

      setLocationOpenModal(false);
    } else {
      setLocationData([...locationdata, data]);

      setLocationOpenModal(false);
    }

    if (locationeditid !== "") {
      const obj = locationdata[locationeditid];
      obj.street_no_1 = data.street_no_1;
      obj.street_no_2 = data.street_no_2;
      obj.city = data.city;
      obj.pincode = data.pincode;
      obj.country = data.country;
      obj.State = data.state;
      setLocationData(locationdata);

      setLocationOpenModal(false);
      setlocationEditID("");
    }
  };

  const sentAddData = () => {
    // console.log(addressdata)

    if (add1data.length === 0) {
      setadd1Data([add1data]);

      setAddressOpenModal(false);
    } else {
      setAddressData([...addressdata, add1data]);

      setAddressOpenModal(false);
    }

    if (addresseditid !== "") {
      const obj = addressdata[addresseditid];
      obj.street_no_1 = add1data.street_no_1;
      obj.street_no_2 = add1data.street_no_2;
      obj.city = add1data.city;
      obj.pincode = add1data.pincode;
      obj.country = add1data.country;
      obj.state = add1data.state;
      setAddressData(addressdata);
      setAddressOpenModal(false);
      setaddressEditID("");
    }
  };

  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;

    // check if a group as the filter string as label
    const groupOptions = groupedOptions.filter(
      (group) =>
        group.label.toLocaleLowerCase().includes(string) ||
        group.label.toLocaleUpperCase().includes(string)
    );

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    setbusinessdata1({ ...businessdata1, business_logo: data.id });
    setAvtar(data.id);
    console.log(data.id);
  });

  const turnoverfileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    setbusinessdata1({ ...businessdata1, last_year_turnover_docs: data.id });
    setTurnoverDocs(data.id);
    console.log(data.id);
  });

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (avatar !== "") {
          console.log(avatar);
          fileMutationdelete.mutate(avatar);
        }

        fileMutation.mutate(formData);
      }
    }
  };

  const onTurnOverFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (turnoverdocs !== "") {
          console.log(turnoverdocs);
          fileMutationdelete.mutate(turnoverdocs);
        }

        turnoverfileMutation.mutate(formData);
      }
    }
  };

  const getData = async () => {
    await secureAxios
      .get("/users/me?fields=*.*,business_profiles.categories.category_id.*")
      .then((data) => {
        if (bid === null) {
          setUserId(data.data.id);
        } else {
          setCurrentBId(data.data.business_profiles[bid]?.id);
          setBusinessName(
            data?.data?.business_profiles[bid]?.business_name === null ||
              data?.data?.business_profiles[bid]?.business_name === undefined
              ? ""
              : `${data.data?.business_profiles[bid]?.business_name}`
          );
          setbusinessType(
            data?.data?.business_profiles[bid].business_type === null ||
              data?.data?.business_profiles[bid].business_type === undefined
              ? []
              : data?.data?.business_profiles[bid].business_type
          );
          setOrganisationCategory(
            data?.data?.business_profiles[bid].organisation_category === null ||
              undefined
              ? []
              : data?.data?.business_profiles[bid].organisation_category
          );
          setbusinessDescription(
            data?.data?.business_profiles[bid].business_description === null ||
              undefined
              ? ""
              : `${data?.data?.business_profiles[bid].business_description}`
          );
          setNoEmployee(
            data?.data?.business_profiles[bid].no_of_employee === null ||
              undefined
              ? ""
              : `${data?.data?.business_profiles[bid].no_of_employee}`
          );
          setYearofIncorporation(
            data?.data?.business_profiles[bid].Year_of_incorporation === null ||
              undefined
              ? ""
              : `${data?.data?.business_profiles[bid].Year_of_incorporation}`
          );
          setGST(
            data?.data?.business_profiles[bid].gst === null || undefined
              ? ""
              : `${data?.data?.business_profiles[bid].gst}`
          );
          setUrl(
            data?.data?.business_profiles[bid].url === null || undefined
              ? ""
              : `${data?.data?.business_profiles[bid].url}`
          );
          setLocationData(
            data?.data?.business_profiles[bid].location === null || undefined
              ? []
              : data?.data?.business_profiles[bid].location
          );
          setAddressData(
            data?.data?.business_profiles[bid].address === null || undefined
              ? []
              : data?.data?.business_profiles[bid].address
          );
          setAvtar(
            data?.data?.business_profiles[bid].business_logo === null ||
              undefined
              ? null
              : data?.data?.business_profiles[bid].business_logo
          );
          setTurnover(
            data?.data?.business_profiles[bid].last_year_turnover === null ||
            data?.data?.business_profiles[bid].last_year_turnover === undefined
              ? null
              : data?.data?.business_profiles[bid].last_year_turnover
          );
          setTurnoverDocs(
            !data?.data?.business_profiles[bid].last_year_turnover_docs
              ? ""
              : data?.data?.business_profiles[bid].last_year_turnover_docs
          );
          setBuisnessCategory(data?.data?.business_profiles[bid]?.categories);
        }
        setDataStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mutation = useUserBusinessProfileUpdate(() =>
    console.log("Profile Updated")
  );

  const PostMutation = useUserBusinessProfileCreate(() => {
    console.log("created");
  });
  let arr = [];

  const onChangecattemp = (data) => {
    //console.log(data);
    arr = [];
    arr.push(data);
  };

  const onSubmitBusinessInfo = () => {
    //console.log(arr)
    const Buissness_arr =
      arr[0] === null || arr[0] === undefined
        ? []
        : arr[0].map((item) => ({ category_id: item.id }));
    const Buissness_cat_main =
      buisnessCategory === null || buisnessCategory === undefined
        ? []
        : buisnessCategory.map((item) => ({
            category_id: item.category_id.id,
          }));

    const BusinessData = {
      categories: [...Buissness_cat_main, ...Buissness_arr],
      location: locationdata,
      address: addressdata,
    };

    var main_data = Object.assign({}, businessdata1, BusinessData);

    console.log(businessdata1);

    if (bid === null) {
      PostMutation.mutate({ ...main_data, user: userid });
    } else {
      mutation.mutate({ id: currentbid, data: main_data });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const removecategory = (id) => {
    // Find the index of the object to be removed
    const DltIndex = buisnessCategory.findIndex(
      (ele) => ele.category_id.id === id
    );

    // Mutating the array like this will not update the view
    buisnessCategory.splice(DltIndex, 1);

    setBuisnessCategory([...buisnessCategory]);
  };
  return !datastatus ? (
    <div className="d-flex justify-content-center">
      <MDBSpinner role="status" color="success" />
    </div>
  ) : (
    <>
      <h4 style={{ marginLeft: "12px" }}>Business Information</h4>

      <AddressModel
        onChange1={onChange1}
        ashow={locationopenModal}
        asetShow={setLocationOpenModal}
        sentData={sentData}
        reciveData={data}
      />

      <AddressModel
        onChange1={onChange2}
        ashow={addressopenModal}
        asetShow={setAddressOpenModal}
        sentData={sentAddData}
        reciveData={add1data}
      />

      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                {
                  <img
                    src={getImageURL(avatar)}
                    className="preview-img"
                    alt="preview"
                    style={{ width: "150px", marginLeft: "18px" }}
                  />
                }
                <div className="d-flex flex-row alignitems-center mb-4 mt-3">
                  <button
                    className="mb-2"
                    onClick={handleClick}
                    style={{
                      width: "auto",
                      padding: "7px",
                      borderColor: "#1b5ff2",
                      color: "#1b5ff2",
                      backgroundColor: "#ffff",
                      borderRadius: "5px",
                    }}
                    status={fileMutation.status}
                    disabled={fileMutation.isLoading}
                  >
                    {" "}
                    <MDBIcon fas icon="upload" className="px-1" />
                    {fileMutation.status === "loading"
                      ? "Uploading Image..."
                      : avatar
                      ? "Change Business Logo"
                      : "Your Business Logo"}
                  </button>
                  <input
                    ref={hiddenFileInput}
                    onChange={onFile}
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Business Name*"
                    id="formControlLg"
                    required
                    type="text"
                    size="md"
                    value={`${businessname}`}
                    onChange={handleBusinessName}
                    autoComplete="off"
                    autoFocus
                  />
                </div>

                <div className="d-flex flex-row alignitems-center mb-4">
                  <Select
                    isMulti
                    isSearchable
                    options={Type}
                    placeholder="You Can Select Multiple Business Types*"
                    className="w-100 mb-2"
                    onChange={handleBusinesstype}
                    value={businesstype}
                  />
                </div>
                <div className="d-flex flex-row alignitems-center mb-4">
                  <Select
                    isMulti
                    isSearchable
                    options={OrganisationCategory}
                    placeholder="You Can Select Multiple Organisation Category*"
                    className="w-100 mb-2"
                    onChange={handleOrganisationCategory}
                    value={organisationcategory}
                  />
                </div>
                <div
                  className="row-lg mb-4 w-100 "
                  style={{
                    position: "relative",
                    border: "1px solid #BDBDBD",
                    borderRadius: "3px",
                    width: "100%",
                    padding: "6px",
                  }}
                >
                  <label
                    style={{
                      color: "#757575",
                      marginBottom: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Saved Buisness Categories
                  </label>
                  <div className="">
                    {buisnessCategory?.map((data, idx) => (
                      <MDBBadge
                        className="text-dark me-2 m-2"
                        color="light"
                        light
                      >
                        {data.category_id.name}{" "}
                        <button
                          style={{ border: "none", backgroundColor: "none" }}
                          onClick={() => {
                            removecategory(data.category_id.id);
                          }}
                        >
                          x
                        </button>
                      </MDBBadge>
                    ))}
                  </div>
                </div>

                <div className=" d-flex flex-row alignitems-center  mb-4 w-100 ">
                  <TaxanomyCategory onChange={onChangecattemp} />
                </div>
                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Year of Incorporation*"
                    id="formControlLg"
                    required
                    type="date"
                    size="md"
                    value={`${yearofincorporation}`.slice(0, 10)}
                    onChange={handleOfIncorporation}
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="No of Employee*"
                    id="formControlLg"
                    required
                    type="number"
                    size="md"
                    defaultValue={1}
                    value={`${noemployee}`}
                    onChange={handleNoofEmployee}
                    autoComplete="off"
                  />
                </div>
                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBTextArea
                    wrapperClass="mb-2 w-100"
                    label="Business Description*"
                    id="formControlLg"
                    required
                    type="text"
                    rows={4}
                    maxLength={1000}
                    style={{
                      fontSize: "14px",
                    }}
                    value={`${businessdescription}`}
                    onChange={handleBusinessDescription}
                  />
                </div>

                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="GST(optional)"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${gst}`}
                    onChange={handleGST}
                    autoComplete="off"
                  />
                </div>

                <div className="d-flex flex-row alignitems-center mb-4">
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Business URL"
                    id="formControlLg"
                    type="url"
                    size="md"
                    value={`${url}`}
                    onChange={handleURL}
                    autoComplete="off"
                  />
                </div>


                {/* {
                  <img
                    src={getImageURL(turnoverdocs)}
                    className="preview-img"
                    alt="preview"
                    style={{ width: "150px", marginLeft: "18px" }}
                  />
                } */}
                <div className="d-flex flex-row alignitems-center mb-4 ">
                  <button
                    className="mb-2"
                    onClick={handleClick}
                    style={{
                      width: "auto",
                      padding: "7px",
                      borderColor: "#1b5ff2",
                      color: "#1b5ff2",
                      backgroundColor: "#ffff",
                      borderRadius: "5px",
                    }}
                    status={fileMutation.status}
                    disabled={fileMutation.isLoading}
                  >
                    {" "}
                    <MDBIcon fas icon="upload" className="px-1" />
                    {fileMutation.status === "loading"
                      ? "Uploading file..."
                      : turnoverdocs
                      ? "Change Uploaded Balance Sheet"
                      : "Upload Your Last Year Balance Sheet"}
                  </button>
                  <input
                    ref={hiddenFileInput}
                    onChange={onTurnOverFile}
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpg, .jpeg, .pdf, .xlsx"
                  />
                </div>

                    <div className="d-flex flex-row alignitems-center mb-4">
                      <MDBInput
                        wrapperClass="mb-2 w-100"
                        label="Turnover of Last Finacial Year(as per the submitted balance sheet)*"
                        id="formControlLg"
                        type="number"
                        size="md"
                        value={Number(`${turnover}`)}
                        onChange={(eve)=>{
                          setbusinessdata1({ ...businessdata1, last_year_turnover: Number(eve.target.value) });
                          setTurnover(eve.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                <button
                  className="mt-1 py-1 mb-4"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setData({
                      street_no_1: "",
                      street_no_2: "",
                      city: "",
                      pincode: "",
                      country: "",
                      state: "",
                    });
                    setLocationOpenModal(!locationopenModal);
                  }}
                >
                  <MDBIcon fas icon="location" className="px-1" />
                  Add Location
                </button>

                {/* Show added all Added Address */}
                {locationdata.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setData({
                            street_no_1: `${value.street_no_1}`,
                            street_no_2: `${value.street_no_2}`,
                            city: `${value.city}`,
                            pincode: `${value.pincode}`,
                            country: `${value.country}`,
                            state: `${value.state}`,
                          });
                          setlocationEditID(`${idx}`);
                          setLocationOpenModal(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          locationdata.splice(idx, 1);
                          setLocationData([...locationdata]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>
                    <p className="" style={{ fontSize: "14px" }}>
                      Street No.1 : {value.street_no_1}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Street No.2 : {value.street_no_2}
                    </p>
                    <p style={{ fontSize: "14px" }}>city : {value.city}</p>
                    <p style={{ fontSize: "14px" }}>State : {value.state} </p>
                    <p style={{ fontSize: "14px" }}>
                      country : {value.country}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      pincode : {value.pincode}
                    </p>
                  </div>
                ))}

                <button
                  className="mt-1 py-1 mb-4"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setadd1Data({
                      street_no_1: "",
                      street_no_2: "",
                      city: "",
                      pincode: "",
                      country: "",
                      state: "",
                    });
                    setAddressOpenModal(!addressopenModal);
                  }}
                >
                  <MDBIcon fas icon="address-book" className="px-1" />
                  Add Address
                </button>

                {/* Show added all Added Address */}
                {addressdata.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setadd1Data({
                            street_no_1: `${value.street_no_1}`,
                            street_no_2: `${value.street_no_2}`,
                            city: `${value.city}`,
                            pincode: `${value.pincode}`,
                            country: `${value.country}`,
                            state: `${value.state}`,
                          });
                          setaddressEditID(`${idx}`);
                          setAddressOpenModal(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          addressdata.splice(idx, 1);
                          setAddressData([...addressdata]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>
                    <p className="" style={{ fontSize: "14px" }}>
                      Street No.1 : {value.street_no_1}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Street No.2 : {value.street_no_2}
                    </p>
                    <p style={{ fontSize: "14px" }}>city : {value.city}</p>
                    <p style={{ fontSize: "14px" }}>State : {value.state} </p>
                    <p style={{ fontSize: "14px" }}>
                      country : {value.country}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      pincode : {value.pincode}
                    </p>
                  </div>
                ))}

                <button
                  className="mt-4 py-1"
                  style={{
                    width: "8rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={onSubmitBusinessInfo}
                >
                  {mutation.isLoading || PostMutation.isLoading ? (
                    <MDBSpinner color="light" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                {mutation.isSuccess ? (
                  <div
                    class="alert alert-success text-center mt-2"
                    role="alert"
                  >
                    <i class="fa fa-info-circle"></i> Data Saved Successfully!
                  </div>
                ) : (
                  ""
                )}

                {mutation.isSuccess ? navigate(`/profile/view`) : ""}
                {PostMutation.isSuccess
                  ? navigate("/profile/manage-business-profile")
                  : ""}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
