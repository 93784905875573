import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function MemberShipSetup() {
  const navigate = useNavigate();
  return (
    <>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Settings</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Setup</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }} active>
          Membership Setup
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>

      <h4 className="my-4" style={{ margin: "12px" }}>Membership Setup</h4>

      <MDBContainer fluid className="px-2 ">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-4 w-100 d-flex flex-column">
                <div>
                  <button
                    className="py-2 px-3"
                    style={{
                      color: "white",
                      backgroundColor: "#F58A2E",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={() => {
                      navigate(
                        "/profile/membership/upgrade-verification/setup-document-type"
                      );
                    }}
                  >
                    <MDBIcon fas icon="edit" className="px-1" /> Maintain Document type
                  </button>
                </div>
                <div className="mt-4">
                  <button
                    className="py-2 px-4"
                    style={{
                      color: "white",
                      backgroundColor: "#F58A2E",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={() => {
                      navigate(
                        "/profile/membership/upgrade-verification/setup-chapters"
                      );
                    }}
                  >
                    <MDBIcon fas icon="edit" className="px-1" /> Maintain Chapters
                  </button>
                </div>
                <div className="mt-4">
                  <button
                    className="py-2 px-4"
                    style={{
                      color: "white",
                      backgroundColor: "#F58A2E",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={() => {
                      navigate(
                        "/profile/membership/upgrade-verification/setup-tags"
                      );
                    }}
                  >
                    <MDBIcon fas icon="edit" className="px-1" /> Maintain Tags
                  </button>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
