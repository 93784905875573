import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { secureAxios } from "../../_helper/auth";
import { getImageURL } from "../../_helper/image";
import OrganisationDetailPage from "./BusinessProfileComponents/OrganisationDetailPage";
import Aboutus from "./DetailPageComponents/Aboutus";
import EducationInformation from "./DetailPageComponents/EducationInformation";
import HeaderCard from "./DetailPageComponents/HeaderCard";
import TabInfo from "./DetailPageComponents/TabInfo";
import WorkExperience from "./DetailPageComponents/WorkExperience";

export default function MyProfileView() {
  const navigate = useNavigate();
  const [profiledata, setProfileData] = useState({});
  const [businessdata, setBusinessData] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const getBusinessData = () => {
    secureAxios
      .get(
        `/users/me/?fields=business_profiles.*,business_profiles.categories.category_id.*`
      )
      .then((data) => {
        console.log(data.data);
        setBusinessData(data.data.business_profiles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    secureAxios
      .get(
        `users/me?fields=*,user.*,user.role.name,profile.chapter.*,profile.*,role.*`
      )
      .then((data) => {
        console.log(data.data);
        //console.log(data.data.profile[0]);
        setProfileData(data.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getBusinessData();
  }, []);

  console.log(businessdata);
  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}>Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem style={{ cursor: "default" }} active>
            My Profile
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </div>
      <div className="d-flex">
        <button
          className="text-center text-white rounded border-0 px-4 py-2"
          style={{
            marginLeft: "auto",
            marginRight: "15px",
            fontSize: "14px",
            marginTop: "0px",
            //padding: '2px',
            borderRadius: "5px",
            marginBottom: "2px",
            backgroundColor: "#F58A2E",
          }}
          onClick={() => {
            profiledata?.user_type === "individual"
              ? navigate("/profile/manage-business-profile")
              : navigate("/profile/edit-business-profile/0");
          }}
        >
          <MDBIcon far icon="edit" />
          <span className="px-1 "></span>
          Manage Business Profiles
        </button>
        {profiledata?.user_type === "individual" ? (
          <button
            className="text-center text-white  border-0 px-4 py-2"
            style={{
              //marginLeft: "auto",
              marginRight: "10px",
              fontSize: "14px",
              marginTop: "0px",
              borderRadius: "5px",
              marginBottom: "2px",
              backgroundColor: "#F58A2E",
            }}
            onClick={() => {
              navigate("/profile/edit-profile");
            }}
          >
            <MDBIcon far icon="edit" />
            <span className="px-1 "></span>
            Edit Profile
          </button>
        ) : (
          ""
        )}
      </div>

      {isLoading ? (
        <div className="text-center m-4">
          <MDBSpinner color="success" size="xl" />
        </div>
      ) : (
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <HeaderCard
            mname={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.first_name === null
                      ? ""
                      : profiledata?.first_name
                  } ${
                    profiledata?.middle_name === null
                      ? ""
                      : profiledata?.middle_name
                  } ${
                    profiledata?.last_name === null
                      ? ""
                      : profiledata?.last_name
                  }`
                : businessdata[0]?.business_name
            }
            img={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.avatar === null
                      ? " "
                      : getImageURL(profiledata?.avatar)
                  }`
                : getImageURL(businessdata[0]?.business_logo)
            }
            nativeVillage={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.profile[0]?.native_village === null ||
                    undefined
                      ? ""
                      : profiledata?.profile[0]?.native_village
                  }`
                : ""
            }
            nativeDistrict={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.profile[0]?.native_district === null ||
                    undefined
                      ? ""
                      : profiledata?.profile[0]?.native_district
                  }`
                : ""
            }
            LCCIADesignation={`${
              profiledata?.role?.name === null || undefined
                ? ""
                : profiledata?.role?.name
            }`}
            email={`${
              profiledata?.email === null || undefined ? "" : profiledata?.email
            }`}
            //phone={`${profiledata.mobile === null || undefined ? "" : profiledata.mobile}`}
            expire_date={`${
              profiledata?.profile[0]?.membership_valid_till === null ||
              profiledata?.profile[0]?.membership_valid_till === undefined
                ? ""
                : profiledata?.profile[0]?.membership_valid_till
            }`}
            current_city={`${
              profiledata?.profile[0]?.current_city === null || undefined
                ? ""
                : profiledata?.profile[0]?.current_city
            }`}
            lcciaRole={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.profile[0]?.LCCIA_Designation === null ||
                    profiledata?.profile[0]?.LCCIA_Designation === undefined
                      ? ""
                      : profiledata?.profile[0]?.LCCIA_Designation
                  }`
                : "Organisation"
            }
            tag={
              profiledata?.user_type === "individual"
                ? `${
                    profiledata?.profile[0]?.tag === null
                      ? ""
                      : profiledata?.profile[0]?.tag
                  }`
                : ""
            }
            chapter={profiledata?.profile[0]?.chapter}
          />
          {profiledata?.user_type === "individual" ? (
            <div
              className="container"
              style={{
                marginBottom: "60px",
              }}
            >
              <div class="row">
                <div class="col-sm">
                  <Aboutus aboutus={profiledata.profile[0].about} />

                  <WorkExperience value={profiledata.profile[0].experiences} />

                  <EducationInformation
                    value={profiledata.profile[0].education_details}
                  />
                </div>
                {/* <div class="col-sm">
    </div> */}
                <div class="col-sm">
                  <TabInfo
                    email={`${
                      profiledata.email === null || undefined
                        ? ""
                        : profiledata.email
                    }`}
                    phone={`${
                      profiledata.profile[0].mobile === null || undefined
                        ? ""
                        : profiledata.profile[0].mobile
                    }`}
                    addresss={profiledata.profile[0].addresss}
                    gender={`${
                      profiledata.profile[0].gender === null || undefined
                        ? ""
                        : profiledata.profile[0].gender
                    }`}
                    projectsData={profiledata.profile[0].projects}
                    patentsData={profiledata.profile[0].patents}
                    certificatesData={
                      profiledata.profile[0].licenses_or_certifications
                    }
                    awardsData={profiledata.profile[0].honors_and_awards}
                    incentiveData={profiledata.profile[0].initiatives}
                    socialtalData={profiledata.profile[0].sociatal}
                    businessData={businessdata}
                  />
                </div>
              </div>
            </div>
          ) : businessdata ? (
            <OrganisationDetailPage
              buisnessData={businessdata ? businessdata : {}}
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
