import React from 'react'

export default function VideoPost({video_src}){
  return (
    <iframe
    src={`${video_src}?showinfo=0&rel=0&modestbranding=1&autoplay=1&mute=0&controls=0&loop=1&color=white&iv_load_policy=3`}
   
    width="100%"
    height="350px"
    frameBorder="0"
   
    alt="Youtube video"
  />
  )
}
