import React from 'react'
import { useMutation } from 'react-query';
import { secureAxios } from '../auth';
import { queryClient } from '../query';

export default function useCreateEnquireAction() {

return useMutation(
    async(data) => {
        return await secureAxios
            .post("/items/enquires", data)
            .then((res) => res.data);
    },
    {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(["items", "post"]);
        },
    }
);
}