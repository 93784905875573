import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import { secureAxios } from "../../../_helper/auth";
import { useMe, useUserUpdate } from "../../../_helper/actions";

export default function MemberShipTable({ onChange, current_plan }) {
  const [category, setCategory] = useState([]);
  const [id, setid] = useState("");

  const updateme = useUserUpdate();

  // console.log("id", id);

  const getGender = () => {
    secureAxios
      .get(`items/profile/${localStorage.getItem("user_id")}?fields=*.*`)
      .then((data) => {
        // console.log(data.data.gender);
        //setGender(data.data.gender)
        localStorage.setItem("gender", data.data.gender);
        //setGender()
      });
  };

  const plan_category = {
    Male: "men",
    Female: "women",
    null: "organisation",
  };
  // console.log(category);
  const getData = () => {
    secureAxios
      .get(
        `/items/membership_plan?filter={
          "_or": [
            {
              "category": {
                "_eq": "${plan_category[localStorage.getItem("gender")]}"
              }
            },
            {
              "category": {
                "_eq": "family"
              }
            }
          ]
        }&sort=price`
      )
      .then((data) => {
        // console.log(data);
        setCategory([data.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGender();
    getData();
  }, []);

  // console.log(category);

  return (
    <>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            <th
              scope="col"
              style={{
                width: "100px",
                padding: "100px",
              }}
            ></th>
            {category[0] === undefined || null
              ? []
              : category[0].map((value, idx) => (
                  <th
                    scope="col"
                    style={{
                      backgroundColor: `${value.theme}`,
                    }}
                  >
                    <h4
                      className="text-center"
                      style={{
                        color: "blue",
                      }}
                    >
                      {value.name}
                    </h4>
                    <h4
                      className="text-center"
                      style={{
                        color: "black",
                      }}
                    >
                      ₹
                      {Number(value.price) +
                        Number(value.one_time_fees) -
                        (Number(value.price) *
                          Number(value.discount_for_new_registration)) /
                          100}
                    </h4>
                    <div
                      style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        textAlign: "center",
                        padding: "2px",
                      }}
                    >
                      <button
                        style={{
                          border: "1px solid blue",
                          color: `${id === value.id ? `white` : ""}`,
                          width: "100px",
                          backgroundColor: `${
                            id === value.id ? `#413bdb` : ""
                          }`,
                        }}
                        onClick={() => {
                          updateme.mutate(
                            {
                              last_selected_membership: value.id,
                            },
                            {
                              onSuccess: (data) => {
                                setid(value.id);
                                onChange(value.id);
                              },
                            }
                          );
                          // setid(value.id);
                        }}
                        disabled={current_plan >= value.id ? true : false}
                      >
                        {current_plan > value.id
                          ? "Not Eligible"
                          : current_plan === value.id
                          ? "Current Pack"
                          : id == value.id
                          ? `Selected`
                          : "Select"}
                      </button>
                    </div>
                  </th>
                ))}
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr className="text-center">
            <th
              scope="row"
              style={{
                color: "blue",
                width: "100px",
              }}
            >
              One Time Fees
            </th>
            {category[0] === undefined || category[0] === null
              ? []
              : category[0]?.map((value, idx) => (
                  <td>{value.one_time_fees}</td>
                ))}
          </tr>
          <tr className="text-center">
            <th
              scope="row"
              style={{
                color: "blue",
                width: "100px",
              }}
            >
              Price
            </th>

            {category[0] === undefined || null
              ? []
              : category[0]?.map((value, idx) => <td>{value.price}</td>)}
          </tr>
          <tr className="text-center">
            <th
              scope="row"
              style={{
                color: "blue",
                width: "100px",
              }}
            >
              Discount For New Registrations
            </th>

            {category[0] === undefined || null
              ? []
              : category[0].map((value, idx) => (
                  <td>{value.discount_for_new_registration}</td>
                ))}
          </tr>
          <tr className="text-center">
            <th
              scope="row"
              style={{
                color: "blue",
                width: "100px",
              }}
            >
              Voting Rights
            </th>
            {category[0] === undefined || null
              ? []
              : category[0].map((value, idx) => (
                  <td>{value.voting_rights === false ? "no" : "yes"}</td>
                ))}
          </tr>
        </MDBTableBody>
      </MDBTable>
    </>
  );
}
