import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { secureAxios } from "../../../_helper/auth";
import { getImageURL } from "../../../_helper/image";
import RefereceCardApproval from "./RefereceCardApproval";

export default function MyReferences() {
  const [profiledata, setProfileData] = useState([]);


  const GetDocumentVerification = async() => {
      await secureAxios.get(`/items/profile/${localStorage.getItem('user_id')}?fields=references.*.*.*`).
      then((res)=>{
        console.log(res.data.references)
        setProfileData(res.data.references);
        

      }).catch((err)=>{
        console.log(err)
      })
  }



  useEffect(() => {
    //GetData();
    GetDocumentVerification();
  }, []);

  return (
    <>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}>Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }}>
          Settings
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }} active>
          Reference Approval
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <h4>My References</h4>

      {profiledata.map((item, idx) => (
      
        <>
        <RefereceCardApproval
          img={`${
            !item?.referent?.user?.avatar
              ? " "
              : getImageURL(item.referent?.user?.avatar)
          }`}
          mname={`${
            !item.referent?.first_name ? "" : item.referent?.first_name
          } ${!item.referent?.middle_name ? "" : item.referent?.middle_name} ${
            !item.referent?.last_name ? "" : item.referent?.last_name
          }`}
          current_city={`${
            !item.referent?.current_city ? "" : item.referent?.current_city
          }`}
          nativeDistrict={`${
            !item.referent?.native_district ? "" : item.referent?.native_district
          }`}
          nativeVillage={`${
            !item.referent?.native_village ? "" : item.referent?.native_village
          }`}
          email={`${
            !item.referent?.user?.email ? "" : item.referent?.user?.email
          }`}
          lcciaRole = {`${
            !item?.referent?.LCCIA_Designation ? "" : item.referent?.LCCIA_Designation
          }`}

          docData = {item.referent?.verification_docs}
          id = {item?.id}
          rstatus = {item?.status}


          
        />
        </>
      ))}
    </>
  );
}
