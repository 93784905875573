import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { useDeletePost } from '../../../../_helper/colabarated_wall/DeletePostAction';

export default function DeletePostModal({isopen,setIsOpen,post_id}) {
   

    const delete_post = useDeletePost();
    const deletePost = async () => {
        await delete_post.mutateAsync(post_id);
        setIsOpen(false);
    }



    return (
    <>
     <MDBModal show={isopen} setShow={setIsOpen} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
          
            <MDBModalBody>Really want withdrawal it?</MDBModalBody>

            <MDBModalFooter>
              <button 
              onClick={deletePost}
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                outline: 'none',
                cursor: 'pointer',
                
              }}>Yes</button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}
