import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import Avatar from "../../../media/avatar2.jpeg";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBRipple,
  MDBBreadcrumbItem,
  MDBBreadcrumb,
  MDBContainer,
  MDBCardSubTitle,
} from "mdb-react-ui-kit";

export default function HeaderCard({
  mname,
  expire_date,
  img,
  email,
  current_city,
  LCCIADesignation,
  nativeVillage,
  nativeDistrict,
  lcciaRole,
  tag,
  chapter,
}) {
  const { name } = useParams();
  var q = new Date();
  var m = q.getMonth() + 1;
  var d = q.getDay();
  var y = q.getFullYear();

  var date = new Date();
  let mydate = new Date(
    `${expire_date === "" ? "" : `${expire_date}`.slice(0, 10)}`
  );

  //console.log(lcciaRole)

  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="">
          <MDBCard
            style={{
              width: "100%",
              margin: "10px",
              padding: "15px",
            }}
          >
            <MDBRow className="lg-3">
              <MDBCol sm="3">
                <MDBCardImage
                  src={img !== " " ? img : Avatar}
                  alt="..."
                  fluid
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                />
              </MDBCol>
              <MDBCol xl="1"></MDBCol>
              <MDBCol sm="4">
                <MDBCardBody style={{ padding: "6px" }}>
                  <MDBCardTitle>
                    {" "}
                    {`${mname}`} <br></br>
                    <MDBCardSubTitle>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "350",
                          color: "#808080",
                          marginTop: "10px",
                        }}
                      >{`${
                        nativeVillage ? `${nativeVillage + ","}` : ""
                      } ${nativeDistrict}`}</p>
                    </MDBCardSubTitle>
                  </MDBCardTitle>

                  <MDBCardText>
                    {current_city ? (
                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="location"
                            style={{ color: "#F58A2E" }}
                            size="sm"
                          />
                        </div>
                        <div className="col"> {`${current_city}`}</div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="row ">
                      <div className="col-1">
                        <MDBIcon
                          fas
                          icon="envelope"
                          size="sm"
                          style={{ color: "#F58A2E" }}
                        />
                      </div>

                      <div className="col"> {`${email}`}</div>
                    </div>

                    <div className="row py-1">
                      <div className="col-1">
                        <MDBIcon
                          fas
                          icon="user"
                          size="sm"
                          style={{ color: "#F58A2E" }}
                        />
                      </div>{" "}
                      <div className="col">
                        <span className="">{`${
                          lcciaRole ? lcciaRole : "Member"
                        }`}</span>
                      </div>
                    </div>

                    {tag === "" || tag === null || tag === undefined ? (
                      ""
                    ) : (
                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="user-tag"
                            size="sm"
                            style={{ color: "#F58A2E" }}
                          />
                        </div>{" "}
                        <div className="col">
                          <span className="">{`${tag}`}</span>
                        </div>
                      </div>
                    )}

                    {chapter === null || chapter === undefined ? (
                      ""
                    ) : (
                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            size="sm"
                            fas
                            icon="book"
                            style={{ color: "#F58A2E" }}
                          />
                        </div>
                        <div className="col">
                          <span>{`${chapter.name}`}</span>
                        </div>
                      </div>
                    )}

                    {
                      // <a
                      //   href="https://tshow.qureal.com/w/shop_demo"
                      //   target="_blank"
                      // >
                      //   <div className="row py-1">
                      //     <div className="col-1">
                      //       <MDBIcon
                      //         size="sm"
                      //         fas
                      //         icon="shop"
                      //         style={{ color: "#F58A2E" }}
                      //       />
                      //     </div>
                      //     <div className="col">
                      //       <span>Shop Now</span>
                      //     </div>
                      //   </div>
                      // </a>
                    }
                  </MDBCardText>

                  <h5>
                    {/* <span
                      class="badge"
                      style={{
                        backgroundColor: "#F58A2E",
                      }}
                    >
                      {`${LCCIADesignation}`}
                    </span> */}
                    <span
                      className="badge mx-4"
                      style={{
                        backgroundColor: `${
                          date <= mydate ? `#35bd52` : "#f52222"
                        }`,
                      }}
                    >
                      {date <= mydate ? `Active` : "Inactive"}
                    </span>
                  </h5>
                  {name === "view" || date > mydate ? (
                    <>
                      <MDBIcon
                        className="px-1"
                        fas
                        icon="calendar"
                        size="sm"
                        style={{ color: "#c42525" }}
                      />
                      <a
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Membership Valid Till{" "}
                        {new Date(`${expire_date}`.slice(0, 10)).toLocaleString(
                          "en-IN",
                          { day: "numeric", month: "short", year: "numeric" }
                        )}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
