import { useMutation } from "react-query";
import { secureAxios } from "./auth";
import { queryClient } from "./query";

export const usePostPaymentVerification = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/payment_verification/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "payment_verification"]);
            },
        }
    );
};

export const useUpdatePaymentVerification = () => {
    return useMutation(
        (newData) => {
            return secureAxios
                .patch(`/items/payment_verification/${newData.id}`, newData.data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "payment_verification"]);
            },
        }
    );
};