import React, { useEffect, useState } from 'react'
import { MDBInput, MDBIcon, MDBModal, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBTextArea } from 'mdb-react-ui-kit';
import '../style/Datetime.css';
import Select from 'react-select';


export default function ExperienceModel({ exshow, exsetShow, sentExperienceData, reciveData, onChangeExperience }) {
    const [Title, setTitle] = useState("");
    const [EmploymentType, setEmploymentType] = useState("");
    const [Location, setLocation] = useState("");
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [Headline, setHeadline] = useState("");
    const [Industry, setIndustry] = useState("");
    const [Description, setDescription] = useState("");

    //console.log(reciveData)
    const options = [
        {label:"Full Time",value:0},
        {label:"Part Time",value:1},
        {label:"Self Employed",value:2},

    ]

    const handleTitle = (eve) => {
        setTitle(eve.target.value);
        onChangeExperience({
            "title": eve.target.value,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    const handleEmploymentType = (eve) => {
        
        setEmploymentType(eve.label);
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": eve.label,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    const handleLocation = (eve) => {
        setLocation(eve.target.value);
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": eve.target.value,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    const handleStartDate = (eve) => {
        setStartDate(new Date(eve.target.value).toISOString());
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": new Date(eve.target.value).toISOString(),
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    const handleEndDate = (eve) => {
        setEndDate(new Date(eve.target.value).toISOString());
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date":  new Date(eve.target.value).toISOString(),
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    
    const handleHeadline = (eve) => {
        setHeadline(eve.target.value);
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": eve.target.value,
            "industry": reciveData.industry,
            "description": reciveData.description,

        })

    }

    const handleIndustry = (eve) => {
        setIndustry(eve.target.value);
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": eve.target.value,
            "description": reciveData.description

        })

    }

    const handleDescription = (eve) => {
        setDescription(eve.target.value);
        onChangeExperience({
            "title": reciveData.title,
            "employment_type": reciveData.employment_type,
            "location": reciveData.location,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "headline": reciveData.headline,
            "industry": reciveData.industry,
            "description": eve.target.value

        })

    }


    const experienceData = {
        "title": Title,
        "employment_type": EmploymentType,
        "location": Location,
        "start_date": StartDate,
        "end_date": EndDate,
        "headline": Headline,
        "industry": Industry,
        "description": Description

    }

    
    const sent = () => {

        sentExperienceData(experienceData)

        setTitle('');
        setEmploymentType('');
        setLocation('');
        setStartDate('');
        setEndDate('');
        setHeadline('');
        setIndustry('');
        setDescription('');
        exsetShow(!exshow)
    }






    return (
        <>

            <MDBModal show={exshow} setShow={exsetShow} tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Experience</MDBModalTitle>
                            <button
                                className='btn-close'
                                color='none'
                                onClick={() => exsetShow(!exshow)
                                }
                                X
                            ></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Title'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleTitle}
                                    value={`${reciveData.title}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <Select
                                    className='mb-4 w-100'
                                    placeholder='Employment Type'
                                    onChange={handleEmploymentType}
                                    options={options}
                                    value={reciveData.employment_type?{label:`${reciveData.employment_type}`}:""}
                                   
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Location'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleLocation}
                                    value={`${reciveData.location}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Start Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleStartDate}
                                    value={`${reciveData.start_date=== undefined ? {} : reciveData.start_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='End Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleEndDate}
                                    value={`${reciveData.end_date=== undefined ? {} : reciveData.end_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Name of the organisation'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleHeadline}
                                    value={`${reciveData.headline}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                          
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Industry'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleIndustry}
                                    value={`${reciveData.industry}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                          
                          <MDBTextArea
                              wrapperClass='mb-4 w-100 '
                              label='Description'
                              id='formControlLg'
                              type='text'
                              size="lg"
                              onChange={handleDescription}
                              value={`${reciveData.description}`}
                              autoComplete='off'

                          />

                    {/* <MDBTextArea contrast   id='formControlLg' label='Description' /> */}
                      </div>

                        </MDBModalBody>
                        <MDBModalFooter>


                            < button className='mt-4 py-1'
                                style={{ width: "8rem", color: "white", backgroundColor: "#1b5ff2", borderRadius: "5px", border: 'none' }}
                                onClick = {
                                    sent
                                }
                            >

                                <MDBIcon fas icon='save' className='px-1' />
                                Save
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </>
    )
}
