import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  resetTokens,
  restoreRefreshToken,
  secureAxios,
  setAuthToken,
} from "../../_helper/auth";
import "./style/sidebar.css";
import Image from "./images/lccia_logo.jpg";
import { MDBIcon } from "mdb-react-ui-kit";
import Avatar from "../../media/avatar2.jpeg";
import { getImageURL } from "../../_helper/image";
import SideBar from "./SideBarComponents/SideBar";

export default function Navbar() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [islogedin, setIslogedin] = useState(true);
  const [Avtar, setAvtar] = useState("");
  const [data, setData] = useState({});

  const navigation = useNavigate();
  const { name } = useParams();

  const getData = async () => {
    await secureAxios
      .get("/users/me?fields=*.*")
      .then((data) => {
        localStorage.setItem("user_id", data?.data?.profile[0].id);
        localStorage.setItem(
          "business_id",
          data?.data?.business_profiles[0].id
        );
        localStorage.setItem("me_id", data?.data?.id);
        localStorage.setItem("gender", data?.data?.profile[0].gender);

        if (!localStorage.getItem("access_token")) {
          localStorage.getItem("user_id", data?.data?.profile[0].id);
          localStorage.getItem(
            "business_id",
            data?.data?.business_profiles[0].id
          );
        }
        setData(data.data);

        setAvtar(
          data?.data?.avatar === null || data?.data?.avatar === undefined
            ? ""
            : `${data?.data?.avatar?.id}`
        );
      })
      .catch((err) => {
        console.log(err);
        resetTokens();
        navigation("/login");
        return <Navigate to="/login" />;
      });
  };

  useEffect(() => {
    getData();
  }, []);

  function logout() {
    setLoading(true);
    secureAxios
      .post("/auth/logout", {
        refresh_token: restoreRefreshToken(),
      })
      .then((res) => {
        console.log("Successfully logged out");
        setIslogedin(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err.response, "Error while logging out");
      })
      .finally(() => {
        resetTokens();
        setAuthToken();

        setLoading(false);
        // QueryClient.invalidateQueries();
        //history.push("/login");
        // queryClient.invalidateQueries();
      });
  }

  if (!islogedin) {
    return <Navigate to="/login"></Navigate>;
  }

  return (
    <>
      <header>
        {/* <nav
          id="sidebarMenu"
          className={`collapse d-lg-block sidebar collapse bg-white collapse`}
        >
          <div className="position-sticky">
            <div className="list-group list-group-flush mx-3 mt-4">
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                 
                  navigation("/profile/all-member");
                  
                  return <Navigate to="/profile/all-member"></Navigate>;
                }}
                className={`list-group-item list-group-item-action py-2 ripple my-4 ${
                  name === "all-member" ? `active` : ``
                }`}
              >
                <MDBIcon fas icon="users" />
                <span classNameName="px-2" style={{ cursor: "pointer" }}>
                  All Members
                </span>
              </a>

              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                 
                  navigation("/profile/view");
                  
                  return <Navigate to="/profile/view"></Navigate>;
                }}
                className={`list-group-item list-group-item-action py-2 ripple ${
                  name === "view" ? `active` : ``
                }`}
                aria-current="true"
              >
                <MDBIcon fas icon="user-tie" />
                <span classNameName="px-2" style={{ cursor: "pointer" }}>
                  My Profile
                </span>
              </a>

              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                 
                  navigation("/profile/setting");
                  
                  return <Navigate to="/profile/setting"></Navigate>;
                }}
                className={`list-group-item list-group-item-action py-2 ripple my-4 ${
                  name === "setting" ? `active` : ``
                }`}
                aria-current="true"
              >
                <MDBIcon fas icon="cogs" />
                <span classNameName="px-2" style={{ cursor: "pointer" }}>
                  Settings
                </span>
              </a>
             
            </div>
          </div>
        </nav> */}

        <nav
          id="main-navbar"
          style={{ padding: 0 }}
          className="navbar navbar-expand-lg navbar-light bg-white fixed-top"
        >
          <div className="container-fluid">
            <a className="navbar-brand">
              <img src={Image} height="45" alt="" loading="lazy" />
            </a>

            <ul className="navbar-nav ms-auto d-flex flex-row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginRight: ".5em",
                  cursor: "pointer",
                }}
              >
                {/* <a href="https://tshow.qureal.com/w/shop_demo" target="_blank">
                  <MDBIcon
                    fas
                    size="lg"
                    icon="shop"
                    style={{ color: "gray" }}
                  />
                </a> */}
              </div>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle hidden-arrow d-flex align-items-center"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={getImageURL(Avtar)}
                    className="rounded-circle"
                    height="30"
                    width="30"
                    alt=""
                  />
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigation("/profile/view");
                        return <Navigate to="/profile/view"></Navigate>;
                      }}
                    >
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigation("/profile/setting");
                        return <Navigate to="/profile/setting"></Navigate>;
                      }}
                    >
                      Settings
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={logout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <SideBar />
      {/* <SideBar/> */}
    </>
  );
}
