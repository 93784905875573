import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import WallMenuCard from "./WallMenuCard";
import { useNavigate } from "react-router-dom";
import CreatePostModel from "./CreatePost/CreatePostModel";

export default function MenuModel({ isopen, setIsopen, profile_img }) {
  const navigate = useNavigate();
  const [staticModal, setStaticModal] = useState(false);

  return (
    <>
      <CreatePostModel
        isopen={staticModal}
        setIsOpen={setStaticModal}
        img={profile_img}
        isEditOpen={false}
        data={null}
      />
      <MDBModal tabIndex="-1" show={isopen} setShow={setIsopen}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalBody className="text-center">
              <button
                style={{
                  float: "right",
                }}
                className="btn-close"
                color="none"
                onClick={() => {
                  setIsopen(false);
                }}
              ></button>
              <div
                className="px-5"
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#407ee3",
                  marginTop: "10px",
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setStaticModal(true);
                    setIsopen(false);
                  }}
                >
                  Create Posts
                </p>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/_home/my_posts");
                    setIsopen(false);
                  }}
                >
                  My Posts
                </p>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  My Groups
                </p>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  My Ads
                </p>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Events
                </p>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Invitations
                </p>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
