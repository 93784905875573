import { useMutation } from "react-query";
import { publicAxios, secureAxios } from "./auth";

export const useSendForgetPasswordLink = (onSuccess) => {
    return useMutation((data) =>
        publicAxios.post("/auth/password/request", data).then((res) => res.data),
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
            },
        }
    );
};

export const useForgetPassword = (onSuccess) => {
    return useMutation((data) =>
        publicAxios.post("/auth/password/reset", data).then((res) => res.data),
        {
            onSuccess: (data, variables, context) => {
                onSuccess(data);
            },
        }
    );
};