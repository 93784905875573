import React, { useEffect, useState } from "react";
import {
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../style/Datetime.css";

export default function SocietalModel({
  soshow,
  sosetShow,
  sentSociatalData,
  reciveData,
  onChangeSocietal,
 
}) {
  const [organisation_name, setOrganisationName] = useState("");
  const [designation, setDesignation] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [url,seturl] = useState("");

  console.log(reciveData);

  const handleOrgName = (eve) => {
    setOrganisationName(eve.target.value);
    //setSociatalData({... sociataldata,organisation_name:eve.target.value})
    onChangeSocietal({
      organisation_name: eve.target.value,
      designation: reciveData.designation,
      start_date: reciveData.start_date,
      end_date: reciveData.end_date,
      description: reciveData.description,
      url:reciveData.url
    });
  };

  const handleDesignation = (eve) => {
    setDesignation(eve.target.value);
    // setSociatalData({... sociataldata,designation:eve.target.value})
    onChangeSocietal({
      organisation_name: reciveData.organisation_name,
      designation: eve.target.value,
      start_date: reciveData.start_date,
      end_date: reciveData.end_date,
      description: reciveData.description,
      url:reciveData.url
    });
  };

  const handleStartDate = (eve) => {
    setStartDate(new Date(eve.target.value).toISOString());
    // setSociatalData({... sociataldata,start_date:new Date(eve.target.value).toISOString()})
    onChangeSocietal({
      organisation_name: reciveData.organisation_name,
      designation: reciveData.designation,
      start_date: new Date(eve.target.value).toISOString(),
      end_date: reciveData.end_date,
      description: reciveData.description,
      url:reciveData.url
    });
  };

  const handleEndDate = (eve) => {
    setEndDate(new Date(eve.target.value).toISOString());
    //    ?etSociatalData({... sociataldata,end_date:new Date(eve.target.value).toISOString()})
    //onChangeSocietal({...sociataldata,end_date:new Date(eve.target.value).toISOString()})
    onChangeSocietal({
      organisation_name: reciveData.organisation_name,
      designation: reciveData.designation,
      start_date: reciveData.start_date,
      end_date: new Date(eve.target.value).toISOString(),
      description: reciveData.description,
       url:reciveData.url
    });
  };

  const handleDescription = (eve) => {
    setDescription(eve.target.value);
    // setSociatalData({ ...sociataldata, description: eve.target.value });
    // onChangeSocietal({...sociataldata,description:eve.target.value})
    onChangeSocietal({
        organisation_name: reciveData.organisation_name,
        designation: reciveData.designation,
        start_date: reciveData.start_date,
        end_date: reciveData.end_date,
        description: eve.target.value,
        url:reciveData.url
      });

  };

  const handleURL = (eve) => {
    seturl(eve.target.value);
    // setSociatalData({ ...sociataldata, description: eve.target.value });
    // onChangeSocietal({...sociataldata,description:eve.target.value})
    onChangeSocietal({
        organisation_name: reciveData.organisation_name,
        designation: reciveData.designation,
        start_date: reciveData.start_date,
        end_date: reciveData.end_date,
        description: reciveData.description,
        url:eve.target.value
      });

  };

  const sociataldata = {
    "organisation_name":organisation_name,
    "designation":designation,
    "start_date":start_date,
    "end_date":end_date,
    "description":description,
    "url":url
  }

  const sent = () => {
    sentSociatalData(sociataldata);
    setOrganisationName('')
    setDesignation('')
    setStartDate('')
    setEndDate('')
    setDescription('')
    seturl('')
    sosetShow(!soshow);
  };

  return (
    <>
      <MDBModal show={soshow} setShow={sosetShow} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add My Societal</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => sosetShow(!soshow)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Organisation"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleOrgName}
                  value={`${
                    reciveData?.organisation_name === undefined
                      ? ""
                      : reciveData?.organisation_name
                  }`}
                  autoComplete="off"
                  autoFocus
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Designation"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleDesignation}
                  value={`${
                    reciveData.designation === undefined
                      ? ""
                      : reciveData.designation
                  }`}
                  autoComplete="off"
                  autoFocus
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Start Date"
                  id="formControlLg"
                  type="date"
                  size="md"
                  onChange={handleStartDate}
                  value={`${
                    reciveData.start_date === undefined
                      ? {}
                      : reciveData.start_date.slice(0, 10)
                  }`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="End Date"
                  id="formControlLg"
                  type="date"
                  size="md"
                  onChange={handleEndDate}
                  value={`${
                    reciveData.end_date === undefined
                      ? {}
                      : reciveData.end_date.slice(0, 10)
                  }`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                <MDBTextArea
                  wrapperClass="mb-4 w-100 "
                  label="Description"
                  id="formControlLg"
                  type="text"
                  size="lg"
                  onChange={handleDescription}
                  value={`${
                    reciveData.description === undefined
                      ? ""
                      : reciveData.description
                  }`}
                  autoComplete="off"
                />

                {/* <MDBTextArea contrast   id='formControlLg' label='Description' /> */}
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="URL"
                  id="formControlLg"
                  type="url"
                  size="md"
                  onChange={handleURL}
                  value={`${
                    reciveData.url === undefined || reciveData.url === null 
                      ? ""
                      : reciveData.url
                  }`}
                  autoComplete="off"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={sent}
              >
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
