import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { secureAxios } from "../../../_helper/auth";
import {
  usePostPaymentVerification,
  useUpdatePaymentVerification,
} from "../../../_helper/PaymentVerificationAction";

export default function PaymentVerification() {
  const { id } = useParams();
  const [data, setData] = useState({
    profile: Number(localStorage.getItem(`user_id`)),
    plan_id: Number(id),
    status: null,
  });

  const [pending_amt, setPendingAmount] = useState(0);

  const [paymentdata, setPaymentData] = useState([]);

  const navigate = useNavigate();

  const mutation = usePostPaymentVerification();
  const updatemutation = useUpdatePaymentVerification();

  console.log(pending_amt);

  const GetMe = async () => {
    await secureAxios.get("users/me?fields=pending_amount").then((res) => {
      console.log(res.data);
      setPendingAmount(res?.data?.pending_amount);
    });
  };

  useEffect(() => {
    GetMe();
  }, []);

  const OnPaymentsSubmit = (eve) => {
    eve.preventDefault();

    mutation.mutate(data);
    console.log(data);
  };

  const OnClickSubmit = (id) => {};

  const OnUpdateSubmit = (eve, id) => {
    eve.preventDefault();
    updatemutation.mutate({ id: id, data: data });
  };

  const onGetData = async () => {
    await secureAxios
      .get(
        `items/profile/${localStorage.getItem(
          "user_id"
        )}?fields=payment_verification.*`
      )
      .then((res) => {
        // console.log(res.data.payment_verification);
        setPaymentData(res.data.payment_verification);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const { data: planData } = useMe();

  useEffect(() => {
    onGetData();
  }, []);

  return (
    <>
      <h4 style={{ marginLeft: "12px" }}>Payment Verification</h4>
      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <h5 className="mb-4">Receiver's Bank Details</h5>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Bank Name"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`HDFC Bank`}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Account Holder Name"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`Leva Chamber Of Commerece I And A`}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="A/C Number"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`50200072786737`}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="IFSC"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`HDFC0002524`}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Branch"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`Pimple Saudagar Pune`}
                    readOnly
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Email"
                    id="formControlLg"
                    type="email"
                    size="md"
                    value={`levachamberofcommerce@gmail.com`}
                    readOnly
                  />
                </div>
                <div>
                  <p style={{ color: "red", fontSize: "15px" }}>
                    Please do the payment on above bank details only NEFT
                    allowed. <br></br> Please update payment details below and
                    submit.
                  </p>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <h5 className="mb-4">Member's Bank Details</h5>
                {paymentdata.length !== 0 ? (
                  paymentdata[0].status === "rejected" ? (
                    <>
                      <div
                        class="alert alert-danger text-center mt-2"
                        role="alert"
                      >
                        <i class="fa fa-info-circle"></i> Your membership is
                        Rejected Please fill the form again.
                      </div>
                      <form
                        onSubmit={(eve) => {
                          OnUpdateSubmit(eve, paymentdata[0].id);
                        }}
                      >
                        <div className="d-flex flex-row align-items-center ">
                          <MDBInput
                            wrapperClass="mb-4 w-100"
                            label="Bank Name"
                            id="formControlLg"
                            type="text"
                            size="md"
                            required
                            onChange={(eve) => {
                              setData({ ...data, bank_name: eve.target.value });
                            }}
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center ">
                          <MDBInput
                            wrapperClass="mb-4 w-100"
                            label="Payment details (UTR)*"
                            id="formControlLg"
                            type="text"
                            size="md"
                            required
                            onChange={(eve) => {
                              setData({ ...data, utrs: eve.target.value });
                            }}
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center ">
                          <MDBInput
                            wrapperClass="mb-4 w-100"
                            label="Amount Paid"
                            id="formControlLg"
                            type="Number"
                            size="md"
                            required
                            onChange={(eve) => {
                              setData({
                                ...data,
                                amount_paid: Number(eve.target.value),
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex flex-row align-items-center ">
                          <MDBInput
                            wrapperClass="mb-4 w-100"
                            label="Pay Date"
                            id="formControlLg"
                            type="date"
                            size="md"
                            required
                            onChange={(eve) => {
                              setData({
                                ...data,
                                date: new Date(eve.target.value).toISOString(),
                              });
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="px-4 py-1"
                            style={{
                              color: "white",
                              backgroundColor: "#1b5ff2",
                              borderRadius: "5px",
                              border: "none",
                            }}
                          >
                            {mutation.isLoading ? (
                              <MDBSpinner color="light" />
                            ) : (
                              "Submit"
                            )}
                          </button>
                          {updatemutation.isSuccess
                            ? navigate("/profile/view")
                            : ""}

                          {updatemutation.isSuccess ? (
                            <div
                              class="alert alert-success text-center mt-2"
                              role="alert"
                            >
                              <i class="fa fa-info-circle"></i> Your membership
                              will be updated within next 24 hours
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </form>
                    </>
                  ) : (
                    <div
                      class="alert alert-success text-center mt-2"
                      role="alert"
                    >
                      <i class="fa fa-info-circle"></i> Your membership will be
                      updated within next 24 hours
                    </div>
                  )
                ) : (
                  <form onSubmit={OnPaymentsSubmit}>
                    <div className="d-flex flex-row align-items-center ">
                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        label="Bank Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        required
                        onChange={(eve) => {
                          setData({ ...data, bank_name: eve.target.value });
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        label="Payment details (UTR)*"
                        id="formControlLg"
                        type="text"
                        size="md"
                        required
                        onChange={(eve) => {
                          setData({ ...data, utrs: eve.target.value });
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        label="Amount Paid"
                        id="formControlLg"
                        type="Number"
                        size="md"
                        value={`${pending_amt}`}
                        readOnly
                        required
                        onChange={(eve) => {
                          setData({
                            ...data,
                            amount_paid: Number(eve.target.value),
                          });
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        label="Pay Date"
                        id="formControlLg"
                        type="date"
                        size="md"
                        required
                        onChange={(eve) => {
                          setData({
                            ...data,
                            date: new Date(eve.target.value).toISOString(),
                          });
                        }}
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="px-4 py-1"
                        style={{
                          color: "white",
                          backgroundColor: "#1b5ff2",
                          borderRadius: "5px",
                          border: "none",
                        }}
                      >
                        {mutation.isLoading ? (
                          <MDBSpinner color="light" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                      {mutation.isSuccess ? navigate("/profile/view") : ""}

                      {mutation.isSuccess ? (
                        <div
                          class="alert alert-success text-center mt-2"
                          role="alert"
                        >
                          <i class="fa fa-info-circle"></i> Your membership will
                          be updated within next 24 hours
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
