import React from "react";

export default function AwardsInformation({ allAwardsvalue }) {
  console.log(allAwardsvalue);
  return (
    <div>
      {!allAwardsvalue || allAwardsvalue?.length === 0 ? (
        ""
      ) : (
        <h4
          style={{
            marginTop: "20px",
            fontWeight: "500",
            color: "#8A8A8A",
          }}
        >
          All Awards and Honors
        </h4>
      )}

      {allAwardsvalue === null || undefined
        ? []
        : allAwardsvalue.map((value, idx) => (
            <div>
              <h4
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "450",
                }}
              >
                {value.title}
              </h4>

              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  
                }}
              >
                {" "}
                {`${value.issued_by_org} associated by ${value.in_associated_with}`}
                <br></br>
                <h6
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "400",
                    
                  }}
                >
                  {new Date(`${value.issued_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  )}
                </h6>
              </p>

              <p
                style={{
                  fontSize: "14px",
                  justifyContent: "flex-start",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              >
                {value.description}
              </p>
            </div>
          ))}
    </div>
  );
}
