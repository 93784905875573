import React from "react";
import {
  BrowserRouter,
  Route,
  Redirect,
  Routes,
  Navigate,
} from "react-router-dom";
import Index from "./components/auth/index.js";
import Signup from "./components/auth/signup.js";
import ProtectedRoute from "./_helper/ProtectedRoute.js";
import Home from "./components/home/home";
import MembershipVerificationRouting from "./components/home/MembershipVerificationRouting.js";
import PaymentVerificationRouting from "./components/home/PaymentVerificationRouting.js";
import BusinessRouting from "./components/home/BusinessRouting.js";
import ForgetPassword from "./components/auth/ForgetPassword.js";
import OrganisationRouting from "./components/home/OrganisationRouting.js";
import SideBar from "./components/home/SideBarComponents/SideBar.js";
import MainWall from "./components/collaborated_wall/MainWall.js";
import SingleViewPost from "./components/collaborated_wall/components/ViewPost/SingleViewPost.js";

function App() {
  return (
    <>
      <BrowserRouter>
     
        <Routes>

          <Route path="/" element={<Navigate to="/_home" />}></Route>
          <Route
            path="/_home"
            element={<ProtectedRoute Cmp={MainWall}></ProtectedRoute>}/>
          <Route
            path="/_home/:id"
            element={<ProtectedRoute Cmp={MainWall}></ProtectedRoute>}/>
         
          {/* <Route path="/login" element={<Index />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="/admin/reset-password" element={<ForgetPassword/>}></Route> */}
          <Route
            path="/profile/:name"
            element={<ProtectedRoute Cmp={Home}></ProtectedRoute>}
          />
           <Route
            path="/profile/membership/upgrade-verification/:id"
            element={<ProtectedRoute Cmp={MembershipVerificationRouting}></ProtectedRoute>}
          />
           <Route
            path="/profile/membership/payment-verification/:id"
            element={<ProtectedRoute Cmp={PaymentVerificationRouting}></ProtectedRoute>}
          />
          <Route
            path="/profile/edit-business-profile/:id"
            element={<ProtectedRoute Cmp={BusinessRouting}></ProtectedRoute>}
          />
           <Route
            path="/profile/organisation/:id"
            element={<ProtectedRoute Cmp={OrganisationRouting}></ProtectedRoute>}
          />
          <Route
            path="/shared/post/:id"
            element={<ProtectedRoute Cmp={SingleViewPost}></ProtectedRoute>}
          />
       
          <Route path="/login" element={<Index />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="/admin/reset-password" element={<ForgetPassword/>}></Route>
        </Routes>
         
     

        
      </BrowserRouter>
    </>
  );
}

export default App;
