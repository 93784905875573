import React, { useEffect, useState } from 'react'
import { MDBInput, MDBIcon, MDBModal, MDBModalSchool, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBTextArea } from 'mdb-react-ui-kit';
import '../style/Datetime.css';


export default function EducationModel({ edshow, edsetShow, sentEducationData, reciveData, onChangeEducation }) {
    const [School, setSchool] = useState("");
    const [Degree, setDegree] = useState("");
    const [FieldOfStudy, setFieldOfStudy] = useState("");
    const [StartDate, setStartDate] = useState("");
    const [EndDate, setEndDate] = useState("");
    const [Grade, setGrade] = useState("");
    const [Activities, setActivities] = useState("");
    

    //console.log(reciveData)



    const handleSchool = (eve) => {
      setSchool(eve.target.value);
        onChangeEducation({
           "school": eve.target.value,
            "degree": reciveData.degree,
            "field_of_study": reciveData.field_of_study,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "grade": reciveData.grade,
            "activities": reciveData.activities,
           

        })

    }

    const handleDegree = (eve) => {
        setDegree(eve.target.value);
        onChangeEducation({
           "school": reciveData.school,
            "degree": eve.target.value,
            "field_of_study": reciveData.field_of_study,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "grade": reciveData.grade,
            "activities": reciveData.activities,
           

        })

    }

    const handleFieldOfStudy = (eve) => {
        setFieldOfStudy(eve.target.value);
        onChangeEducation({
           "school": reciveData.school,
            "degree": reciveData.degree,
            "field_of_study": eve.target.value,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "grade": reciveData.grade,
            "activities": reciveData.activities,
           

        })

    }

    const handleStartDate = (eve) => {
        setStartDate(new Date(eve.target.value).toISOString());
        onChangeEducation({
           "school": reciveData.school,
            "degree": reciveData.degree,
            "field_of_study": reciveData.field_of_study,
            "start_date": new Date(eve.target.value).toISOString(),
            "end_date": reciveData.end_date,
            "grade": reciveData.grade,
            "activities": reciveData.activities,
           
        })

    }

    const handleEndDate = (eve) => {
        setEndDate(new Date(eve.target.value).toISOString());
        onChangeEducation({
           "school": reciveData.school,
            "degree": reciveData.degree,
            "field_of_study": reciveData.field_of_study,
            "start_date": reciveData.start_date,
            "end_date":  new Date(eve.target.value).toISOString(),
            "grade": reciveData.grade,
            "activities": reciveData.activities,
           

        })

    }

    
    const handleGrade = (eve) => {
        setGrade(eve.target.value);
        onChangeEducation({
           "school": reciveData.school,
            "degree": reciveData.degree,
            "field_of_study": reciveData.field_of_study,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "grade": eve.target.value,
            "activities": reciveData.activities,
           

        })

    }

    const handleActivities = (eve) => {
        setActivities(eve.target.value);
        onChangeEducation({
           "school": reciveData.school,
            "degree": reciveData.degree,
            "field_of_study": reciveData.field_of_study,
            "start_date": reciveData.start_date,
            "end_date": reciveData.end_date,
            "grade": reciveData.grade,
            "activities": eve.target.value,
           

        })

    }

  


    const educationData = {
       "school":School,
        "degree": Degree,
        "field_of_study": FieldOfStudy,
        "start_date": StartDate,
        "end_date": EndDate,
        "grade": Grade,
        "activities": Activities,
      

    }
   

    
    const sent = () => {

        sentEducationData(educationData)

      setSchool('');
        setDegree('');
        setFieldOfStudy('');
        setStartDate('');
        setEndDate('');
        setGrade('');
        setActivities('');

        edsetShow(!edshow)
    }






    return (
        <>

            <MDBModal show={edshow} setShow={edsetShow} tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Education</MDBModalTitle>
                            <button
                                className='btn-close'
                                color='none'
                                onClick={() => edsetShow(!edshow)
                                }
                                X
                            ></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Name of School Instutution'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleSchool}
                                    value={`${reciveData.school}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Degree'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleDegree}
                                    value={`${reciveData.degree}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Field Of Study'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleFieldOfStudy}
                                    value={`${reciveData.field_of_study}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Start Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleStartDate}
                                    value={`${reciveData.start_date=== undefined ? {} : reciveData.start_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='End Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleEndDate}
                                    value={`${reciveData.end_date=== undefined ? {} : reciveData.end_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Grade'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleGrade}
                                    value={`${reciveData.grade}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                          
                                <MDBTextArea
                                    wrapperClass='mb-4 w-100'
                                    label='Activities'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleActivities}
                                    value={`${reciveData.activities}`}
                                    autoComplete='off'

                                />
                            </div>


                        </MDBModalBody>
                        <MDBModalFooter>


                            < button className='mt-4 py-1'
                                style={{ width: "8rem", color: "white", backgroundColor: "#1b5ff2", borderRadius: "5px", border: 'none' }}
                                onClick = {
                                    sent
                                }
                            >

                                <MDBIcon fas icon='save' className='px-1' />
                                Save
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </>
    )
}
