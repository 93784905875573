import React, { Component, useEffect, useState } from 'react'
import {Navigate, Redirect} from 'react-router-dom';
import { history } from './history'


export default function ProtectedRoute(props) {
    const [islogedin,setIslogedin] = useState(true);
   
    let Cmp = props.Cmp
    useEffect(()=>{
        if(!localStorage.getItem('access_token')){
            setIslogedin(false)
            // history.push('/login')
        }
    })
    if (!islogedin){
        return <Navigate to = "/login"></Navigate>
    }
    return (

        <div>
            <Cmp/>
        </div>
    )
}