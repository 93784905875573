import React from "react";
import { useParams } from "react-router-dom";
import OrganisationDetailPage from "./BusinessProfileComponents/OrganisationDetailPage";
import EditBusinessProfile from "./EditBusinessProfile";
import Navbar from "./navbar";

export default function OrganisationRouting() {
  const { id } = useParams();
  if (id === "edit") {
    return (
      <>
        <Navbar />
        <main>
          <EditBusinessProfile />
        </main>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <main>
          <OrganisationDetailPage />
        </main>
      </>
    );
  }
}
