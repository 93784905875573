import axios from "axios";
import { history } from "./history";
import { BASE_URL } from "../constant";



// export const BASE_URL_1 = BASE_URL;

let token = localStorage.getItem('access_token')?localStorage.getItem('access_token'):sessionStorage.getItem('access_token')



export function resetTokens() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("business_id");
    localStorage.removeItem("gender");
    localStorage.removeItem("me_id");
    localStorage.removeItem("user_bid")
   

}

export function restoreRefreshToken() {
    return localStorage.getItem("refresh_token")?localStorage.getItem('refresh_token'):sessionStorage.getItem('refresh_token');
}

export function restoreAccessToken() {
    return localStorage.getItem("access_token")?localStorage.getItem('access_token'):sessionStorage.getItem('access_token');
}




axios.defaults.baseURL = BASE_URL;


export const publicAxios = axios.create({
    baseURL: BASE_URL,
});

publicAxios.interceptors.response.use(
    function (res) {
        return res.data;
    },
    async function (err) {
        
        return Promise.reject(err);
    }
);

export const secureAxios = axios.create({
    baseURL: BASE_URL,
    

    headers: {},
});

if (localStorage.getItem('access_token')){
 

    setAuthToken(localStorage.getItem('access_token'))
    
}



secureAxios.interceptors.response.use(
    function (res) {
       
        return res.data;
    },
    function (err) {
       
     

       
      
        // return Promise.reject(err);
        const originalRequest = err.config;
        if((err?.response?.status === 403 || err?.response?.status === 401) && !originalRequest._retry){

            //console.log('401 0r 403')
           
            // 
            const token = restoreAccessToken();
           
            secureAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
           
            //return secureAxios(originalRequest)
            return Promise.reject(err)
        }
        return Promise.reject(err)
 
        
    }
);

export function setAuthToken(token) {
   
    if (token) {
        secureAxios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
    }
}