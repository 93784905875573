import { useMutation } from "react-query";
import { secureAxios } from "./auth";
import { queryClient } from "./query";



export const useChangeMembership = (profile_id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/profile/${profile_id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "profile"]);
            },
        }
    );
};

export const useCreateMembership = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/membership_plan/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "membership_plan"]);
            },
        }
    );
};

export const useEditMembership = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/membership_plan/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "membership_plan"]);
            },
        }
    );
};

export const useDeleteMembership = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .delete(`/items/membership_plan/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "membership_plan"]);
            },
        }
    );
};