import React from 'react'

export default function ChangeType() {
  return (
   <>
   <p style={{
    fontSize:"14px",
    fontWeight:"300",
    color:"gray",
    marginTop:"2px",
    marginBottom:"5px",
   

   }}>Your post will visible on feed, on your profile</p>

   <div>
    
   </div>
   </>

  )
}
