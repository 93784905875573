import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBIcon,
  MDBSpinner,
  MDBTextArea,
} from "mdb-react-ui-kit";
import {
  useMe,
  useUpdateUserById,
  useUserProfileUpdate,
  useUserUpdate,
} from "../../_helper/actions";
import { secureAxios } from "../../_helper/auth";
import AddressModel from "./modals/AddressModel";
import CertificatesModel from "./modals/CertificatesModel";
import ExperienceModel from "./modals/ExperienceModel";
import EducationModel from "./modals/EducationModel";
import InitiativeModel from "./modals/InitiativeModel";
import PatentsModel from "./modals/PatentsModel";
import ProjectModel from "./modals/ProjectModel";
import AwardsModel from "./modals/AwardsModel";
import EditMembership from "./EditMembership";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import SocietalModel from "./modals/Societal";
import { getImageURL } from "../../_helper/image";

export default function ProfileInfo() {
  //  All Hooks and states are here
  const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [city, setcity] = useState("");
  const [nativevillage, setNativeVillage] = useState("");
  const [nativedistrict, setNativeDistrict] = useState("");
  const [role, setRole] = useState("");
  const [father, setFather] = useState("");
  const [about, setAbout] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [openCertificateModel, setopenCertificateModal] = useState(false);
  const [openExperienceModel, setopenExperienceModel] = useState(false);
  const [openEducationModel, setopenEducationModel] = useState(false);
  const [openInitiativeModel, setopenInitiativeModel] = useState(false);
  const [openPatentsModel, setopenPatentsModel] = useState(false);
  const [openprojectModel, setopenProjectModel] = useState(false);
  const [openawardModel, setopenAwardModel] = useState(false);
  const [opensociatalModel, setopenSocitalModal] = useState(false);

  const [editid, setEditID] = useState("");
  const [CertificateEditId, setCertificateEditID] = useState("");
  const [ExperienceEditId, setExperienceEditId] = useState("");
  const [EducationEditId, setEducationEditId] = useState("");
  const [InitiativeEditId, setInitiativeEditId] = useState("");
  const [patentEditId, setPatentEditId] = useState("");
  const [projectEditId, setProjectEditId] = useState("");
  const [awardEditId, setAwardEditId] = useState("");
  const [sociatalEditId, setsociatalEditId] = useState("");

  const [addressdata, setAddressData] = useState([]);
  const [AllCertificateData, setAllCertificateData] = useState([]);
  const [AllExperienceData, setAllExperienceData] = useState([]);
  const [AllEducationData, setAllEducationData] = useState([]);
  const [AllInitiativeData, setAllInitiativeData] = useState([]);
  const [AllpatentData, setAllpatentData] = useState([]);
  const [AllprojectData, setAllprojectData] = useState([]);
  const [AllawardData, setAllawardData] = useState([]);
  const [allsocitalData, setAllSocitalData] = useState([]);

  const [data, setData] = useState({
    street_no_1: "",
    street_no_2: "",
    city: "",
    pincode: "",
    country: "",
    state: "",
  });
  const [certificatesData, setCertificateData] = useState({
    name: "",
    issuing_organization: "",
    issue_date: "",
    expiration_date: "",
    certification_id: "",
    description: "",
    doc_img: "",
    URL: "",
  });

  const [ExperienceData, setExperienceData] = useState({
    title: "",
    employment_type: "",
    location: "",
    start_date: "",
    end_date: "",
    headline: "",
    industry: "",
    description: "",
  });

  const [EducationData, setEducationData] = useState({
    school: "",
    degree: "",
    field_of_study: "",
    start_date: "",
    end_date: "",
    grade: "",
    activities: "",
  });

  const [InitiativesData, setInitiativeData] = useState({
    title: "",
    organisation_name: "",
    description: "",
    start_date: "",
    no_end_date: "",
    end_date: "",
    doc_img: "",
    URL: "",
  });

  const [PatentData, setPatentData] = useState({
    patent_title: "",
    patent_or_application_number: "",
    inventor_name: "",
    status: "",
    issue_date: "",
    description: "",
    URL: "",
    doc_img: "",
  });

  const [ProjectData, setProjectData] = useState({
    name: "",
    description: "",
    assoicated_org: "",
    URL: "",
    doc_img: "",
  });

  const [AwardData, setAwardData] = useState({
    title: "",
    in_associated_with: "",
    issued_by_org: "",
    issued_date: "",
    description: "",
  });

  const [sociatalData, setSociatalData] = useState({
    organisation_name: "",
    designation: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  // On change function on Models

  const onChange1 = (data) => {
    setData(data);
  };

  const onChangeCertificate = (data) => {
    setCertificateData(data);
  };

  const onChangeExperience = (data) => {
    setExperienceData(data);
  };

  const onChangeEducation = (data) => {
    setEducationData(data);
  };

  const onChangeInitiativeData = (data) => {
    setInitiativeData(data);
  };

  const onChangePatentData = (data) => {
    setPatentData(data);
  };

  const onChangeProjectData = (data) => {
    setProjectData(data);
  };

  const onChangeAwardData = (data) => {
    setAwardData(data);
  };

  const onChangeSocitalData = (data) => {
    setSociatalData(data);
  };

  //console.log(sociatalData)

  // On Submit and Edit Functions of all Models

  const sentData = () => {
    // console.log(addressdata)

    if (addressdata.length === 0) {
      setAddressData([data]);

      setOpenModal(false);
    } else {
      setAddressData([...addressdata, data]);

      setOpenModal(false);
    }

    if (editid !== "") {
      const obj = addressdata[editid];
      obj.street_no_1 = data.street_no_1;
      obj.street_no_2 = data.street_no_2;
      obj.city = data.city;
      obj.pincode = data.pincode;
      obj.country = data.country;
      obj.State = data.state;
      setAddressData(addressdata);
      setOpenModal(false);
      setEditID("");
    }
  };

  const sentCertificateData = () => {
    if (AllCertificateData.length === 0) {
      setAllCertificateData([certificatesData]);

      setopenCertificateModal(false);
    } else {
      setAllCertificateData([...AllCertificateData, certificatesData]);

      setopenCertificateModal(false);
    }

    if (CertificateEditId !== "") {
      const obj = AllCertificateData[CertificateEditId];
      obj.name = certificatesData.name;
      obj.certification_id = certificatesData.certification_id;
      obj.issue_date = certificatesData.issue_date;
      obj.expiration_date = certificatesData.expiration_date;
      obj.description = certificatesData.description;
      obj.doc_img = certificatesData.doc_img;
      obj.URL = certificatesData.URL;
      obj.issuing_organization = certificatesData.issuing_organization;
      setAllCertificateData(AllCertificateData);
      setopenCertificateModal(false);
      setCertificateEditID("");
    }
  };

  const sentExperienceData = () => {
    if (AllExperienceData.length === 0) {
      setAllExperienceData([ExperienceData]);

      setopenExperienceModel(false);
    } else {
      setAllExperienceData([...AllExperienceData, ExperienceData]);

      setopenExperienceModel(false);
    }

    if (ExperienceEditId !== "") {
      console.log(ExperienceData);

      const obj = AllExperienceData[ExperienceEditId];
      obj.title = ExperienceData.title;
      obj.employment_type = ExperienceData.employment_type;
      obj.location = ExperienceData.location;
      obj.start_date = ExperienceData.start_date;
      obj.end_date = ExperienceData.end_date;
      obj.headline = ExperienceData.headline;
      obj.industry = ExperienceData.industry;
      obj.description = ExperienceData.description;
      setAllExperienceData(AllExperienceData);
      setopenExperienceModel(false);
      setExperienceEditId("");
    }
  };

  const sentEducationData = () => {
    if (AllEducationData.length === 0) {
      setAllEducationData([EducationData]);

      setopenEducationModel(false);
    } else {
      setAllEducationData([...AllEducationData, EducationData]);

      setopenEducationModel(false);
    }

    if (EducationEditId !== "") {
      const obj = AllEducationData[EducationEditId];
      obj.school = EducationData.school;
      obj.degree = EducationData.degree;
      obj.field_of_study = EducationData.field_of_study;
      obj.start_date = EducationData.start_date;
      obj.end_date = EducationData.end_date;
      obj.grade = EducationData.grade;
      obj.activities = EducationData.activities;
      setAllEducationData(AllEducationData);
      setopenEducationModel(false);
      setEducationEditId("");
    }
  };

  const sentInitiativeData = () => {
    if (AllInitiativeData.length === 0) {
      setAllInitiativeData([InitiativesData]);

      setopenInitiativeModel(false);
    } else {
      setAllInitiativeData([...AllInitiativeData, InitiativesData]);

      setopenInitiativeModel(false);
    }

    if (InitiativeEditId !== "") {
      const obj = AllInitiativeData[InitiativeEditId];
      obj.title = InitiativesData.title;
      obj.organisation_name = InitiativesData.organisation_name;
      obj.description = InitiativesData.description;
      obj.start_date = InitiativesData.start_date;
      obj.no_end_date = InitiativesData.no_end_date;
      obj.end_date = InitiativesData.end_date;
      obj.doc_img = InitiativesData.doc_img;
      obj.URL = InitiativesData.URL;
      setAllInitiativeData(AllInitiativeData);
      setopenInitiativeModel(false);
      setInitiativeEditId("");
    }
  };

  const sentPatentData = () => {
    if (AllpatentData.length === 0) {
      setAllpatentData([PatentData]);

      setopenPatentsModel(false);
    } else {
      setAllpatentData([...AllpatentData, PatentData]);

      setopenPatentsModel(false);
    }

    if (patentEditId !== "") {
      const obj = AllpatentData[patentEditId];
      obj.patent_title = PatentData.patent_title;
      obj.patent_or_application_number =
        PatentData.patent_or_application_number;
      obj.inventor_name = PatentData.inventor_name;
      obj.status = PatentData.status;
      obj.issue_date = PatentData.issue_date;
      obj.description = PatentData.description;
      obj.doc_img = PatentData.doc_img;
      obj.URL = PatentData.URL;
      setAllpatentData(AllpatentData);
      setopenPatentsModel(false);
      setPatentEditId("");
    }
  };

  const sentAwardData = () => {
    if (AllawardData.length === 0) {
      setAllawardData([AwardData]);

      setopenAwardModel(false);
    } else {
      setAllawardData([...AllawardData, AwardData]);

      setopenAwardModel(false);
    }

    if (awardEditId !== "") {
      console.log(awardEditId);
      console.log(AwardData);
      const obj = AllawardData[awardEditId];
      obj.title = AwardData.title;
      obj.description = AwardData.description;
      obj.issued_date = AwardData.issued_date;
      obj.issued_by_org = AwardData.issued_by_org;
      obj.in_associated_with = AwardData.in_associated_with;

      setAllawardData(AllawardData);
      setopenAwardModel(false);
      setAwardEditId("");
    }
  };

  const sentProjectData = () => {
    if (AllprojectData.length === 0) {
      setAllprojectData([ProjectData]);

      setopenProjectModel(false);
    } else {
      setAllprojectData([...AllprojectData, ProjectData]);

      setopenProjectModel(false);
    }

    if (projectEditId !== "") {
      const obj = AllprojectData[projectEditId];
      obj.name = ProjectData.name;
      obj.description = ProjectData.description;
      obj.assoicated_org = ProjectData.assoicated_org;
      obj.URL = ProjectData.URL;
      obj.doc_img = ProjectData.doc_img;

      setAllprojectData(AllprojectData);
      setopenProjectModel(false);
      setProjectEditId("");
    }
  };

  const sentSocitalData = () => {
    if (allsocitalData.length === 0) {
      setAllSocitalData([sociatalData]);

      setopenSocitalModal(false);
    } else {
      setAllSocitalData([...allsocitalData, sociatalData]);

      setopenSocitalModal(false);
    }

    if (sociatalEditId !== "") {
      const obj = allsocitalData[sociatalEditId];
      obj.organisation_name = sociatalData.organisation_name;
      obj.description = sociatalData.description;
      obj.start_date = sociatalData.start_date;
      obj.end_date = sociatalData.end_date;
      obj.designation = sociatalData.designation;
      obj.url = sociatalData.url;
      setAllSocitalData(allsocitalData);
      setopenSocitalModal(false);
      setsociatalEditId("");
    }
  };

  //  Seprate mobile and city Field

  const handleMobile = (eve) => {
    setMobile(eve.target.value);
  };

  const handlecity = (eve) => {
    setcity(eve.target.value);
  };

  const handleNativeVillage = (eve) => {
    setNativeVillage(eve.target.value);
  };

  const handleNativeDistrict = (eve) => {
    setNativeDistrict(eve.target.value);
  };
  const handleRole = (eve) => {
    setRole(eve.target.value);
  };
  const handleFather = (eve) => {
    setFather(eve.target.value);
  };

  const handleAbout = (eve) => {
    setAbout(eve.target.value);
  };

  // Get Data from url
  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get("id");

  // Get Data form the DataBase

  const getData = () => {
    secureAxios
      .get(`/users/${user_id ? user_id : "me"}?fields=*.*`)
      .then((data) => {
        console.log(data.data.profile[0]);

        //setProfile_id(data?.data?.profile[0].id)
        localStorage.setItem("user_id", data?.data?.profile[0].id);
        //localStorage.setItem("business_name", data?.data?.business_profiles[0].id)
        setMobile(
          data?.data?.profile[0].mobile === null
            ? ""
            : `${data.data?.profile[0].mobile}`
        );
        setcity(
          data?.data?.profile[0].current_city === null
            ? ""
            : `${data.data.profile[0].current_city}`
        );
        setAbout(
          data?.data?.profile[0].about === null
            ? ""
            : `${data.data.profile[0].about}`
        );
        setNativeVillage(
          data?.data?.profile[0].native_village === null
            ? ""
            : `${data.data.profile[0].native_village}`
        );
        setNativeDistrict(
          data?.data?.profile[0].native_district === null
            ? ""
            : `${data.data.profile[0].native_district}`
        );
        setRole(
          data?.data?.profile[0].LCCIA_Designation === null
            ? ""
            : `${data.data.profile[0].LCCIA_Designation}`
        );
        setFather(
          data?.data?.profile[0].father_or_husband === null
            ? ""
            : `${data.data.profile[0].father_or_husband}`
        );
        setAddressData(data?.data?.profile[0].addresss);
        setAllCertificateData(
          data?.data?.profile[0].licenses_or_certifications === null ||
            undefined
            ? []
            : data?.data?.profile[0].licenses_or_certifications
        );
        setAllExperienceData(
          data?.data?.profile[0].experiences === null || undefined
            ? []
            : data?.data?.profile[0].experiences
        );
        setAllawardData(
          data?.data?.profile[0].honors_and_awards === null || undefined
            ? []
            : data?.data?.profile[0].honors_and_awards
        );
        setAllprojectData(
          data?.data?.profile[0].projects === null || undefined
            ? []
            : data?.data?.profile[0].projects
        );
        setAllpatentData(
          data?.data?.profile[0].patents === null || undefined
            ? []
            : data?.data?.profile[0].patents
        );
        setAllEducationData(
          data?.data?.profile[0].education_details === null || undefined
            ? []
            : data?.data?.profile[0].education_details
        );
        setAllInitiativeData(
          data?.data?.profile[0].initiatives === null || undefined
            ? []
            : data?.data?.profile[0].initiatives
        );

        setAllSocitalData(
          data?.data?.profile[0].sociatal === null ||
            data?.data?.profile[0].sociatal === undefined
            ? []
            : data?.data?.profile[0].sociatal
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Mutation to update fields in profile

  const mutation = useUserProfileUpdate(() => console.log("Profile Updated"));
  const user_update_mutation = useUpdateUserById(user_id);

  const onSubmitProfileInfo = () => {
    console.log(AllawardData);

    const profiledata = {
      current_city: city,
      mobile: mobile,
      father_or_husband: father,
      native_village: nativevillage,
      native_district: nativedistrict,
      about: about,
      LCCIA_Designation: role,
      addresss: addressdata,
      licenses_or_certifications: AllCertificateData,
      experiences: AllExperienceData,
      honors_and_awards: AllawardData,
      projects: AllprojectData,
      patents: AllpatentData,
      education_details: AllEducationData,
      initiatives: AllInitiativeData,
      sociatal: allsocitalData,
    };
    console.log(profiledata);

    if (user_id) {
      user_update_mutation.mutate(profiledata);
    } else {
      mutation.mutate(profiledata);
    }
  };

  //  To load the data from there server once componet is load

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* Pop Up for Add Address */}
      <AddressModel
        onChange1={onChange1}
        ashow={openModal}
        asetShow={setOpenModal}
        sentData={sentData}
        reciveData={data}
      />
      {/* Pop Up for Add Certificate  */}
      <CertificatesModel
        cshow={openCertificateModel}
        csetShow={setopenCertificateModal}
        onChangeCertificate={onChangeCertificate}
        reciveData={certificatesData}
        sentCertificateData={sentCertificateData}
      />

      <ExperienceModel
        exshow={openExperienceModel}
        exsetShow={setopenExperienceModel}
        onChangeExperience={onChangeExperience}
        reciveData={ExperienceData}
        sentExperienceData={sentExperienceData}
      />

      <EducationModel
        edshow={openEducationModel}
        edsetShow={setopenEducationModel}
        onChangeEducation={onChangeEducation}
        reciveData={EducationData}
        sentEducationData={sentEducationData}
      />

      <InitiativeModel
        Inshow={openInitiativeModel}
        InsetShow={setopenInitiativeModel}
        onChangeInitiativeData={onChangeInitiativeData}
        reciveData={InitiativesData}
        sentInitiativeData={sentInitiativeData}
      />

      <PatentsModel
        Pshow={openPatentsModel}
        PsetShow={setopenPatentsModel}
        onChangePatentData={onChangePatentData}
        reciveData={PatentData}
        sentPatentData={sentPatentData}
      />

      <ProjectModel
        prshow={openprojectModel}
        prsetShow={setopenProjectModel}
        onChangeProjectData={onChangeProjectData}
        reciveData={ProjectData}
        sentProjectData={sentProjectData}
      />

      <AwardsModel
        awshow={openawardModel}
        awsetShow={setopenAwardModel}
        onChangeAwardData={onChangeAwardData}
        reciveData={AwardData}
        sentAwardData={sentAwardData}
      />

      <SocietalModel
        soshow={opensociatalModel}
        sosetShow={setopenSocitalModal}
        onChangeSocietal={onChangeSocitalData}
        reciveData={sociatalData}
        sentSociatalData={sentSocitalData}
      />

      <h4
        style={{ marginLeft: "12px", marginTop: "15px", marginBottom: "15px" }}
      >
        Profile Information
      </h4>
      {/* All Profile Input fields and Pop Up data */}
      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <div className="d-flex flex-row alignitems-center mb-4">
                  {/* For Mobile Input */}

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Mobile"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${mobile}`}
                    onChange={handleMobile}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  {/* For city Input */}

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="city"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${city}`}
                    onChange={handlecity}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  {/* For city Input */}

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Native Village"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${nativevillage}`}
                    onChange={handleNativeVillage}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  {/* For city Input */}

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Native District"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${nativedistrict}`}
                    onChange={handleNativeDistrict}
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-4">
                  {/* For city Input */}

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="LCCIA Role"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={`${role}`}
                    onChange={handleRole}
                  />
                </div>

                <div className="d-flex flex-row align-items-center mb-4">
                  {/* For city Input */}

                  <MDBTextArea
                    wrapperClass="mb-2 w-100"
                    label="About"
                    id="formControlLg"
                    type="text"
                    size="md"
                    rows={4}
                    value={`${about}`}
                    onChange={handleAbout}
                  />
                </div>

                {/* Add Address Button */}

                <button
                  className="mt-2 py-1"
                  style={{
                    width: "10rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setData({
                      street_no_1: "",
                      street_no_2: "",
                      city: "",
                      pincode: "",
                      country: "",
                      state: "",
                    });
                    setOpenModal(!openModal);
                  }}
                >
                  <MDBIcon fas icon="address-book" className="px-1" />
                  Address
                </button>
                {/* Show added all Added Address */}
                {addressdata.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setData({
                            street_no_1: `${value.street_no_1}`,
                            street_no_2: `${value.street_no_2}`,
                            city: `${value.city}`,
                            pincode: `${value.pincode}`,
                            country: `${value.country}`,
                            state: `${value.state}`,
                          });
                          setEditID(`${idx}`);
                          setOpenModal(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          addressdata.splice(idx, 1);
                          setAddressData([...addressdata]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>
                    <p className="" style={{ fontSize: "14px" }}>
                      Street No.1 : {value.street_no_1}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Street No.2 : {value.street_no_2}
                    </p>
                    <p style={{ fontSize: "14px" }}>city : {value.city}</p>
                    <p style={{ fontSize: "14px" }}>State : {value.state} </p>
                    <p style={{ fontSize: "14px" }}>
                      Country : {value.country}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Pincode : {value.pincode}
                    </p>
                  </div>
                ))}

                {/* Add Certificate button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setCertificateData({
                      name: "",
                      issuing_organization: ``,
                      issue_date: ``,
                      expiration_date: ``,
                      certification_id: ``,
                      description: "",
                      doc_img: "",
                      URL: ``,
                    });

                    setopenCertificateModal(!openCertificateModel);
                  }}
                >
                  <MDBIcon far icon="folder-open" className="px-1" />
                  Add Certificates
                </button>

                {/* Show all added certificates */}

                {AllCertificateData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setCertificateData({
                            name: `${value.name}`,
                            issuing_organization: `${value.issuing_organization}`,
                            issue_date: `${
                              value.issue_date === null || undefined
                                ? " "
                                : value.issue_date.slice(0, 10)
                            }`,
                            expiration_date: `${
                              value.expiration_date === "" || undefined
                                ? " "
                                : value.expiration_date
                            }`,
                            certification_id: `${value.certification_id}`,
                            description: `${value.description}`,
                            doc_img: `${value.doc_img}`,
                            URL: `${value.URL}`,
                          });
                          setCertificateEditID(`${idx}`);
                          setopenCertificateModal(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllCertificateData.splice(idx, 1);
                          setAllCertificateData([...AllCertificateData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    {value.doc_img !== undefined ? (
                      <>
                        <p>
                          <img
                            style={{ width: "10%", height: "10%" }}
                            src={`${getImageURL(value.doc_img)}`}
                          />
                        </p>
                        <p className="" style={{ fontSize: "14px" }}>
                          Certificate Image
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    <p className="" style={{ fontSize: "14px" }}>
                      Name : {value.name}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Issuing Organization : {value.issuing_organization}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Issue Date :{" "}
                      {value.issue_date === undefined
                        ? ""
                        : value.issue_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Expiration Date :{" "}
                      {value.expiration_date === undefined
                        ? ""
                        : value.expiration_date.slice(0, 10)}{" "}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Certification Id : {value.certification_id}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                    <p style={{ fontSize: "14px" }}>URL : {value.URL}</p>
                  </div>
                ))}

                {/* Add Experience button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setExperienceData({
                      title: "",
                      employment_type: ``,
                      location: ``,
                      start_date: ``,
                      end_date: ``,
                      headline: ``,
                      industry: ``,
                      description: ``,
                    });

                    setopenExperienceModel(!openExperienceModel);
                  }}
                >
                  <MDBIcon far icon="pen" className="px-1" />
                  Add Experience
                </button>

                {/* Show all added certificates */}

                {AllExperienceData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setExperienceData({
                            title: `${value.title}`,
                            employment_type: `${value.employment_type}`,
                            location: `${value.location}`,
                            start_date: `${
                              value.start_date === undefined
                                ? ""
                                : value.start_date.slice(0, 10)
                            }`,
                            end_date: `${
                              value.end_date === undefined
                                ? ""
                                : value.end_date.slice(0, 10)
                            }`,
                            headline: `${value.headline}`,
                            industry: `${value.industry}`,
                            description: `${value.description}`,
                          });
                          setExperienceEditId(`${idx}`);
                          setopenExperienceModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllExperienceData.splice(idx, 1);
                          setAllExperienceData([...AllExperienceData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    <div className="">
                      <p className="" style={{ fontSize: "14px" }}>
                        Title : {value.title}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Employment Type : {value.employment_type}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Location : {value.location}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Start Date :{" "}
                        {value.start_date === undefined
                          ? ""
                          : value.start_date.slice(0, 10)}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        End Date :{" "}
                        {value.end_date === undefined
                          ? ""
                          : value.end_date.slice(0, 10)}{" "}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Name of organisation : {value.headline}
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        Industry : {value.industry}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Description : {value.description}
                      </p>
                    </div>
                  </div>
                ))}

                {/* Add Education button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setEducationData({
                      school: "",
                      degree: "",
                      field_of_study: "",
                      start_date: "",
                      end_date: "",
                      grade: "",
                      activities: "",
                    });

                    setopenEducationModel(!openEducationModel);
                  }}
                >
                  <i class="fas fa-graduation-cap px-1"></i>
                  Add Education
                </button>

                {/* Show all added Educations */}

                {AllEducationData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setEducationData({
                            school: `${value.school}`,
                            degree: `${value.degree}`,
                            field_of_study: `${value.field_of_study}`,
                            start_date: `${
                              value.start_date === null || undefined
                                ? ""
                                : value.start_date.slice(0, 10)
                            }`,
                            end_date: `${
                              value.end_date === null || undefined
                                ? ""
                                : value.end_date.slice(0, 10)
                            }`,
                            grade: `${value.grade}`,
                            activities: `${value.activities}`,
                          });
                          setEducationEditId(`${idx}`);
                          setopenEducationModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllEducationData.splice(idx, 1);
                          setAllEducationData([...AllEducationData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    <p className="" style={{ fontSize: "14px" }}>
                      School : {value.school}
                    </p>
                    <p style={{ fontSize: "14px" }}>Degree : {value.degree}</p>
                    <p style={{ fontSize: "14px" }}>
                      Field Of Study: {value.field_of_study}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Start Date :{" "}
                      {value.start_date === null || undefined
                        ? ""
                        : value.start_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      End Date :{" "}
                      {value.end_date === null || undefined
                        ? ""
                        : value.end_date.slice(0, 10)}{" "}
                    </p>
                    <p style={{ fontSize: "14px" }}>Grade : {value.grade}</p>
                    <p style={{ fontSize: "14px" }}>
                      Activities : {value.activities}
                    </p>
                  </div>
                ))}

                {/* Add Incentive button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setInitiativeData({
                      title: "",
                      description: "",
                      organisation_name: "",
                      start_date: "",
                      no_end_date: "",
                      end_date: "",
                      doc_img: "",
                      URL: "",
                    });

                    setopenInitiativeModel(!openInitiativeModel);
                  }}
                >
                  <i class="fas fa-certificate px-1"></i>
                  Add Initiative
                </button>

                {/* Show all added Incentive */}

                {AllInitiativeData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setInitiativeData({
                            title: `${value.title}`,
                            description: `${value.description}`,
                            organisation_name: `${value.organisation_name}`,
                            start_date: `${value.start_date.slice(0, 10)}`,
                            no_end_date: `${value.no_end_date.slice(0, 10)}`,
                            end_date: `${value.end_date.slice(0, 10)}`,
                            doc_img: `${value.doc_img}`,
                            URL: `${value.URL}`,
                          });
                          setInitiativeEditId(`${idx}`);
                          setopenInitiativeModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllInitiativeData.splice(idx, 1);
                          setAllInitiativeData([...AllInitiativeData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    {value.doc_img !== undefined ? (
                      <>
                        <p>
                          <img
                            style={{ width: "10%", height: "10%" }}
                            src={`${getImageURL(value.doc_img)}`}
                          />
                        </p>
                        <p className="" style={{ fontSize: "14px" }}>
                          Initiative Image
                        </p>
                      </>
                    ) : (
                      ""
                    )}

                    <p className="" style={{ fontSize: "14px" }}>
                      Title : {value.title}
                    </p>
                    <p className="" style={{ fontSize: "14px" }}>
                      Organisation Name : {value.organisation_name}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Start Date:{" "}
                      {value.start_date === undefined
                        ? ""
                        : value.start_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      No End Date :{" "}
                      {value.no_end_date === undefined
                        ? ""
                        : value.no_end_date.slice(0, 10)}{" "}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      End Date :{" "}
                      {value.end_date === undefined
                        ? ""
                        : value.end_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>URL : {value.URL}</p>
                  </div>
                ))}

                {/* Add Patent button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setPatentData({
                      patent_title: "",
                      patent_or_application_number: "",
                      inventor_name: "",
                      status: "",
                      issue_date: "",
                      description: "",
                      URL: "",
                      doc_img: "",
                    });

                    setopenPatentsModel(!openPatentsModel);
                  }}
                >
                  <i class="fas fa-book px-1"></i>
                  Add Patent
                </button>

                {/* Show all added Patents */}

                {AllpatentData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setPatentData({
                            patent_title: `${value.patent_title}`,
                            patent_or_application_number: `${value.patent_or_application_number}`,
                            inventor_name: `${value.inventor_name}`,
                            status: `${value.status}`,
                            issue_date: `${value.issue_date.slice(0, 10)}`,
                            description: `${value.description}`,
                            URL: `${value.URL}`,
                            doc_img: `${value.doc_img}`,
                          });
                          setPatentEditId(`${idx}`);
                          setopenPatentsModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllpatentData.splice(idx, 1);
                          setAllpatentData([...AllpatentData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>
                    {value.doc_img !== undefined ? (
                      <>
                        <p>
                          <img
                            style={{ width: "10%", height: "10%" }}
                            src={`${getImageURL(value.doc_img)}`}
                          />
                        </p>
                        <p className="" style={{ fontSize: "14px" }}>
                          Patent Image
                        </p>
                      </>
                    ) : (
                      ""
                    )}

                    <p className="" style={{ fontSize: "14px" }}>
                      Patent Title : {value.patent_title}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Application Number: {value.patent_or_application_number}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Inventor Name : {value.inventor_name}{" "}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Issue Date : {value.issue_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                    <p style={{ fontSize: "14px" }}>Status : {value.status}</p>
                    <p style={{ fontSize: "14px" }}>URL : {value.URL}</p>
                  </div>
                ))}

                {/* Add Project button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setProjectData({
                      name: "",
                      description: "",
                      assoicated_org: "",
                      URL: "",
                      doc_img: "",
                    });

                    setopenProjectModel(!openprojectModel);
                  }}
                >
                  <i class="fas fa-shapes px-1"></i>
                  Add Project
                </button>

                {/* Show all added Projects */}

                {AllprojectData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      whiteSpace: "nowrap",
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setProjectData({
                            name: `${value.name}`,
                            description: `${value.description}`,
                            assoicated_org: `${value.assoicated_org}`,
                            URL: `${value.URL}`,
                            doc_img: `${value.doc_img}`,
                          });
                          setProjectEditId(`${idx}`);
                          setopenProjectModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllprojectData.splice(idx, 1);
                          setAllprojectData([...AllprojectData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>
                    {value.doc_img !== undefined ? (
                      <>
                        <p>
                          <img
                            style={{ width: "10%", height: "10%" }}
                            src={`${getImageURL(value.doc_img)}`}
                          />
                        </p>
                        <p className="" style={{ fontSize: "14px" }}>
                          Project Image
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    <p className="" style={{ fontSize: "14px" }}>
                      Name : {value.name}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Assoicated Org: {value.assoicated_org}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                    <p style={{ fontSize: "14px" }}>URL : {value.URL}</p>
                  </div>
                ))}

                {/* Add Awards button */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setAwardData({
                      title: "",
                      in_associated_with: "",
                      issued_by_org: "",
                      issued_date: "",
                      description: "",
                    });

                    setopenAwardModel(!openawardModel);
                  }}
                >
                  <i class="fas fa-medal px-1"></i>
                  Add Awards
                </button>

                {/* Show all added Patents */}

                {AllawardData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          setAwardData({
                            title: `${value.title}`,
                            in_associated_with: `${value.in_associated_with}`,
                            issued_by_org: `${value.issued_by_org}`,
                            issued_date: `${value.issued_date.slice(0, 10)}`,
                            description: `${value.description}`,
                          });
                          setAwardEditId(`${idx}`);
                          setopenAwardModel(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          AllawardData.splice(idx, 1);
                          setAllawardData([...AllawardData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    <p className="" style={{ fontSize: "14px" }}>
                      Title : {value.title}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Associated With: {value.in_associated_with}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Issued By : {value.issued_by_org}{" "}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Issue Date :{" "}
                      {value.issued_date === null || undefined
                        ? ""
                        : value.issued_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                  </div>
                ))}

                <button
                  className="mt-4 py-1"
                  style={{
                    width: "12rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={() => {
                    setSociatalData({
                      organisation_name: "",
                      designation: "",
                      start_date: "",
                      end_date: "",
                      description: "",
                      url: "",
                    });
                    setopenSocitalModal(!opensociatalModel);
                  }}
                >
                  <i class="fas fa-shapes px-1"></i>
                  Add Societal
                </button>

                {allsocitalData.map((value, idx) => (
                  <div
                    className=""
                    style={{
                      border: "1px solid #2596be",
                      borderRadius: "10px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      padding: "10px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="flex" style={{ float: "right" }}>
                      <button
                        style={{
                          backgroundColor: "blue",
                          color: "whitesmoke",
                          border: "none",
                          margin: "4px",
                        }}
                        onClick={() => {
                          //setSociatalData({})
                          setSociatalData({
                            organisation_name: `${value.organisation_name}`,
                            designation: `${value.designation}`,
                            start_date: `${
                              value.start_date === undefined
                                ? ""
                                : value.start_date.slice(0, 10)
                            }`,
                            end_date: `${
                              value.end_date === undefined
                                ? ""
                                : value.end_date.slice(0, 10)
                            }`,
                            description: `${value.description}`,
                            url: `${!value.url ? "" : value.url}`,
                          });
                          setsociatalEditId(`${idx}`);
                          setopenSocitalModal(true);
                        }}
                      >
                        <MDBIcon fas icon="edit" />
                      </button>
                      <button
                        style={{
                          backgroundColor: "red",
                          color: "whitesmoke",
                          border: "none",
                        }}
                        onClick={() => {
                          allsocitalData.splice(idx, 1);
                          setAllSocitalData([...allsocitalData]);
                        }}
                      >
                        <MDBIcon fas icon="close" />
                      </button>
                    </div>

                    <p className="" style={{ fontSize: "14px" }}>
                      Organisation Name: {value.organisation_name}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Designation: {value.designation}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Start Date :{" "}
                      {value.start_date === undefined
                        ? ""
                        : value.start_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      End Date :{" "}
                      {value.end_date === null || undefined
                        ? ""
                        : value.end_date.slice(0, 10)}
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      Description : {value.description}
                    </p>
                    <p style={{ fontSize: "14px" }}>URL : {value.url}</p>
                  </div>
                ))}

                {/* Submit button to Submit the Profile Form */}
                <button
                  className="mt-4 py-1"
                  style={{
                    width: "8rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={onSubmitProfileInfo}
                >
                  {mutation.isLoading ? (
                    <MDBSpinner color="light" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>

                {mutation.isSuccess || user_update_mutation.isSuccess ? (
                  <div
                    class="alert alert-success text-center mt-2"
                    role="alert"
                  >
                    <i class="fa fa-info-circle"></i> Data Saved Successfully!
                  </div>
                ) : (
                  ""
                )}
                {mutation.isSuccess ? navigate(`/profile/view`) : ""}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
