import {
    MDBBreadcrumb,
    MDBBreadcrumbItem,
    MDBCard,
    MDBContainer,
    MDBIcon,
    MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { secureAxios } from "../../../_helper/auth";
import BusinessInfo from "../DetailPageComponents/BusinessInfo";

export default function ManageBusinessProfiles() {
    const [buisnessData, setBuisnessData] = useState([]);
    const [datastatus, setDataStatus] = useState(false);
    const navigate = useNavigate();
    const getData = async () => {
        await secureAxios
            .get(
                `users/me?fields=business_profiles.*,business_profiles.categories.category_id.*`
            )
            .then((res) => {
                console.log(res.data);
                setBuisnessData(res.data.business_profiles);
                setDataStatus(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getData();
    }, []);
    return !datastatus ? (
        <div className="d-flex justify-content-center">
            <MDBSpinner role="status" color="success" />
        </div>
    ) : (
        <>
            <div className="d-flex">
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem>
                        <a style={{ cursor: "default" }}>Home</a>
                    </MDBBreadcrumbItem>
                    <MDBBreadcrumbItem>
                        <a style={{ cursor: "default" }}>My Profile</a>
                    </MDBBreadcrumbItem>

                    <MDBBreadcrumbItem active>
                        <a
                            style={{
                                cursor: "default",
                            }}
                        >
                            Manage Business Profiles
                        </a>
                    </MDBBreadcrumbItem>
                </MDBBreadcrumb>
            </div>
            {}
            <div className="d-flex">
                <button
                    className="text-center text-white rounded border-0 px-4 py-2"
                    style={{
                        marginLeft: "auto",
                        fontSize: "14px",
                        marginTop: "0px",
                        borderRadius: "5px",
                        marginBottom: "40px",
                        backgroundColor: "#F58A2E",
                    }}
                    onClick={() => {
                        navigate("/profile/edit-business-profile/add");
                    }}
                >
                    <MDBIcon size="lg" far icon="add" className="px-1" />
                    Add Business Profile
                </button>
            </div>

            {buisnessData?.length > 0 ? (
                buisnessData?.map((value, idx) => (
                    <MDBContainer className="mb-4">
                        <MDBCard>
                            <div className="">
                                <button
                                    className="text-center text-white rounded border-0 px-4 py-2"
                                    style={{
                                        float: "right",
                                        fontSize: "14px",
                                        borderRadius: "5px",
                                        marginTop: "20px",
                                        marginRight: "20px",
                                        backgroundColor: "#F58A2E",
                                    }}
                                    onClick={() => {
                                        navigate(
                                            `/profile/edit-business-profile/${idx}`
                                        );
                                    }}
                                >
                                    <MDBIcon
                                        size="lg"
                                        far
                                        icon="edit"
                                        className="px-1"
                                    />
                                    Edit
                                </button>
                            </div>
                            <BusinessInfo
                                AllBusinessData={value}
                                manage={true}
                            />
                        </MDBCard>
                    </MDBContainer>
                ))
            ) : (
                <p>No business profile available</p>
            )}
        </>
    );
}
