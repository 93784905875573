import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardSubTitle,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Avatar from "../../../../media/avatar2.jpeg";
import { getImageURL } from "../../../../_helper/image";
import ViewDocumentModal from "./ViewDocumentModal";

export default function UserDocumentCard({
  mname,
  img,
  email,
  current_city,
  nativeVillage,
  nativeDistrict,
  lcciaRole,
  tag,
  docData = [],
  id,
  status
}) {
    const [openmodal,setopenmodal] = useState(false);
    const [type,setType] = useState("");
    const [file,setFile] = useState("");
    const [doc_id,setDocId] = useState("");

  console.log(docData);

  const onGetUpdate = (data) => {
    status(data)
  }
  return (
    <>
    <ViewDocumentModal vshow={openmodal} setvshow = {setopenmodal} file={file} type={type} id={doc_id} status={onGetUpdate}/>
      {docData.length !== 0 ? (
        <MDBContainer fluid>
          <MDBRow className="">
            <MDBCard
              style={{
                width: "100%",
                margin: "10px",
                padding: "15px",
              }}
            >
              <MDBRow className="lg-3">
                <MDBCol sm="2">
                  <MDBCardImage
                    src={img !== " " ? img : Avatar}
                    alt="..."
                    fluid
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  />
                </MDBCol>
                <MDBCol xl="1"></MDBCol>
                <MDBCol sm="4">
                  <MDBCardBody style={{ padding: "6px" }}>
                    <MDBCardTitle>
                      {" "}
                      {`${mname}`} <br></br>
                      <MDBCardSubTitle>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "350",
                            color: "#808080",
                            marginTop: "10px",
                          }}
                        >{`${nativeVillage}, ${nativeDistrict}`}</p>
                      </MDBCardSubTitle>
                    </MDBCardTitle>

                    <MDBCardText>
                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="location"
                            style={{ color: "#F58A2E" }}
                            size="sm"
                          />
                        </div>
                        <div className="col"> {`${current_city}`}</div>
                      </div>

                      <div className="row ">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="envelope"
                            size="sm"
                            style={{ color: "#F58A2E" }}
                          />
                        </div>

                        <div className="col"> {`${email}`}</div>
                      </div>

                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="user"
                            size="sm"
                            style={{ color: "#F58A2E" }}
                          />
                        </div>{" "}
                        <div className="col">
                          <span className="">{`${lcciaRole}`}</span>
                        </div>
                      </div>

                      {tag === "" || tag === null || tag === undefined ? (
                        ""
                      ) : (
                        <div className="row py-1">
                          <div className="col-1">
                            <MDBIcon
                              fas
                              icon="user-tag"
                              size="sm"
                              style={{ color: "#F58A2E" }}
                            />
                          </div>{" "}
                          <div className="col">
                            <span className="">{`${tag}`}</span>
                          </div>
                        </div>
                      )}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
              <MDBContainer className="mt-3">
                <h4>Uploaded Documents</h4>
                <MDBRow>
                  {docData.map((items, idx) => (
                    items.status === 'approve' || items.status === 'approved' || items.status === "rejected"?
                    <MDBCol sm={4}>
                      <div className="">
                        <img
                          src={getImageURL(`${items.file}`)}
                          className="preview-img mt-4"
                          alt="preview"
                          style={{ width: "200px" }}
                        />

                        <div className="mt-3 d-flex">
                          <button
                            className="text-center"
                            style={{
                              width: "80%",

                              borderColor: "#1b5ff2",
                              color: "#1b5ff2",
                              backgroundColor: "#ffff",
                              borderRadius: "5px",
                            }}
                            onClick = {()=>{
                                setFile(items.file);
                                setType(items.type);
                                setDocId(items.id)
                                setopenmodal(!openmodal)
                            }}
                          >
                            {" "}
                            <MDBIcon fas icon="view" className="px-1" />
                            {items.status === "approved"?`${items.type} Approved `:items.status === "rejected"?`${items.type} Rejected`:`View ${items.type}` }
                            
                          </button>
                        </div>
                      </div>
                    </MDBCol>
                    :<p style={{color:'red'}}>{`${items.type} is in draft.`}</p>
                  ))}
                </MDBRow>
              </MDBContainer>
            </MDBCard>
          </MDBRow>
        </MDBContainer>
      ) : (
        ""
      )}
    </>
  );
}
