import React from "react";
import { useParams } from "react-router-dom";
import ApplyMembership from "./membership-verification/ApplyMembership";
import MaintainChapters from "./membership-verification/MaintainChapters";
import MaintainDocumentType from "./membership-verification/MaintainDocumentType";
import MaintainMembership from "./membership-verification/MaintainMembership";
import MasterTag from "./membership-verification/MasterTag";
import MemberShipSetup from "./membership-verification/MemberShipSetup";
import MembershipVerification from "./membership-verification/MembershipVerification";
import Navbar from "./navbar";
import MyReferences from "./settings/MyReferences";

export default function MembershipVerificationRouting() {
  const { id } = useParams();

  if (id === "approval") {
    return (
      <>
        <Navbar />
       <main>
              <MembershipVerification />
              </main>
      </>
    );
  } else if (id === "setup") {
    return (
      <>
        <Navbar />
      <main>
              <MemberShipSetup />
              </main>
      </>
    );
  } else if (id === "setup-document-type") {
    return (
      <>
        <Navbar />
       <main>
              <MaintainDocumentType />
              </main>
      </>
    );
  } else if (id === "setup-chapters") {
    return (
      <>
        <Navbar />
     <main>
              <MaintainChapters />
              </main>
      </>
    );
  } else if (id === "setup-tags") {
    return (
      <>
        <Navbar />
        <main>
              <MasterTag />
              </main>
      </>
    );
  } else if (id === "reference-membership") {
    return (
      <>
        <Navbar />
          <main>
              <MyReferences />
              </main>
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <main>
          <ApplyMembership />
        </main>
      </>
    );
  }
}
