import {
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBTextArea,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Select from 'react-select';
import { usePostDocumentType } from "../../../_helper/MembershipSetupAction";

export default function AddTypeModel({ openaddmodal, setopenAddmodal , onChange, value={},status }) {
    const [doctype_data,setdocTypeData] = useState({});

  

    const mutation = usePostDocumentType((data)=>{console.log('done')})
    const sent = () =>{
      status(true)
        mutation.mutate(value);
        setopenAddmodal(!openaddmodal);
    }

    //console.log(value)
   
    //console.log(doctype_data)
  return (
    <MDBModal show={openaddmodal} setShow={setopenAddmodal} tabIndex="-1">
      <MDBModalDialog scrollable>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Add Document Type</MDBModalTitle>
            <button
              className="btn-close"
              color="none"
              onClick={() => {

              
                setdocTypeData({})
                setopenAddmodal(!openaddmodal)}
              }
                X
            ></button>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="d-flex flex-row alignitems-center mb-3">
              <MDBInput
                wrapperClass="mb-4 w-100"
                label="Name"
                id="formControlLg"
                type="text"
                size="md"
                autoComplete="off"
                onChange={(event)=>{
                    //setdocTypeData(value)

                    //setdocTypeData({...doctype_data,type:event.target.value})
                    onChange({...value,type:event.target.value})
                }}
                value = {value.type === undefined ? "" :value.type} 
              />
            </div>
            <div className="d-flex flex-row alignitems-center ">
              <MDBTextArea
                wrapperClass="mb-4 w-100"
                label="Description"
                id="formControlLg"
                type="text"
                size="md"
                rows={3}
                autoComplete="off"
                onChange={(event) => {
                    //setdocTypeData(value)

                    //setdocTypeData({...doctype_data,description:event.target.value})
                    onChange({...value,description:event.target.value})
                }}
               value={value.description === undefined ? "" :value.description}
              />
            </div>

            <div className="d-flex flex-row alignitems-center ">
                <Select
                placeholder="Category"
                isSearchable={false}
                options={[{label:"individual",value:"0"},{label:'organisation',value:1}]}
                className="w-100 mb-4"
                onChange={(event)=>{
                    //setdocTypeData(value)

                    //setdocTypeData({...doctype_data,status:event.label==='Active'?true:false})
                    onChange({...value,category:event.label})
                }}
                value={!value.category?"":{label:value.category}}
                
                />
            </div>

            <div className="d-flex flex-row alignitems-center ">
                <Select
                placeholder="Status"
                isSearchable={false}
                options={[{label:"Active",value:"0"},{label:'InActive',value:1}]}
                className="w-100 mb-4"
                onChange={(event)=>{
                    //setdocTypeData(value)

                    //setdocTypeData({...doctype_data,status:event.label==='Active'?true:false})
                    onChange({...value,status:event.label==='Active'?true:false})
                }}
                value={value.status === undefined || value.status === null ? "" :value.label}
                
                />
            </div>

          </MDBModalBody>
          <MDBModalFooter>
            <button
              className="mt-4 py-1"
              style={{
                width: "8rem",
                color: "white",
                backgroundColor: "#1b5ff2",
                borderRadius: "5px",
                border: "none",
              }}
              onClick={sent}
              // onClick={SubmitEditedData}
            >
              {/* {editmutation.isLoading ? (
              <MDBSpinner color="light" size="sm" />
            ) : (
              <>
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </>
            )} */}
              Save
            </button>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
