import React, { useEffect, useState } from "react";
import { publicAxios, secureAxios } from "../../../_helper/auth";

function TaxanomyData() {
  const parentData = [];
  const [maindata,setMainData] = useState([]);

  const GetData = () => {
    publicAxios
      .get("/items/category?limit=-1")
      .then((res) => {
   

        const data = res.data;
     

        const filterData = (data, id) => {
          return data?.find((obj) => obj.id === id);
       
        };
        const dataPut = () => {
          res.data.forEach((element) => {
            if (!element.parent) {
            
              parentData.push(element);
            }
          });

          return parentData;
        };


       

        const subCategoryDataFunction = (categorylst) => {
          const subcatarr = [];
          categorylst.forEach((id) => {
            const subcatobj = filterData(data, id);
            subcatarr.push(subcatobj);
          });
          return subcatarr;
        };

        const function_depth = (data) => {
          return data?.map((item) => ({
            id:item.id,
            label: item.name,
            children:
              item.subcategories !== 0
                ? function_depth(subCategoryDataFunction(item?.subcategories))
                : [],
          }));
        };

        

        setMainData(function_depth(dataPut()));

  
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData();

   
  }, []);
  return maindata
}

export default TaxanomyData;
