import React, { useEffect } from "react";
import ProfileCard from "./ProfileCard";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSpinner,
  MDBIcon,
} from "mdb-react-ui-kit";
import "../components/style/layout.css";
import AdsSection from "./AdsSection";
import { secureAxios } from "../../../_helper/auth";
import { useState } from "react";
import { getImageURL } from "../../../_helper/image";
import PostSection from "./PostSection";
import "./style/layout.css";
import "./style/postcomponent.css";
import WallMenuCard from "./WallMenuCard";
import { useParams } from "react-router-dom";
import MyRecentPosts from "./MyPosts/MyRecentPosts";
import MenuModel from "./MenuModel";
import CreatePostModel from "./CreatePost/CreatePostModel";
import useScrollPosition from "./ViewPost/useScrollPosition";
import MyProfileCard from "./MyProfileCard";

export default function Layout() {
  const { id } = useParams();
  console.log(id);
  const [modelisopen, setModelIsOpen] = useState(false);

  const [data, setData] = useState({});
  const [businessData, setBusinessData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [staticModal, setStaticModal] = useState(false);
  const [usersData, setUserData] = useState([]);
  const [ispost, setPostDone] = useState(false);

  const isposted = (data) => {
    setPostDone(data);
  };

  const getProfileData = async () => {
    await secureAxios
      .get(
        `/users/me/?fields=*,business_profiles.*,business_profiles.categories.category_id.*,profile.*,role.name,profile.chapter.*`
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const userCreatedData = (data) => {
    //console.log(data)
    setUserData(data);
  };

  return isLoading ? (
    <div className="text-center m-4">
      <MDBSpinner color="success" size="xl" />
    </div>
  ) : (
    <div>
      <div
        className="create-post"
        style={{
          position: "fixed",
          margin: "18px",
          bottom: 0,
          right: 0,
        }}
      >
        <button
          onClick={() => {
            setStaticModal(true);
          }}
          style={{
            backgroundColor: "#F58A2E",
            color: "white",
            borderRadius: "100%",
            width: "40px",
            height: "40px",
            padding: "5px",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
        >
          <MDBIcon fas icon="add" />
        </button>
      </div>
      <div
        className="wall-menu"
        style={{
          position: "fixed",
          margin: "18px",
          bottom: 0,
          right: 0,
        }}
      >
        <button
          onClick={() => {
            setModelIsOpen(true);
          }}
          style={{
            backgroundColor: "#F58A2E",
            color: "white",
            borderRadius: "100%",
            width: "40px",
            height: "40px",
            padding: "5px",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
        >
          <MDBIcon fas icon="bars" />
        </button>
      </div>
      <CreatePostModel
        isPosted={isposted}
        isopen={staticModal}
        setIsOpen={setStaticModal}
        img={
          data?.user_type === "individual"
            ? `${data?.avatar === null ? " " : getImageURL(data?.avatar)}`
            : getImageURL(data.business_profiles[0]?.business_logo)
        }
        data={null}
        isEditOpen={false}
      />
      <MenuModel
        isopen={modelisopen}
        setIsopen={setModelIsOpen}
        profile_img={
          data?.user_type === "individual"
            ? `${data?.avatar === null ? " " : getImageURL(data?.avatar)}`
            : getImageURL(data.business_profiles[0]?.business_logo)
        }
      />
      <MDBContainer className="mt-1">
        <MDBRow className="p-0">
          <MDBCol className="profile-card mx-1 ">
            <div
              className="profile-card"
              style={{
                position: "fixed",

                //offset:"2"
              }}
            >
              <WallMenuCard />
            </div>
          </MDBCol>
          <MDBCol sm="6" className="post_card">
            <div className="post_card">
              {id === "my_posts" && <MyRecentPosts />}
              {!id && (
                <PostSection
                  isPosted={ispost}
                  userCreatedData={userCreatedData}
                  img={
                    data?.user_type === "individual"
                      ? `${
                          data?.avatar === null
                            ? " "
                            : getImageURL(data?.avatar)
                        }`
                      : getImageURL(data.business_profiles[0]?.business_logo)
                  }
                />
              )}
            </div>
          </MDBCol>
          <MDBCol sm={3} className="mx-1 p-0 profile-card">
            <div
              style={{
                position: "fixed",
              }}
            >
              {id ? (
                <MyProfileCard
                  mname={
                    data?.user_type === "individual"
                      ? `${data?.first_name} ${data?.middle_name} ${data?.last_name}`
                      : `${data?.business_profiles[0]?.business_name}`
                  }
                  img={
                    data?.user_type === "individual"
                      ? `${
                          data?.avatar === null
                            ? " "
                            : getImageURL(data?.avatar)
                        }`
                      : getImageURL(data.business_profiles[0]?.business_logo)
                  }
                  nativeVillage={
                    data?.user_type === "individual"
                      ? `${
                          data?.profile[0]?.native_village === null || undefined
                            ? ""
                            : data?.profile[0]?.native_village
                        }`
                      : ""
                  }
                  nativeDistrict={
                    data?.user_type === "individual"
                      ? `${
                          data?.profile[0]?.native_district === null ||
                          undefined
                            ? ""
                            : data?.profile[0]?.native_district
                        }`
                      : ""
                  }
                  LCCIADesignation={`${
                    data?.user?.role?.name === null || undefined
                      ? ""
                      : data?.role?.name
                  }`}
                  email={`${
                    data?.email === null || undefined ? "" : data?.email
                  }`}
                  // //phone={`${data.mobile === null || undefined ? "" : data.mobile}`}
                  expire_date={`${
                    data?.profile[0]?.membership_valid_till === null ||
                    undefined
                      ? ""
                      : data?.profile[0]?.membership_valid_till
                  }`}
                  current_city={`${
                    data?.profile[0]?.current_city === null || undefined
                      ? ""
                      : data?.profile[0]?.current_city
                  }`}
                  lcciaRole={
                    data?.user_type === "individual"
                      ? `${
                          data.LCCIA_Designation === null ||
                          data.LCCIA_Designation === undefined
                            ? ""
                            : data.LCCIA_Designation
                        }`
                      : "Organisation"
                  }
                  tag={
                    data?.user_type === "individual"
                      ? `${data?.profile[0]?.tag === null ? "" : data?.tag}`
                      : ""
                  }
                  chapter={data?.profile[0]?.chapter}
                />
              ) : (
                <ProfileCard usersData={usersData} />
              )}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
