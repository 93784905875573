import React from "react";

export default function WorkExperience({ value = [] }) {
  return (
    <>
      <div>
        {value === undefined || value === null || value?.length === 0 ? (
          ""
        ) : (
          <h4
            style={{
              marginTop: "20px",
              fontWeight: "500",
              color: "#8A8A8A",
            }}
          >
            Work Experience
          </h4>
        )}

        {value === null || undefined
          ? []
          : value.map((data, idx) => (
              <div>
                <h4
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "450",
                  }}
                >
                  {data.title}
                </h4>

                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    
                  }}
                >
                  {data.headline}
                  <span className=""><br></br>{data.employment_type}</span>
                </span>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  
                  }}
                >
                  {new Date(`${data.start_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? ""
                    : new Date(
                        `${data.start_date.slice(0, 10)}`
                      ).toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                      })}{" "}
                  -{" "}
                  {new Date(`${data.end_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? "Present"
                    : new Date(`${data.end_date.slice(0, 10)}`).toLocaleString(
                        "en-us",
                        { month: "short", year: "numeric" }
                      )}
                </div>

                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                   
                  }}
                >
                  {data.industry} Industry{" "}
                  <span className="mx-2">- {data.location} </span>
                </div>

                <p
                  style={{
                    fontSize: "14px",
                    justifyContent: "flex-start",
                    marginRight: "15px",
                    marginTop: "14px",
                  }}
                >
                  {data.description}
                </p>
              </div>
            ))}
      </div>
    </>
  );
}
