import { useMutation } from "react-query";
import { secureAxios } from "./auth";
import { queryClient } from "./query";

export const usePostDocument = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/document/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document"]);
            },
        }
    );
};

export const useUpdateDocument = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/document/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document"]);
            },
        }
    );
};

export const useUpdateChapter = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/profile/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "profile"]);
            },
        }
    );
};


export const useUpdateReference = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post("/items/references", data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "reference"]);
            },
        }
    );
}


export const useApproveReference = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/references/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "references"]);
            },
        }
    );
};

export const useDocumentVerification = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/document/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document"]);
            },
        }
    );
};

export const useDocumentVerificationById = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/document/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document"]);
            },
        }
    );
};