import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";
import ChangeType from "./ChangeType";
import { useDeleteFile, useUploadFile } from "../../../../_helper/files";
import { getImageURL } from "../../../../_helper/image";
import { useCreatePost } from "../../../../_helper/colabarated_wall/CreatePostAction";
import { useUpdatePost } from "../../../../_helper/colabarated_wall/UpdatePostAction";

export default function CreatePostModel({ isopen, setIsOpen, img ,data, isEditOpen,isPosted }) {
  const [publicbtn, setPublicbtn] = useState("Public");
  const [publicbtnstatus, setPublicbtnStatus] = useState(false);
  const [videobtnstatus, setVideoBtnStatus] = useState(false);
  const [avatar, setAvtar] = useState("");
  const [filedata,setFileData] = useState({})
  const [txtcontent, setTxtContent] = useState("");
  const [youtubelink, setYoutubeLink] = useState("");
  const [youtubelinkstatus, setYoutubeLinkStatus] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [type, setType] = useState("");
  const [getedittype,setGetEditType] = useState("");
  const [editid,setEditID] = useState("");
  
  

  

  const update_post = useUpdatePost();


  const getData = () => {
   
   
    

      if (isEditOpen){
        console.log(data)
        setEditID(data.id)
      
        setTxtContent(data?.content)
        setGetEditType(data.type)
        setFileData([{"directus_files_id":data.files[0].directus_files_id}])

       
        
        if (data.type === 'video'){
          setType('video')
          setAvtar(data?.youtube_url)
          setYoutubeLink(data?.youtube_url)
        }
        else{
          setGetEditType(data.type)
          setAvtar(data.files.length>0?(data.files[0]?.directus_files_id)?(data.files[0]?.directus_files_id):"":"")
        }
      }else{
       setType("")
      }
   
   
  }
console.log(type)


 

  const types_dict = {
    image: ".png,.jpg,.jpeg",
    video: ".mp4,.mov,.avi",
    document: ".pdf",
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data.id, "Success Data");
    console.log(data)
    // const {id, ...rest} = data
   
    // setFileData({key:data.id,type:type})
    setFileData([{"directus_files_id":data.id}])
    setAvtar(data.id);
  });

  const onFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];

    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (avatar !== "") {
          console.log(avatar);
          fileMutationdelete.mutate(avatar);
        }

        fileMutation.mutate(formData);
      }
      
    }
  };

  function matchYoutubeUrl(url) {
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }

  useEffect(() => {
    if (data !== null && isEditOpen === true){

      getData();
    }
    if (type !== "" && type !== "video") {
      handleClick();
    }
    
  }, [type,isopen]);

  console.log(youtubelinkstatus);

  const mutation = useCreatePost(() => {});

  const OnPost = () => {
    let data;


    
    if (isEditOpen){
      if (type === "video") {
        data = {
          content: txtcontent,
          type: getedittype,
          youtube_url: `https://www.youtube.com/embed/${avatar.slice(-11)}`,
          // user:Number(localStorage.getItem('user_id'))
        };
      } else {
       
        console.log(filedata)
        data = {
          content: txtcontent,
          type: getedittype,
          files: type === "video" ? "" : filedata
          // user:Number(localStorage.getItem('user_id'))
        };
      }
     
      update_post.mutate({id:editid,data:data})
    }else{
      if (type === "video") {
        data = {
          content: txtcontent,
          type: type,
          youtube_url: `https://www.youtube.com/embed/${avatar.slice(-11)}`,
          // user:Number(localStorage.getItem('user_id'))
        };
      } else {
       
        console.log(filedata)
        data = {
          content: txtcontent,
          type: type,
          files: type === "video" ? "" : filedata
          // user:Number(localStorage.getItem('user_id'))
        };
      }
  

      mutation.mutate(data);
    }
    setIsOpen(false)
   
  };

 

  return (
    <>
      <MDBModal staticBackdrop tabIndex="-1" show={isopen} setShow={setIsOpen}>
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Create a post</MDBModalTitle>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "black",
                  fontSize: "24px",
                }}
                onClick={() => {
                  if (avatar && type !== "video") {
                    if (!isEditOpen){

                      fileMutationdelete.mutate(avatar);
                      setAvtar("");
                    }
                  }
                  setAvtar("")
                  setType("")
                  setYoutubeLink("")
                  setVideoBtnStatus(false)
                  setTxtContent("");
                  setPublicbtn("Public");
                  setPublicbtnStatus(false);
                  setIsOpen(false);
                }}
              >
                <i class="fas fa-times"></i>
              </button>
            </MDBModalHeader>
            <MDBModalBody>
              {publicbtnstatus ? (
                <ChangeType />
              ) : (
                <MDBContainer className="m-0 p-0">
                  <MDBRow className="d-flex justify-content-center">
                    <MDBCol md="12" className="m-0 p-0">
                      <div className="p-2 w-100 d-flex flex-row">
                        <div
                          style={{
                            marginLeft: "1rem",
                          }}
                        >
                          <img
                            src={img}
                            className="rounded-circle"
                            height="50"
                            width="50"
                            alt=""
                          />
                          <button
                            className="show-btn"
                            style={{
                              border: "2px solid #e0e0e0",
                              borderRadius: "15px",
                              padding: "2px",
                              width: "8rem",
                              fontSize: "14px",
                              fontWeight: "450",
                              color: "#807c7c",
                              textAlign: "center",
                              marginLeft: "1rem",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => {
                              setPublicbtnStatus(true);
                            }}
                            disabled
                          >
                            <MDBIcon fas icon="globe-africa" className="mx-1" />{" "}
                            {publicbtn} <MDBIcon fas icon="caret-down" />
                          </button>
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {videobtnstatus ? (
                    <>
                      <MDBInput
                        type="url"
                        label="Add youtube video link"
                        wrapperClass="w-80 mx-3 mt-3 mb-2"
                        onChange={(eve) => {
                          setYoutubeLink(eve.target.value);
                          if (matchYoutubeUrl(eve.target.value)) {
                            setYoutubeLinkStatus(true);
                          } else {
                            setYoutubeLinkStatus(false);
                          }
                        }}
                      />
                      {youtubelinkstatus ? (
                        <p
                          style={{
                            color: "green",
                            fontSize: "12px",
                            marginLeft: "1rem",
                          }}
                        >
                          Valid Link
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "1rem",
                          }}
                        >
                          Link is invalid
                        </p>
                      )}
                    </>
                  ) : (
                    <textarea
                      onChange={(eve) => {
                      
                        setTxtContent(eve.target.value);
                       
                      }}
                      placeholder="What's on your mind?"
                      value={txtcontent}
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        height: "100px",
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "black",
                        padding: "10px",
                        resize: "none",
                        overflow: "none",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                        lineHeight: "1.5",
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                        boxSizing: "border-box",
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "0px",
                        marginRight: "0px",
                        paddingLeft: "15px",
                        paddingRight: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderTopWidth: "0px",
                      }}
                      rows="4"
                      cols="50"
                    />
                  )}
                  {avatar && type === "image" || getedittype === 'image' ? (
                    <img
                      src={getImageURL(avatar)}
                      className="img-fluid"
                      alt="Post Image"
                    />
                  ) : (
                    ""
                  )}
                  {avatar && youtubelink ? (
                    // console.log(avatar)
                    <iframe
                      src={`https://www.youtube.com/embed/${avatar.slice(-11)}`}
                      width={400}
                      height={200}
                      alt="Youtube video"
                    />
                  ) : (
                    ""
                  )}
                </MDBContainer>
              )}
            </MDBModalBody>

            <MDBModalFooter>
              {videobtnstatus ? (
                <>
                  <button
                    className="px-4"
                    style={{
                      backgroundColor: "#F58A2E",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "4px",
                      borderRadius: "10px",
                      border: 0,
                    }}
                    onClick={() => {
                      setVideoBtnStatus(false);
                    }}
                  >
                    back
                  </button>
                  <button
                    className="px-4"
                    style={{
                      backgroundColor: "#F58A2E",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "4px",
                      borderRadius: "10px",
                      border: 0,
                    }}
                    onClick={() => {
                      setType("video");
                      setAvtar(youtubelink);
                      setVideoBtnStatus(false);
                    }}
                    disabled={!youtubelinkstatus}
                  >
                    Add link
                  </button>
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <button
                    title="Add a image"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      float: "left",
                    }}
                    onClick={() => {
                      setType("image");
                      //handleClick();
                    }}
                  >
                    <MDBIcon
                      style={{
                        color: "#9c9898",
                        fontSize: "22px",
                        fontWeight: "500",
                        border: "none",
                        backgroundColor: "transparent",
                        paddingLeft: "10px",
                      }}
                      fas
                      icon="image"
                      className="mx-1"
                    />
                  </button>

                  <button
                    title="Add a video"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      float: "left",
                    }}
                    onClick={() => {
                      setVideoBtnStatus(true);
                      setYoutubeLink("");

                      setYoutubeLinkStatus(false);
                    }}
                  >
                    <MDBIcon
                      style={{
                        color: "#9c9898",
                        fontSize: "22px",
                        fontWeight: "500",
                        border: "none",
                        backgroundColor: "transparent",
                        paddingLeft: "20px",
                      }}
                      fab
                      icon="youtube"
                      className="mx-1"
                    />
                  </button>

                  {/* <button
                    title="Add a document"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      float: "left",
                    }}
                    onClick={() => {
                      setType("document");
                    }}
                  >
                    <MDBIcon
                      style={{
                        color: "#9c9898",
                        fontSize: "22px",
                        fontWeight: "500",
                        border: "none",
                        backgroundColor: "transparent",
                        paddingLeft: "20px",
                      }}
                      fas
                      icon="file-alt"
                    />
                  </button> */}

                  <input
                    type="file"
                    id="file"
                    ref={hiddenFileInput}
                    onChange={onFile}
                    accept={types_dict[type]}
                    style={{ display: "none" }}
                  />
                  <button
                    className="px-4"
                    style={{
                      backgroundColor: "#F58A2E",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "14px",
                      padding: "4px",
                      borderRadius: "10px",
                      border: 0,
                      float: "right",
                    }}
                    disabled={!(txtcontent.length !== 0?true:false||avatar.length===0?false:true)}
                    onClick={()=>{
                      OnPost();
                      setAvtar("")
                      setYoutubeLink("")
                      setType("")
                      setVideoBtnStatus(false)
                      setTxtContent("");
                      setPublicbtn("Public");
                      setPublicbtnStatus(false);
                      setIsOpen(false)
                    }}
                  >
                    {mutation.isLoading ? (
                      <MDBSpinner color="light" size="sm" />
                    ) : (
                      "Post"
                    )}
                  </button>
                </div>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
