import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBSwitch,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { secureAxios } from "../../../_helper/auth";
import {
  useCreateMembership,
  useEditMembership,
} from "../../../_helper/MembershipAction";

export default function CreateMembershipForm({ getmodal, id, onChange }) {
  const [data, setdata] = useState({});
  const [payable,setPayable] = useState('');
  const [alleditdata, setAllEditData] = useState({});

  const navigate = useNavigate();
  const status_plan = [
    { label: "inactive", value: "0" },
    { label: "active", value: "1" },
  ];
  const category_plan = [
    { label: "men", value: 0 },
    { label: "women", value: 1 },
    { label: "family", value: 2 },
    { label: "organisation", value: 3 },
  ];

  const mutation = useCreateMembership(() => {
    navigate("/profile/edit-membership");
  });

 

  const SubmitData = () => {
    console.log(data);

    mutation.mutate(data);
  };

  const TotalPayable = (curr_value_price,one_time,discount) => {
    if (curr_value_price === 0){
      //console.log(curr_value_price,one_time,discount)
      //console.log(data.price)
     
      setPayable(data.price===undefined?0:data.price+Number(one_time)*data.discount_for_new_registration === undefined?0:data.discount_for_new_registration/100)
    }else if (one_time === 0){
      //console.log(curr_value_price,one_time,discount)
      console.log(curr_value_price)
      setPayable(data.one_time_fees===undefined?0:data.one_time_fees+Number(curr_value_price)*data.discount_for_new_registration === undefined?0:data.discount_for_new_registration/100)
    }else{
      setPayable(data.price===undefined?0:data.price+data.one_time_fees===undefined?0:data.one_time_fees*discount/100)
    }
  }

  console.log(payable)



  const getData = () => {
    secureAxios
      .get(`/items/membership_plan/${id}`)
      .then((res) => {
        setAllEditData(res.data);
        setdata({
          ...data,
          name: res?.data?.name,
          price: res?.data?.price,
          one_time_fees: res?.data?.one_time_fees,
          discount_for_new_registration:
            res?.data?.discount_for_new_registration,
          duration_in_years: res?.data?.duration_in_years,
          event_discount: res?.data?.event_discount,
          status: res?.data?.status,
          category: res?.data?.category,
          theme: res?.data?.theme,
          total_payable:res?.data?.total_payable,
          voting_rights: res.data?.voting_rights,
          candidature_for_election: res?.data?.candidature_for_election,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      getData();
    } else {
    }
  }, [id]);

console.log(data);

  return (
    <>
      <div>
        <MDBContainer fluid className="px-2">
          <MDBRow className="d-flex justify-content-center align-items-center h-100">
            <MDBCol col="12">
              <MDBCard className="bg-white my-2 ">
                <MDBCardBody className="p-5 w-100 d-flex flex-column">
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Name"
                      id="formControlLg"
                      type="text"
                      size="md"
                      onChange={(eve) => {
                       
                        id &&
                          setdata({
                            ...data,
                            name: eve.target.value,
                          });
                        id &&
                          onChange({
                            ...data,
                            name: eve.target.value,
                          });

                        id ||
                          setdata({
                            ...data,
                            name: eve.target.value,
                          });
                          
                      }}
                      value={data.name}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Price"
                      id="formControlLg"
                      type="Number"
                      size="md"
                      onChange={(eve) => {
                
                        //  TotalPayable(Number(eve.target.value),0,0)
                        id &&
                          setdata({
                            ...data,
                            price: Number(eve.target.value),
                          });

                        id &&
                          onChange({
                            ...data,
                            price: Number(eve.target.value),
                          });
                        id ||
                          setdata({
                            ...data,
                            price: Number(eve.target.value),
                          });
                         
                      }}
                      value={data.price}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="One time Fees"
                      id="formControlLg"
                      type="Number"
                      size="md"
                      onChange={(eve) => {
                      
                        // TotalPayable(0,Number(eve.target.value),0)
                        id &&
                          setdata({
                            ...data,
                            one_time_fees: Number(eve.target.value),
                          });
                        id &&
                          onChange({
                            ...data,
                            one_time_fees: Number(eve.target.value),
                          });
                        id ||
                          setdata({
                            ...data,
                            one_time_fees: Number(eve.target.value),
                          });
                          
                      }}
                      value={data.one_time_fees}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Discount For New Registration"
                      id="formControlLg"
                      type="Number"
                      size="md"
                      onChange={(eve) => {
                        // TotalPayable(0,0,Number(eve.target.value))
                        id &&
                          onChange({
                            ...data,
                            discount_for_new_registration: Number(
                              eve.target.value
                            ),
                          });
                        id &&
                          setdata({
                            ...data,
                            discount_for_new_registration: Number(
                              eve.target.value
                            ),
                          });
                        id ||
                          setdata({
                            ...data,
                            discount_for_new_registration: Number(
                              eve.target.value
                            ),
                          });
                      }}
                      value={data.discount_for_new_registration}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Duration In Years"
                      id="formControlLg"
                      type="Number"
                      size="md"
                      onChange={(eve) => {
                        id &&
                          onChange({
                            ...data,
                            duration_in_years: Number(eve.target.value),
                          });
                        id &&
                          setdata({
                            ...data,
                            duration_in_years: Number(eve.target.value),
                          });
                        id ||
                          setdata({
                            ...data,
                            duration_in_years: Number(eve.target.value),
                          });
                      }}
                      value={data.duration_in_years}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Event Discount"
                      id="formControlLg"
                      type="Number"
                      size="md"
                      onChange={(eve) => {
                        id &&
                          onChange({
                            ...data,
                            event_discount: Number(eve.target.value),
                          });
                        id &&
                          setdata({
                            ...data,
                            event_discount: Number(eve.target.value),
                          });

                        id ||
                          setdata({
                            ...data,
                            event_discount: Number(eve.target.value),
                          });
                      }}
                      value={data.event_discount}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center w-100  ">
                    <Select
                      className="w-100 mb-4"
                      id="formControlLg"
                      placeholder="Status..."
                      size="md"
                      options={status_plan}
                      onChange={(eve) => {
                        id && onChange({ ...data, status: eve.label });
                        id && setdata({ ...data, status: eve.label });
                        id || setdata({ ...data, status: eve.label });
                      }}
                      value={data.status  === undefined?null:{ label: data.status } }
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center w-100 mb-4 ">
                    <Select
                      className="w-100 "
                      id="formControlLg"
                      placeholder="Category..."
                      size="md"
                      options={category_plan}
                      onChange={(eve) => {
                        id && onChange({ ...data, category: eve.label });
                        id && setdata({ ...data, category: eve.label });
                        id || setdata({ ...data, category: eve.label });
                      }}
                      value={data?.category  === undefined? null:{ label: data.category }}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Theme"
                      id="formControlLg"
                      type="text"
                      size="md"
                      onChange={(eve) => {
                        id && onChange({ ...data, theme: eve.target.value });
                        id && setdata({ ...data, theme: eve.target.value });
                        id || setdata({ ...data, theme: eve.target.value });
                      }}
                      value={data.theme}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <MDBInput
                      wrapperClass="mb-4 w-100"
                      label="Total Payable"
                      id="formControlLg"
                      type="number"
                      size="md"
                      readOnly
                     
                      value={payable}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4 ">
                    <MDBSwitch
                      id="flexSwitchCheckDefault"
                      label="Voting Rights "
                      onChange={(eve) => {
                        id &&
                          onChange({
                            ...data,
                            voting_rights: eve.target.checked,
                          });
                        id &&
                          setdata({
                            ...data,
                            voting_rights: eve.target.checked,
                          });
                        id ||
                          setdata({
                            ...data,
                            voting_rights: eve.target.checked,
                          });
                      }}
                      defaultChecked={data.voting_rights}
                    />
                  </div>
                  
                  {getmodal || (
                    <div className="d-flex flex-row align-items-center mb-4 ">
                      <button
                        className="mt-4 py-1"
                        style={{
                          width: "8rem",
                          color: "white",
                          backgroundColor: "#1b5ff2",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={SubmitData}
                      >
                        {mutation.isLoading ? (
                          <MDBSpinner color="light" size="sm" />
                        ) : (
                          "Save"
                        )}
                        {mutation.isSuccess
                          ? navigate(`/profile/edit-membership`)
                          : ""}
                      </button>
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </>
  );
}
