import React, { useEffect, useState } from 'react'
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow, MDBBtn, MDBIcon, MDBModal, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalHeader } from 'mdb-react-ui-kit';
import Select from 'react-select';


export default function AddressModel({ ashow, asetShow, sentData, reciveData, onChange1 }) {
    const [street1, setStreet1] = useState("");
    const [street2, setStreet2] = useState("");
    const [state, setState] = useState('');
    const [modelcity, setModelcity] = useState("");
    const [pincode, setpincode] = useState("");
    const [country, setcountry] = useState("");
    

    



    const Countries = [
        { label: "India", value: 1 },
        { label: "Nepal", value: 2 },

    ];



    const handleStreet1 = (eve) => {
        setStreet1(eve.target.value);
        onChange1({
            "street_no_1": eve.target.value,
            "street_no_2": reciveData.street_no_2,
            "city": reciveData.city,
            "pincode": reciveData.pincode,
            "country": reciveData.country,
           "state": reciveData.state
        })

    }
    const handleStreet2 = (eve) => {
        setStreet2(eve.target.value);
        onChange1({
            "street_no_1": reciveData.street_no_1,
            "street_no_2": eve.target.value,
            "city": reciveData.city,
            "pincode": reciveData.pincode,
            "country": reciveData.country,
           "state": reciveData.state
        })

    }
    const handlepincode = (eve) => {
        setpincode(eve.target.value);
        onChange1({
            "street_no_1": reciveData.street_no_1,
            "street_no_2": reciveData.street_no_2,
            "city": reciveData.city,
            "pincode": eve.target.value,
            "country": reciveData.country,
           "state": reciveData.state
        })

    }
    const handleState = (eve) => {
        setState(eve.target.value);
        onChange1({
            "street_no_1": reciveData.street_no_1,
            "street_no_2": reciveData.street_no_2,
            "city": reciveData.city,
            "pincode": reciveData.pincode,
            "country": reciveData.country,
           "state": eve.target.value
        })

    }
    const handleModelcity = (eve) => {
        setModelcity(eve.target.value);
        onChange1({
            "street_no_1": reciveData.street_no_1,
            "street_no_2": reciveData.street_no_2,
            "city": eve.target.value,
            "pincode": reciveData.pincode,
            "country": reciveData.country,
           "state": reciveData.state
        })

    }
    const handlecountry = (eve) => {
        setcountry(eve.label)
        onChange1({
            "street_no_1": reciveData.street_no_1,
            "street_no_2": reciveData.street_no_2,
            "city": reciveData.city,
            "pincode": reciveData.pincode,
            "country": eve.label,
           "state": reciveData.state
        })

        //console.log(eve.label)
    }




    const data = {
        "street_no_1": street1,
        "street_no_2": street2,
        "city": modelcity,
        "pincode": pincode,
        "country": country,
       "state": state

    }







    const sent = () => {
        console.log(data)

        sentData(data)

        setStreet1('');
        setStreet2('');
        setpincode('');
        setState('');
        setModelcity('');
        setcountry('');
        asetShow(!ashow)
    }





    return (
        <>
            <MDBModal show={ashow} setShow={asetShow} tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add New Address</MDBModalTitle>
                            <button
                                className='btn-close'
                                color='none'
                                onClick={() => asetShow(!ashow)
                                }
                                X
                            ></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Street No 1.'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleStreet1}
                                    value={`${reciveData.street_no_1}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Street No 2.'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleStreet2}
                                    value={`${reciveData.street_no_2}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='City'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleModelcity}
                                    value={`${reciveData.city}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='State'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleState}
                                    value={`${reciveData.state}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center">

                                <Select
                                    options={Countries}
                                    placeholder="Select country"
                                    className='w-100 mb-4'
                                    onChange={handlecountry}
                                    value={reciveData.country?{label:`${reciveData.country}`}:""}


                                />


                            </div>
                            <div className="d-flex flex-row alignitems-center ">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='pincode'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    autoComplete='off'
                                    value={`${reciveData.pincode}`}
                                    onChange={handlepincode}

                                />
                            </div>




                        </MDBModalBody>
                        <MDBModalFooter>


                            < button className='mt-4 py-1'
                                style={{ width: "8rem", color: "white", backgroundColor: "#1b5ff2", borderRadius: "5px", border: 'none' }}
                                onClick={sent}
                            >

                                <MDBIcon fas icon='save' className='px-1' />
                                Save
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>



        </>
    )
}
