import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardLink,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardSubTitle,
  MDBIcon,
} from "mdb-react-ui-kit";
import useScrollPosition from "./ViewPost/useScrollPosition";
import { getImageURL } from "../../../_helper/image";

export default function MyProfileCard({
  mname,
  expire_date,
  img,
  email,
  current_city,
  LCCIADesignation,
  nativeVillage,
  nativeDistrict,
  lcciaRole,
  tag,
  chapter,
}) {
  var q = new Date();
  var m = q.getMonth() + 1;
  var d = q.getDay();
  var y = q.getFullYear();

  var date = new Date();
  let mydate = new Date(
    `${expire_date === null ? "" : `${expire_date}`.slice(0, 10)}`
  );

  return (
    <MDBCard
      style={{ padding: "5px", border: "1px solid #e0e0e0", boxShadow: "none" }}
    >
      <div className="text-center mb-1">
        <MDBCardImage
          position="top"
          alt="..."
          src={img}
          style={{ width: "13rem", height: "12rem", marginTop: "1rem" }}
        />
      </div>
      <MDBCardBody className="m-0 p-4">
        <MDBCardTitle>
          {" "}
          {mname} <br></br>
          <MDBCardSubTitle>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "350",
                color: "#808080",
                marginTop: "10px",
              }}
            >{`${nativeVillage} ${nativeDistrict}`}</p>
          </MDBCardSubTitle>
        </MDBCardTitle>

        <MDBCardText>
          {current_city ? (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  fas
                  icon="location"
                  style={{ color: "#F58A2E" }}
                  size="sm"
                />
              </div>
              <div className="col"> {`${current_city}`}</div>
            </div>
          ) : (
            ""
          )}

          <div className="row ">
            <div className="col-1">
              <MDBIcon
                fas
                icon="envelope"
                size="sm"
                style={{ color: "#F58A2E" }}
              />
            </div>

            <div className="col"> {`${email}`}</div>
          </div>

          <div className="row py-1">
            <div className="col-1">
              <MDBIcon fas icon="user" size="sm" style={{ color: "#F58A2E" }} />
            </div>{" "}
            <div className="col">
              <span className="">{`${lcciaRole ? lcciaRole : "Member"}`}</span>
            </div>
          </div>

          {tag === "" || tag === null || tag === undefined ? (
            ""
          ) : (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  fas
                  icon="user-tag"
                  size="sm"
                  style={{ color: "#F58A2E" }}
                />
              </div>{" "}
              <div className="col">
                <span className="">{tag}</span>
              </div>
            </div>
          )}

          {chapter === null || chapter === undefined ? (
            ""
          ) : (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  size="sm"
                  fas
                  icon="book"
                  style={{ color: "#F58A2E" }}
                />
              </div>
              <div className="col">
                <span>{`${chapter?.name}`}</span>
              </div>
            </div>
          )}
        </MDBCardText>

        <h5
          style={{
            fontSize: "18px",
          }}
        >
          <span
            class="badge"
            style={{
              backgroundColor: "#F58A2E",
            }}
          >
            {`${
              LCCIADesignation === null || undefined ? "" : LCCIADesignation
            }`}
          </span>
          <span
            className="badge mx-2"
            style={{
              backgroundColor: `${date <= mydate ? `#35bd52` : "#f52222"}`,
            }}
          >
            {date <= mydate ? `Active` : "Inactive"}
          </span>
        </h5>
      </MDBCardBody>
    </MDBCard>
  );
}
