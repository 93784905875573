import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import OrganisationHomePage from "./OrganisationHomePage";
import OrganisationContactus from "./OrganisationContactus";

export default function OrganisationDetailPage({ buisnessData }) {
  const { id } = useParams();

  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  console.log(buisnessData);
  return (
    <>
      <MDBContainer fluid>
        <MDBRow>
          <MDBCard
            style={{
              width: "100%",
              margin: "10px",
              padding: "15px",
            }}
          >
            <MDBCardBody>
              <MDBTabs justify className="mb-6">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab1")}
                    active={basicActive === "tab1"}
                  >
                    Home
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab2")}
                    active={basicActive === "tab2"}
                  >
                    About US
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleBasicClick("tab3")}
                    active={basicActive === "tab3"}
                  >
                    Contact us
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>

              <MDBTabsContent>
                <MDBTabsPane show={basicActive === "tab1"}>
                  <OrganisationHomePage biz_data={buisnessData[0]} />
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === "tab2"}>
                  <p style={{ fontSize: "14px", fontWeight: "400" }}>{`${
                    buisnessData[0]?.business_description
                      ? buisnessData[0].business_description
                      : ""
                  }`}</p>
                </MDBTabsPane>
                <MDBTabsPane show={basicActive === "tab3"}>
                  <OrganisationContactus AllBusinessData={buisnessData[0]} />
                </MDBTabsPane>
              </MDBTabsContent>
            </MDBCardBody>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
