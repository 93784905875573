import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { secureAxios } from "../../../_helper/auth";
import { useUpdateDocumentType } from "../../../_helper/MembershipSetupAction";
import AddTypeModel from "./AddTypeModel";


export default function MaintainDocumentType() {
  const [doc_type, setdoc_type] = useState([]);
  const [openModal,setOpenModal] = useState(false);
  const [openstatusmodal,setOpenStatusModal] = useState(false)
  const [value,setValue] = useState({});
  const [id,setid] = useState("");
  const [status,setStatus] = useState("");
  const [getrun,setGetRun] = useState(false);

  const getData = () => {
    secureAxios
      .get("/items/document_type")
      .then((res) => {
        console.log(res.data);
        setdoc_type(res.data);
        setGetRun(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChange = (data) => {
    setValue(data)
    console.log(data)
  }

  
  const mutation = useUpdateDocumentType(id);

  const statusUpdate = (data) => {
      setGetRun(data)
  }

  const UpdateStatus = () =>{
    
    mutation.mutate({
      status:status === "true"?"false":"true"
    })
    setGetRun(true)
    setOpenStatusModal(false)
  }

  useEffect(() => {
    getData();
  }, [getrun,openstatusmodal,openModal]);
  return (
    <>
     <MDBModal show={openstatusmodal} setShow={setOpenStatusModal} tabIndex="-1">
      <MDBModalDialog>
        <MDBModalContent>
          <MDBModalHeader>
            {/* <MDBModalTitle></MDBModalTitle> */}
            <button
              className="btn-close"
              color="none"
              onClick={() => {

                setOpenStatusModal(!openstatusmodal)}
              }
                X
            ></button>
          </MDBModalHeader>
          <MDBModalBody>
            Are you want to update it ?
          </MDBModalBody>
          <MDBModalFooter>
          <button
              className="mt-4 py-1"
              style={{
                width: "8rem",
                color: "white",
                backgroundColor: "#1b5ff2",
                borderRadius: "5px",
                border: "none",
              }}
              onClick={()=>{
                setOpenStatusModal(!openstatusmodal)
              }}
              // onClick={SubmitEditedData}
            >
              {/* {editmutation.isLoading ? (
              <MDBSpinner color="light" size="sm" />
            ) : (
              <>
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </>
            )} */}
              No
            </button>
            <button
              className="mt-4 py-1"
              style={{
                width: "8rem",
                color: "white",
                backgroundColor: "#1b5ff2",
                borderRadius: "5px",
                border: "none",
              }}
              onClick={UpdateStatus}
              // onClick={SubmitEditedData}
            >
              {/* {editmutation.isLoading ? (
              <MDBSpinner color="light" size="sm" />
            ) : (
              <>
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </>
            )} */}
              Yes
            </button>

          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>

      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Settings</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem >
          <a style={{ cursor: "default" }}>Setup</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem >
          <a style={{ cursor: "default" }}>Membership Setup</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
          <a style={{ cursor: "default" }}>Maintain Document Type</a>
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>

     
      <AddTypeModel openaddmodal={openModal} setopenAddmodal={setOpenModal} onChange={onChange} value={value} status={statusUpdate}/>
      <MDBContainer fluid className="px-2 ">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-4 w-100 d-flex flex-column">
                <h5>Maintain Documents Type</h5>
                <div>
                  <button
                    className="row-lg p-2 my-3"
                    style={{
                      border: "1px solid ",
                      color: `white`,
                      backgroundColor: `#413bdb`,
                      borderRadius: "5px",
                      float: "right",
                     
                    }}
                    onClick={()=>{
                      setValue({})
                      setOpenModal(!openModal)

                    }}
                  >
                    <MDBIcon fas icon="plus" className="px-0" /> Add Type
                  </button>
                </div>

                <MDBTable hover align="middle" responsive>
                  <MDBTableHead light>
                    <tr className="text-center">
                      <th scope="col">Type</th>
                      <th scope="col">Description</th>
                      <th scope="col">Category</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {doc_type.map((value, idx) => (
                      <>
                        <tr className="text-center">
                          <td>{value.type}</td>
                          <td> {value.description}</td>
                          <td> {value.category}</td>
                          <td> {value.status==="true"?"Active":"Inactive"}</td>

                          <td className="">
                            {" "}
                            <div className="">
                              <button
                              className="mx-3"
                              style={{
                                border: "1px solid",
                                color: `white`,
                                width: "100px",
                                backgroundColor: `${value.status === 'true'?'red':'green'}`,
                                borderRadius: "5px",
                              }}

                              onClick = {()=>{
                                    setid(value.id);
                                    setStatus(value.status)
                                    setOpenStatusModal(true)
                                   
                              }}
                            >
                              
                              {value.status === 'true'?'Inactive':"Active"}
                            </button>
                           
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      
    </>
  );
}
