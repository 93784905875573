import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { secureAxios } from "../../../../_helper/auth";
import { getImageURL } from "../../../../_helper/image";
import UserDocumentCard from "./UserDocumentCard";

export default function DocumentVerification() {
  const [profiledata, setprofileData] = useState([]);
  const [UpdateStatus, setUpdateStatus] = useState(false);

  const GetDocumentVerification = async () => {
    await secureAxios
      .get(`/users?fields=*,profile.*.*&limit=-1`)
      .then((res) => {
        // console.log(res.data);
        setprofileData(res.data);
        setUpdateStatus(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    //GetData();
    GetDocumentVerification();
  }, [UpdateStatus]);

  const UpdateStaus = (data) => {
    setUpdateStatus(data);
    console.log(data);
  };
  return (
    <>
      {" "}
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}>Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }}>
          Settings
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }} active>
          Document Verification
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <h4>Document Verification</h4>
      {profiledata.map((item, idx) => (
        <UserDocumentCard
          status={UpdateStaus}
          img={`${!item?.avatar ? " " : getImageURL(item.avatar)}`}
          mname={`${
            !item?.profile[0]?.first_name ? "" : item?.profile[0]?.first_name
          } ${
            !item?.profile[0]?.middle_name ? "" : item?.profile[0]?.middle_name
          } ${!item?.profile[0]?.last_name ? "" : item?.profile[0]?.last_name}`}
          current_city={`${
            !item?.profile[0]?.current_city
              ? ""
              : item?.profile[0]?.current_city
          }`}
          nativeDistrict={`${
            !item?.profile[0]?.native_district
              ? ""
              : item?.profile[0]?.native_district
          }`}
          nativeVillage={`${
            !item?.profile[0]?.native_village
              ? ""
              : item?.profile[0]?.native_village
          }`}
          email={`${!item?.email ? "" : item?.email}`}
          lcciaRole={`${
            !item?.profile[0]?.LCCIA_Designation
              ? "Member"
              : item?.profile[0]?.LCCIA_Designation
          }`}
          docData={item?.profile[0]?.verification_docs}
          id={item?.id}
        />
      ))}
    </>
  );
}
