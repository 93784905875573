import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import axios from "axios";
import useCreateEnquireAction from "../../../../../_helper/colabarated_wall/CreateEnquireAction";
import { secureAxios } from "../../../../../_helper/auth";

export default function CreateEnquire({
  enquiremodal,
  setEnquireModal,
  post_id,
}) {
  const [data, setData] = useState({});

  console.log(post_id);

  const getData = async () => {
    await secureAxios
      .get(
        "/users/me?fields=email,first_name,last_name,middle_name,profile.mobile"
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data);
  const mutation = useCreateEnquireAction();

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload;
    payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      middle_name: data.middle_name,
      post: post_id,
      mobile_no: data.profile[0].mobile,
      email: data.email,
      query_content: data.query_content,
    };

    mutation.mutate(payload);
    setEnquireModal(false);
  };

  return (
    <>
      <MDBModal show={enquiremodal} setShow={setEnquireModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Send Your Enquiry</MDBModalTitle>
              <button
                onClick={() => {
                  setEnquireModal(false);
                }}
                className="btn-close"
                color="none"
              ></button>
            </MDBModalHeader>
            <form onSubmit={handleSubmit}>
              <MDBModalBody>
                <div className="container p-0">
                  <div className="row ">
                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4 w-100 "
                        label="First Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={
                          data.first_name !== undefined ? data.first_name : ""
                        }
                        onChange={(e) => {
                          setData({ ...data, first_name: e.target.value });
                        }}
                      />
                    </div>
                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4 w-100 "
                        label="Middle Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={
                          data.middle_name !== undefined ? data.middle_name : ""
                        }
                        onChange={(e) => {
                          setData({ ...data, middle_name: e.target.value });
                        }}
                      />
                    </div>

                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4  w-100"
                        label="Last Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={
                          data.last_name !== undefined ? data.last_name : ""
                        }
                        onChange={(e) => {
                          setData({ ...data, last_name: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Email"
                    id="formControlLg"
                    type="text"
                    size="md"
                    readOnly
                    value={data.email !== undefined ? data.email : ""}
                    onChange={(e) => {
                      setData({ ...data, email: e.target.value });
                    }}
                    required
                  />
                </div>

                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Mobile Number"
                    id="formControlLg"
                    type="text"
                    size="md"
                    value={
                      data?.profile && data.profile[0]?.mobile !== null
                        ? data.profile[0]?.mobile
                        : ""
                    }
                    onChange={(e) => {
                      setData({
                        ...data,
                        profile: [{ mobile_number: e.target.value }],
                      });
                    }}
                    required
                  />
                </div>
                <div className="d-flex flex-row align-items-center ">
                  <MDBTextArea
                    wrapperClass="mb-4 w-100"
                    label="Write more about enquire"
                    id="formControlLg"
                    type="text"
                    size="md"
                    rows={4}
                    value={
                      data.query_content !== undefined ? data.query_content : ""
                    }
                    onChange={(e) => {
                      setData({ ...data, query_content: e.target.value });
                    }}
                    required
                  />
                </div>
              </MDBModalBody>

              <MDBModalFooter>
                <button
                  type="submit"
                  className="px-3 py-1"
                  style={{
                    float: "right",
                    backgroundColor: "#407ee3",
                    color: "white",
                    borderRadius: "5px",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  Send Enquiry
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
