import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useRegister } from "../../_helper/actions";
import { publicAxios } from "../../_helper/auth";
import Select from "react-select";

export default function IndividualRegistration() {
  const [basicModal, setBasicModal] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [nativevillage, setNativeVillage] = useState("");
  const [nativedistrict, setNativeDistrict] = useState("");
  const [currentCity, setcurrentCity] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [status, setStatus] = useState(true);
  const [islogedin, setIslogedin] = useState(true);

  const navigate = useNavigate();

  const handleEmail = (eve) => {
    setEmail(eve.target.value);
  };

  const handleMobile = (eve) => {
    setMobile(eve.target.value);
  };

  const handlePass = (eve) => {
    //console.log(gender)
    setPassword(eve.target.value);
    if (cpassword === eve.target.value) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const handleFirstName = (eve) => {
    setFirstName(eve.target.value);
  };

  const handleMiddleName = (eve) => {
    setMiddleName(eve.target.value);
  };

  const handleLastName = (eve) => {
    setLastName(eve.target.value);
  };

  const handleNativeVillage = (eve) => {
    setNativeVillage(eve.target.value);
  };

  const handleNativeDistrict = (eve) => {
    setNativeDistrict(eve.target.value);
  };

  const handleGender = (eve) => {
    setGender(eve.label);
  };

  const handleCurrentCity = (eve) => {
    setcurrentCity(eve.target.value);
  };

  const handlecPassword = (eve) => {
    setcPassword(eve.target.value);
    if (eve.target.value === password) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  };

  const check_login = () => {
    if (localStorage.getItem("access_token")) {
      console.log("logged in");
      setIslogedin(false);
    }
  };

  useEffect(() => {
    check_login();
  }, []);

  const gender_option = [
    {
      label: "Male",
      value: "1",
    },
    {
      label: "Female",
      value: "2",
    },
  ];

  const mutation = useRegister(() => setBasicModal(true));

  function handleSubmit(eve) {
    eve.preventDefault();
    const data = {
      first_name: firstname,
      last_name: lastname,
      middle_name: middlename,
      email: email,
      password: password,
      role: "f2a489e0-e749-4067-ac85-537f3deb9a36",
      status: "draft",
      profile: [
        {
          first_name: firstname,
          last_name: lastname,
          middle_name: middlename,
          email: email,
          mobile: mobile,
          gender: gender,
          native_village: nativevillage,
          current_city: currentCity,
          native_district: nativedistrict,
        },
      ],

      business_profiles: [
        {
          business_name: "",
        },
      ],
    };

    mutation.mutate(data);
  }

  if (!islogedin) {
    return <Navigate to="/profile/all-member"></Navigate>;
  }

  const toggleShow = () => setBasicModal(!basicModal);
  return (
    <>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Registaration Successful</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Your registration on LCCIA portal is successful. we'll review and
              approve profile within 24 hours. you can login once approved.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  // toggleShow()
                  setBasicModal(false);
                  navigate("/login");
                }}
              >
                okay
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <form
        onSubmit={handleSubmit}
        onError={(err) => {
          console.log(err);
        }}
      >
        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100 "
              label="First Name*"
              id="formControlLg"
              type="text"
              size="md"
              value={`${firstname}`}
              onChange={handleFirstName}
              autoComplete="off"
              autoFocus
              required
            />
          </div>
        </div>
        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100 "
              label="Middle Name"
              id="formControlLg"
              type="text"
              size="md"
              value={`${middlename}`}
              onChange={handleMiddleName}
            />
          </div>
        </div>
        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2  w-100"
              label="Last Name*"
              id="formControlLg"
              type="text"
              size="md"
              value={`${lastname}`}
              onChange={handleLastName}
              required
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Native Village"
              id="formControlLg"
              type="text"
              size="md"
              onChange={handleNativeVillage}
            />
          </div>
        </div>
        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Native District*"
              required
              id="formControlLg"
              type="text"
              size="md"
              onChange={handleNativeDistrict}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Current City"
              id="formControlLg"
              type="text"
              size="md"
              onChange={handleCurrentCity}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <>
              <Select
                isSearchable={false}
                placeholder="Gender*"
                wrapperClass="mb-2 w-100"
                label="Gender"
                //isRtl
                //label="Gender*"
                id="formControlLg"
                size="md"
                options={gender_option}
                onChange={handleGender}
              />
              {/* <input required value={gender} style={{display:"none"}}/> */}
            </>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Email address*"
              id="formControlLg"
              type="email"
              required
              size="md"
              onChange={handleEmail}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Mobile Number*"
              id="formControlLg"
              type="text"
              required
              size="md"
              onChange={handleMobile}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-lock fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Password*"
              id="formControlLg"
              type="password"
              required
              size="md"
              autoComplete="new-password"
              onChange={handlePass}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-key fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Confirm Password*"
              id="cformControlLg"
              type="password"
              required
              size="md"
              autoComplete="new-password"
              onChange={handlecPassword}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          <button
            type="submit"
            className="btn  btn-lg w-100"
            //typeof='submit'
            disabled={status}
            //onClick={handleSubmit}
            style={{
              backgroundColor: "#F58A2E",
              border: "0px",
              borderRadius: "5px",
              color: "white",
              fontWeight: "500",
            }}
          >
            {!mutation.isLoading ? (
              "Register"
            ) : (
              <MDBSpinner color="light" size="sm" />
            )}
          </button>
        </div>
        {mutation.isError ? (
          <div class="alert alert-danger text-center" role="alert">
            <i class="fa fa-info-circle"></i> Registaration Failed! Try again
          </div>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
