import React from "react";
import { useParams } from "react-router-dom";
import AllMember from "./allMember";
import ManageBusinessProfiles from "./BusinessProfileComponents/ManageBusinessProfiles";
import DetailPage from "./DetailPage";
import EditBusinessProfile from "./EditBusinessProfile";
import EditProfile from "./EditProfile";
import CreateMembershipForm from "./membership_plan/CreateMembershipForm";
import EditMembership from "./membership_plan/EditMembership";
import MembershipForm from "./membership_plan/MembershipForm";
import UpgradeMembership from "./membership_plan/UpgradeMembership";
import MyProfileView from "./MyProfileView";
import Navbar from "./navbar";
import ProfileInfo from "./ProfileInfo";
import DocumentVerification from "./settings/AdminDocumentVerification/DocumentVerification";
import ApprovedMembership from "./settings/ApprovedMembership";
import OrganisationApproval from "./settings/OrganisationApproval";
import Settings from "./settings/Settings";
import UserTag from "./settings/UserTag";
import "./style/sidebar.css";
import UserInformation from "./userInformation";
import "../../App.css";
import SideBar from "./SideBarComponents/SideBar";

export default function Home() {
  const { name } = useParams();

  //console.log(name);

  if (name === "edit-profile") {
    return (
      <>
        <Navbar />
       

      <main>
          <EditProfile />
          </main>
      </>
    );
  } else if (name === "all-member") {
    return (
      <>
        <Navbar />
      

        <main>
          <AllMember />
        </main>
      </>
    );
  } else if (name === "setting") {
    return (
      <>
        <Navbar />
       
        <main>
          <Settings />
        </main>
      </>
    );
  } else if (name === "view") {
    return (
      <>
        <Navbar />
      
        <main>
          <MyProfileView />
        </main>
      </>
    );
  } else if (name === "edit-business-profile") {
    return (
      <>
        <Navbar />
      
        <main>
          <EditBusinessProfile />
        </main>
      </>
    );
  } else if (name === "manage-business-profile") {
    return (
      <>
        <Navbar />
       
        <main>
          <ManageBusinessProfiles />
        </main>
      </>
    );
  } else if (name === "upgrade-membership") {
    return (
      <>
        <Navbar />
        <main>
          <UpgradeMembership />
        </main>
      </>
    );
  } else if (name === "edit-membership") {
    return (
      <>
        <Navbar />
        <main>
          <EditMembership />
        </main>
      </>
    );
  } else if (name === "approved") {
    return (
      <>
        <Navbar />
        <main>
          <ApprovedMembership />
        </main>
      </>
    );
  } else if (name === "organisation-approved") {
    return (
      <>
        <Navbar />
        <main>
          <OrganisationApproval />
        </main>
      </>
    );
  } else if (name === "create-membership") {
    return (
      <>
        <Navbar />
        <main>
          <MembershipForm />
        </main>
      </>
    );
  } else if (name === "user-tag") {
    return (
      <>
        <Navbar />
        <main>
          <UserTag />
        </main>
      </>
    );
  } else if (name === "document-verification") {
    return (
      <>
        <Navbar />
        <main>
          <DocumentVerification />
        </main>
      </>
    );
  } else {
    return (
      <>
      
        <Navbar />

        <main>
          <DetailPage />
        </main>
      </>
    );
  }
}
