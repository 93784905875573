import { MDBBreadcrumb, MDBBreadcrumbItem, MDBIcon } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import EditMembership from "./EditMembership";
import ProfileInfo from "./ProfileInfo";
import UserInformation from "./userInformation";

export default function EditProfile() {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex">
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a
              style={{ cursor: "pointer" }}
              
            >
              Home
            </a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a
              style={{ cursor: "pointer" }}
             
            >
              My Profile
            </a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem style={{ cursor: "pointer" }} active>
            Edit My Profile
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </div>
      <UserInformation />
      <ProfileInfo />
      {/* <EditMembership/> */}
    </>
  );
}
