import React, { useEffect, useState } from "react";
import { MDBInputGroup, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import { create, insert, search } from "@lyrasearch/lyra";

export default function Search({ inputdata ,outputdata }) {
  const [searchby, setSearchBy] = useState({});
  const [searchbylst, setSearchBylst] = useState([]);
  const [searchinput, setSearchInput] = useState("");

 
  
  const membersProfileDB = create({
    schema: {profile:[{

      first_name: "string",
      middle_name: "string",
      last_name: "string",
      current_city: "string",
      native_village: "string",
      native_district: "string",
      tag: "string",
    }],
    business_profiles:[{
      business_name:"string"
    }]
    }
  });
  
  const arr = inputdata.map((res, idx) => insert(membersProfileDB, res));
  

  
 


  const searchResult = () => {
    const res = search(membersProfileDB, {
      term: `${searchinput}`,
      properties: searchbylst.length === 0 ? "*":searchbylst,
    });

    if (res.hits.length === 0){
        return inputdata
    }else{

      const nonZeroRes = res.hits.map((resp)=>(resp.document))

      return nonZeroRes
    }
  }
  
  
  const handleSearch = (eve) => {
    
    setSearchInput(eve.target.value);
    // console.log(eve.target.value);
  };

  useEffect(()=>{

    outputdata(searchResult());
  },[searchinput,inputdata])
  
  



  function arrayRemove(arr, value) {
    return arr.filter(function (geeks) {
      return geeks != value;
    });
  }



  return (
    <>
      <MDBInputGroup className="mt-2">
        <MDBInput size="md" label="Search" onChange={handleSearch} />
        <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle py-3 px-3"
              type="button"
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              <MDBIcon size="lg" icon="filter" />
            </button>
            <ul
              className="dropdown-menu py-2"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <a className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Checkme1"
                      onChange={(eve) => {
                        eve.target.checked
                          ? setSearchBylst([...searchbylst, "profile.0.first_name"])
                          : setSearchBylst(arrayRemove(searchbylst, "profile.0.first_name"));

                        eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            name: eve.target.checked,
                          });
                        !eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            name: eve.target.checked,
                          });
                      }}
                      checked={searchby.name ? true : false}
                    />
                    <label className="form-check-label" for="Checkme1">
                      Name
                    </label>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Checkme2"
                      onChange={(eve) => {
                        eve.target.checked
                          ? setSearchBylst([...searchbylst, "profile.0.native_village"])
                          : setSearchBylst(
                              arrayRemove(searchbylst, "profile.0.native_village")
                            );

                        eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            native_place: eve.target.checked,
                          });
                        !eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            native_place: eve.target.checked,
                          });
                      }}
                      checked={searchby.native_place ? true : false}
                    />
                    <label className="form-check-label" for="Checkme2">
                      Native Place
                    </label>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Checkme3"
                      onChange={(eve) => {
                        eve.target.checked
                          ? setSearchBylst([...searchbylst, "profile.0.current_city"])
                          : setSearchBylst(
                              arrayRemove(searchbylst, "profile.0,current_city")
                            );

                        eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            current_city: eve.target.checked,
                          });
                        !eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            current_city: eve.target.checked,
                          });
                      }}
                      checked={searchby.current_city ? true : false}
                    />
                    <label className="form-check-label" for="Checkme3">
                      Current City
                    </label>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="Checkme3"
                      onChange={(eve) => {
                        eve.target.checked
                          ? setSearchBylst([...searchbylst, "profile.0.category"])
                          : setSearchBylst(
                              arrayRemove(searchbylst, "profile.0.category")
                            );

                        eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            category: eve.target.checked,
                          });
                        !eve.target.checked &&
                          setSearchBy({
                            ...searchby,
                            category: eve.target.checked,
                          });
                      }}
                      checked={searchby.category ? true : false}
                    />
                    <label className="form-check-label" for="Checkme3">
                      Buisness Category
                    </label>
                  </div>
                </a>
              </li>
            </ul>
          </div>
       
      </MDBInputGroup>
    </>
  );
}
