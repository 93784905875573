import React from "react";

export default function OrganisationContactus({AllBusinessData,userInfo}) {

    const location =
    AllBusinessData?.location === undefined ||
    AllBusinessData?.location === null ||
    AllBusinessData?.location?.length === 0
      ? {}
      : AllBusinessData?.location[0];
  const allAddress =
    AllBusinessData?.address === undefined || AllBusinessData?.address === null
      ? []
      : AllBusinessData?.address;
  return (
    <>

      <table>
        <tr>
          <td> </td>
        </tr>
        <tr>
          <td className={"pb-2 px-4"}>
            <p>
              <span
                style={{
                  fontWeight: 450,
                }}
              >
                Head Office:{" "}
              </span>

              {location === {} ? (
                ""
              ) : (
                <span>
                  {location.street_no_1}
                  <br></br>{" "}
                  {location?.street_no_2 ? `${location?.street_no_2},` : ""}
                  <br></br>
                  {location.city ? `${location.city},` : ""}
                  {location.pincode}
                  <br></br>
                  {location.state} {location.country}
                </span>
              )}
            </p>
          </td>
        </tr>

        <tr>
          <td className={"pb-3 px-4"}>
            <p>
              <span style={{ fontWeight: 450 }}>Address:</span>
              {allAddress.length === 0
                ? []
                : allAddress.map((value, idx) => (
                    <span>
                      {` ${value.street_no_1}`}
                      <br></br>{" "}
                      {value.street_no_2 === undefined
                        ? ""
                        : `${value.street_no_2},`}
                      <br></br>
                      {value.city},{value.pincode}
                      <br></br>
                      {value.state} {value.country}
                    </span>
                  ))}
            </p>
          </td>
        </tr>
      </table>
    </>
  );
}
