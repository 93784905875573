import React from 'react'
import { WhatsappShareButton, EmailShareButton,TelegramShareButton} from "react-share";
import { BASE_URL2 } from '../../../../constant';


export default function SharePost({post_id}) {
  return (
    <>
    <div className='text-center'>
        <WhatsappShareButton style={{
            color: "#25D366",
            fontSize: "3rem",
            marginRight: "15px",
            cursor: "pointer",
            justifyContent: "center",
            justifyItems:"stretch",
            textAlign:"justify"
            

        
        }} title='LCCIA Member Portal' url={`${BASE_URL2}shared/post/${post_id}`}>
            <i className="fab fa-whatsapp"></i>
        </WhatsappShareButton>
        <EmailShareButton style={{
            color: "#D44638",
            fontSize: "3rem",
            marginRight: "15px",
            cursor: "pointer",

        }}  title='LCCIA Member Portal' url={`${BASE_URL2}shared/post/${post_id}`}>
            <i className="fas fa-envelope"></i>
        </EmailShareButton>
        <TelegramShareButton style={{
            color: "#0088CC",
            fontSize: "3rem",
            marginRight: "15px",
            cursor: "pointer",
        }} title='LCCIA Member Portal' url={`${BASE_URL2}shared/post/${post_id}`} >
            <i className="fab fa-telegram"></i>
        </TelegramShareButton>
        </div>
    </>
  )
}
