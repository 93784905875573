import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCheckbox,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner,
} from "mdb-react-ui-kit";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import {
  publicAxios,
  restoreAccessToken,
  restoreRefreshToken,
  setAuthToken,
} from "../../_helper/auth";
import BrandLogo from "../../media/logo.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [islogedin, setIslogedin] = useState(true);
  const [err, setErr] = useState(false);
  const [isremembered, setIsRembred] = useState(false);

  const navigate = useNavigate();

  const check_login = () => {
    if (localStorage.getItem("access_token")) {
      console.log("logged in");
      setIslogedin(false);
    }
  };

  useEffect(() => {
    check_login();
  }, []);

  const handleEmail = (eve) => {
    setEmail(eve.target.value);
  };

  const handlePass = (eve) => {
    setPassword(eve.target.value);
  };

  const handleRemeber = (eve) => {
    setIsRembred(eve.target.checked);
    //console.log(eve.target.checked)
  };

  const action_login = (data) => {
    return publicAxios.post("/auth/login/", data);
  };

  const mutation = useMutation(action_login, {
    onSuccess: (data) => {
      // Invalidate and refetch
      console.log(data);
      if (!isremembered) {
        localStorage.setItem("access_token", data.data.access_token);
        localStorage.setItem("refresh_token", data.data.refresh_token);
        setAuthToken(data.data.access_token);
        //restoreAccessToken();
        //restoreRefreshToken();

        setIslogedin(false);
      } else {
        sessionStorage.setItem("access_token", data.data.access_token);
        sessionStorage.setItem("refresh_token", data.data.refresh_token);
        setIslogedin(false);
      }
    },
    onError: (err) => {
      setErr(true);
      console.log(err);
    },
  });

  function handleSubmit1(eve) {
    eve.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    mutation.mutate(data);
  }

  if (!islogedin) {
    return <Navigate to="/_home"></Navigate>;
  }

  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard
              className="bg-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "500px",
                borderTop: "8px solid #F58A2E",
              }}
            >
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <h3 className="fw-bold mb-2 text-center mb-5 mt-2">
                  {" "}
                  <img
                    src={BrandLogo}
                    style={{
                      width: "10%",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Login to Members LCCIA
                </h3>
                    <form onSubmit={handleSubmit1}>
                <div className="d-flex flex-row align-items-center mb-4">
                  <i
                    className="fas fa-envelope fa-lg me-3 fa-fw "
                    style={{ color: "#aaa" }}
                  ></i>

                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Email address"
                    id="formControlLg"
                    type="email"
                    size="lg"
                    onChange={handleEmail}
                    autoFocus
                    required
                  />
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                  <i
                    className="fas fa-lock fa-lg me-3 fa-fw"
                    style={{ color: "#aaa" }}
                  ></i>
                  <MDBInput
                    wrapperClass="mb-2 w-100"
                    label="Password"
                    id="formControlLg"
                    type="password"
                    size="lg"
                    autoComplete="new-password"
                    onChange={handlePass}
                    required
                  />
                </div>
                {/* <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' /> */}
                <div className=" mx-1 mb-3 ">
                  {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' onChange={handleRemeber} /> */}
                  <a
                    className="link-info"
                    style={{
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={()=>{
                        navigate('/admin/reset-password')
                    }}
                  >
                    Forgot Password?
                  </a>
                </div>
                <button
                type = 'submit'
                  className=" btn-lg mb-3 w-100 mt-3"
                  style={{
                    backgroundColor: "#F58A2E",
                    border: "0px",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: "500",
                  }}
                 
                >
                  {!mutation.isLoading ? (
                    "Login"
                  ) : (
                    <MDBSpinner color="light" size="sm" />
                  )}
                </button>
                </form>

                <p className="ms-5 text-center">
                  Don't have an account?{" "}
                  <Link to={`/register`}>
                    <a className="link-info">Register here</a>
                  </Link>
                </p>
                {err ? (
                  <div class="alert alert-danger text-center" role="alert">
                    <i class="fa fa-info-circle"></i> Login Failed! Invalid
                    Credential
                  </div>
                ) : (
                  ""
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
