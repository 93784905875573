import { MDBCard, MDBCardBody, MDBContainer } from 'mdb-react-ui-kit'
import React from 'react'

export default function ViewEnquiry({data}) {
    console.log(data)
  return (
   <>
   <MDBContainer className='mt-3 mb-3 '
   style={{
    boxShadow:"none",
    border:"1px solid #e0e0e0",
    borderRadius:"10px"

   }}>
    <MDBCard  className='m-0 p-0' style={{
            boxShadow:"none"
        }}>
        <MDBCardBody className='m-0 p-2'>
            <div className='d-flex flex-row'>
                <h6 style={{
                    fontSize:"14px",
                    fontWeight:"500",
                    color:"#545353",
                    fontFamily:"Roboto",
                    margin:"5px"

                }}>Name: {`${data.first_name === null?"":data.first_name} ${data.middle_name=== null ? "" : data.middle_name} ${data.last_name === null ? "":data.last_name}`}</h6> 
                </div>
                <div className='d-flex flex-row'>
                <h6 style={{
                    fontSize:"14px",
                    fontWeight:"500",
                    color:"#545353",
                    fontFamily:"Roboto",
                    margin:"5px"
                    
                }}>Email: {`${data.email}`}</h6> 
                </div>
                <div className='d-flex flex-row'>
                <h6 style={{
                    fontSize:"14px",
                    fontWeight:"500",
                    color:"#545353",
                    fontFamily:"Roboto",
                    margin:"5px"
                    
                }}>Mobile No: +91{`${data.mobile_no}`}</h6> 
                </div>
                <div className='d-flex flex-row'>
                <h6 style={{
                    fontSize:"14px",
                    fontWeight:"500",
                    color:"#545353",
                    fontFamily:"Roboto",
                    margin:"5px"
                    
                }}>Enquire: {`${data.query_content}`} </h6> 
                </div>
        </MDBCardBody>
    </MDBCard>
   </MDBContainer>
   </>
  )
}
