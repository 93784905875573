import React, { useEffect, useState } from 'react'
import { MDBInput, MDBIcon, MDBModal, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBTextArea } from 'mdb-react-ui-kit';
import '../style/Datetime.css';
import { getImageURL } from '../../../_helper/image';
import { useDeleteFile, useUploadFile } from '../../../_helper/files';
import DocumentImg from "../../../media/document_avatar.png"

export default function CertificatesModel({ cshow, csetShow, sentCertificateData, reciveData, onChangeCertificate }) {
    const [Name, setName] = useState("");
    const [IssuingOrganization, setIssuingOrganization] = useState("");
    const [IssueDate, setIssueDate] = useState("");
    const [ExpirationDate, setExpirationDate] = useState("");
    const [CertificationId, setCertificationId] = useState("");
    const [description,setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [doc_img, setDocImg] = useState("");
    const hiddenFileInput = React.useRef(null);

    //console.log(reciveData)

    const fileMutation = useUploadFile((data, variables, context) => {
        console.log(data, "Success Data");
        onChangeCertificate({
            "name": reciveData.name,
            "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
            "expiration_date": reciveData.expiration_date,
            "certification_id": reciveData.certification_id,
            "description":reciveData.description,
            "URL": reciveData.URL,
            "doc_img":data.id
         })
 
        setDocImg(data.id);
        console.log(data.id);
      });
    
      const fileMutationdelete = useDeleteFile((data, variables, context) => {
        console.log(data, "Success Data");
      });
    
    
      const handleClick = (event) => {
        event.preventDefault();
        hiddenFileInput.current.click();
      };
    
      const onFile = (eve) => {
        console.log("Got Image");
        const file = eve.target.files[0];
        console.log(file);
        if (file) {
          if (typeof file === "object" && file !== null) {
            const formData = new FormData();
            //formData.append("title", "Some title")
            formData.append("file", file);
    
            if (doc_img !== "") {
              console.log(doc_img);
              fileMutationdelete.mutate(doc_img);
            }
    
            fileMutation.mutate(formData);
          }
        }
      };



    const handleName = (eve) => {
        setName(eve.target.value);
        onChangeCertificate({
           "name": eve.target.value,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
           "expiration_date": reciveData.expiration_date,
           "certification_id": reciveData.certification_id,
           "description":reciveData.description,
            "URL": reciveData.URL
        })

    }

    const handleIssuingOrganization = (eve) => {
        setIssuingOrganization(eve.target.value);
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": eve.target.value,
            "issue_date": reciveData.issue_date,
           "expiration_date": reciveData.expiration_date,
           "certification_id": reciveData.certification_id,
           "description":reciveData.description,
            "URL": reciveData.URL
        })

    }

    const handleIssueDate = (event) => {
        setIssueDate(new Date(event.target.value).toISOString())
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": new Date(event.target.value).toISOString(),
           "expiration_date": reciveData.expiration_date,
           "certification_id": reciveData.certification_id,
           "description":reciveData.description,
            "URL": reciveData.URL
        })
        
    };

    const handleExpirationDate = (event) => {
        setExpirationDate(new Date(event.target.value).toISOString())
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
           "expiration_date": new Date(event.target.value).toISOString(),
           "certification_id": reciveData.certification_id,
           "description":reciveData.description,
            "URL": reciveData.URL
        })
        
    };


    const handleCertificationId = (eve) => {
        setCertificationId(eve.target.value);
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
           "expiration_date": reciveData.expiration_date,
           "certification_id": eve.target.value,
           "description":reciveData.description,
            "URL": reciveData.URL
        })

    }
    const handleDescription = (eve) => {
        setDescription(eve.target.value);
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
           "expiration_date": reciveData.expiration_date,
           "certification_id": reciveData.certification_id ,
           "description":eve.target.value,
            "URL": reciveData.URL
        })

    }
    const handleURL = (eve) => {
        setUrl(eve.target.value);
        onChangeCertificate({
           "name": reciveData.name,
           "issuing_organization": reciveData.issuing_organization,
            "issue_date": reciveData.issue_date,
           "expiration_date": reciveData.expiration_date,
           "certification_id": reciveData.certification_id,
           "description":reciveData.description,
            "URL": eve.target.value
        })

    }


    const certificatesData = {
        "name": Name,
       "issuing_organization": IssuingOrganization,
        "issue_date": IssueDate,
       "expiration_date": ExpirationDate,
       "certification_id": CertificationId,
       "description":description,
       "doc_img":doc_img,
        "URL": url

    }

    
    const sent = () => {

        sentCertificateData(certificatesData)

        setName('');
        setIssuingOrganization('');
        setIssueDate('');
        setExpirationDate('');
        setCertificationId('');
        setUrl('');
        setDescription('');
        setDocImg('');
        csetShow(!cshow)
    }






    return (
        <>

            <MDBModal show={cshow} setShow={csetShow} tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Certificates and Licences</MDBModalTitle>
                            <button
                                className='btn-close'
                                color='none'
                                onClick={() => csetShow(!cshow)
                                }
                                X
                            ></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                        {
                <img
                  src={reciveData.doc_img?`${getImageURL(reciveData.doc_img)}`:DocumentImg}
                  className="preview-img"
                  alt="preview"
                  style={{ width: "150px", marginLeft: "18px" }}
                />
              }
              <div className="d-flex flex-row alignitems-center mb-4 mt-3">
                <button
                  className="mb-2"
                  onClick={handleClick}
                  style={{
                    width: "auto",
                    padding: "7px",
                    borderColor: "#1b5ff2",
                    color: "#1b5ff2",
                    backgroundColor: "#ffff",
                    borderRadius: "5px",
                  }}
                  status={fileMutation.status}
                  disabled={fileMutation.isLoading}
                >
                  {" "}
                  <MDBIcon fas icon="upload" className="px-1" />
                  {fileMutation.status === "loading"
                    ? "Uploading Image..."
                    : reciveData.doc_img
                    ? "Change Your Certificate"
                    : "Your Certificate"}
                </button>
                <input
                  ref={hiddenFileInput}
                  onChange={onFile}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Name'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleName}
                                    value={`${reciveData.name}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Issuing Organization'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleIssuingOrganization}
                                    value={`${reciveData.issuing_organization}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Certification Id'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleCertificationId}
                                    value={`${reciveData.certification_id}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Issue Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleIssueDate}
                                    value={`${reciveData.issue_date=== undefined ? {} : reciveData.issue_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Expiration Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleExpirationDate}
                                    value={`${reciveData.expiration_date=== undefined ? {} : reciveData.expiration_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                          
                          <MDBTextArea
                              wrapperClass='mb-4 w-100'
                              label='Description'
                              id='formControlLg'
                              type='text'
                              size="md"
                              rows={4}
                              onChange={handleDescription}
                              value={`${reciveData.description}`}
                              autoComplete='off'

                          />
                      </div>
                            <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                          
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='URL'
                                    id='formControlLg'
                                    type='url'
                                    size="md"
                                    onChange={handleURL}
                                    value={`${reciveData.URL}`}
                                    autoComplete='off'

                                />
                            </div>

                        </MDBModalBody>
                        <MDBModalFooter>


                            < button className='mt-4 py-1'
                                style={{ width: "8rem", color: "white", backgroundColor: "#1b5ff2", borderRadius: "5px", border: 'none' }}
                                onClick = {
                                    sent
                                }
                            >

                                <MDBIcon fas icon='save' className='px-1' />
                                Save
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </>
    )
}
