import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useUserTagUpdate } from "../../../_helper/actions";
import { secureAxios } from "../../../_helper/auth";

export default function UserTag() {
  const [gettags, setGettags] = useState([]);
  const [alluser, setAllUser] = useState([]);
  const [email, setemail] = useState("");
  const [profileid, setProfileId] = useState("");
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const GetTagsData = () => {
    secureAxios
      .get("/items/tags?filter[status][_eq]=true")
      .then((res) => {
        //console.log(res.data)
        setGettags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetTagsData();
    GetAllUser();
  }, []);

  const GetAllUser = async () => {
    await secureAxios
      .get(
        "/users?fields=first_name,last_name,email,profile&filter[hidden][_eq]=false&filter[status][_eq]=active&limit=-1"
      )
      .then((res) => {
        setAllUser(res.data);
      });
  };

  const tag_arr = gettags.map((item, idx) => ({
    label: item.name,
    value: item.name,
  }));
  // console.log(tag_arr)

  const user_arr = alluser.map((item, idx) => ({
    label: `${item.first_name} ${item.last_name} `,
    value: item.email,
    id: item.profile,
  }));
  console.log(profileid);

  const mutation = useUserTagUpdate(profileid);

  const SubmitData = () => {
    mutation.mutate(data);
  };

  return (
    <>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}>Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }}>
          Setting
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }} active>
          Tags
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>{" "}
      <h4 style={{ marginLeft: "12px" }}>Tagged User</h4>
      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <div className="container p-0">
                  <h5 className="mb-4">Tag to user</h5>

                  <div className="d-flex flex-row align-items-center mb-4 w-100">
                    <Select
                      className="w-100"
                      placeholder="Select User"
                      onChange={(event) => {
                        setemail(event.value);
                        setProfileId(event.id[0]);
                      }}
                      options={user_arr}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4">
                    <MDBInput
                      wrapperClass="w-100"
                      readOnly
                      label="Email"
                      value={email}
                    />
                  </div>
                  <div className="d-flex flex-row align-items-center mb-4">
                    <Select
                      className="w-100"
                      options={tag_arr}
                      isSearchable={false}
                      placeholder="Select Tag"
                      onChange={(event) => {
                        setData({ ...data, tag: event.label });
                      }}
                    />
                  </div>
                </div>

                <button
                  className="mt-4 py-1"
                  style={{
                    width: "8rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={SubmitData}
                >
                  {mutation.isLoading ? (
                    <MDBSpinner color="light" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                {mutation.isSuccess ? navigate("/profile/setting") : ""}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
