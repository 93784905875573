import React from 'react'

export default function ProjectInformation({ allProjectData }) {
    console.log(allProjectData)
    return (
        <div>
                       {
                    !allProjectData || allProjectData?.length === 0 ?
                    ""
              
                    :
                    <h4
                    style={{

                        marginTop: "20px",
                        fontWeight: "500",
                        color: "#8A8A8A"

                    }}>
                    Project Information</h4>
}

            {
                allProjectData === null || undefined ? [] : allProjectData.map((value, idx) => (
                    <div>
                        <h4
                            style={{
                                marginTop: "10px",
                                fontSize: "16px",
                                fontWeight: "450",

                            }}
                        >
                            {value.name}


                        </h4>

                        <p
                            style={{

                                fontSize: "14px",
                                fontWeight: "400",
                               
                            }}
                        > {value.assoicated_org}
                            <br></br>
                            <a href={value.URL}>{value.URL}</a>
                        </p>

                        <p style={{
                             fontSize: "14px",
                            justifyContent: "flex-start",
                            marginRight: "5px",
                            marginTop: "10px"


                        }}>
                            {value.description}
                        </p>
                    </div>

                ))
            }

        </div>
    )
}
