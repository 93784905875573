import React, { useEffect, useState } from "react";
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBContainer,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useNavigate, useParams } from "react-router-dom";
import { secureAxios } from "../../_helper/auth.js";
import { getImageURL } from "../../_helper/image.js";
import OrganisationDetailPage from "./BusinessProfileComponents/OrganisationDetailPage.js";
import Aboutus from "./DetailPageComponents/Aboutus.js";
import EducationInformation from "./DetailPageComponents/EducationInformation.js";
import HeaderCard from "./DetailPageComponents/HeaderCard.js";
import TabInfo from "./DetailPageComponents/TabInfo.js";
import WorkExperience from "./DetailPageComponents/WorkExperience.js";

export default function DetailPage() {
  const { name } = useParams();
  const [profiledata, setProfileData] = useState({});
  const [businessDetailData, setBusinessDetailData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  const getRole = () => {
    secureAxios
      .get("/users/me?fields=role.name")
      .then((res) => {
        res.data.role.name === "Administrator"
          ? setIsAdmin(true)
          : setIsAdmin(false);
      })
      .catch((err) => {});
  };

  const getBusinessData = async (id) => {
    await secureAxios
      .get(
        `/users/${localStorage.getItem(
          "user_bid"
        )}?fields=business_profiles.*,business_profiles.categories.category_id.*&limit=-1`
      )
      .then((data) => {
        console.log(data.data);
        setBusinessDetailData(data?.data?.business_profiles);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = async () => {
    await secureAxios
      .get(
        `/items/profile/${name}?fields=*.*,user.*,user.role.name,business_profiles.id,chapter.*`
      )
      .then((data) => {
        console.log(data);
        console.log(data?.data?.user.business_profiles[0]);
        setProfileData(data.data);
        console.log(data.data.current_city);
        //console.log(data)
        getBusinessData(data?.data?.user.business_profiles[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getRole();
    //getBusinessData();
  }, []);

  //console.log(profiledata)

  return (
    <>
      <MDBContainer fluid>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}> Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}> All Members</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>
            <a style={{ cursor: "default" }}>Detail Page</a>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        {isAdmin && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <MDBBtn
              className="mx-2"
              color="tertiary"
              rippleColor="light"
              onClick={() => {
                navigate(
                  `/profile/edit-profile?id=${localStorage.getItem("user_bid")}`
                );
              }}
            >
              Act As User
            </MDBBtn>
          </div>
        )}
      </MDBContainer>
      {isLoading ? (
        <div className="text-center">
          <MDBSpinner color="success" size="xl" />
        </div>
      ) : (
        <div>
          <HeaderCard
            mname={
              profiledata?.user?.user_type === "individual"
                ? `${
                    profiledata?.user?.first_name === null
                      ? ""
                      : profiledata?.user?.first_name
                  } ${
                    profiledata?.user?.middle_name === null
                      ? ""
                      : profiledata?.user?.middle_name
                  } ${
                    profiledata?.user?.last_name === null
                      ? ""
                      : profiledata?.user?.last_name
                  }`
                : businessDetailData[0]?.business_name
            }
            img={
              profiledata?.user?.user_type === "individual"
                ? `${
                    profiledata?.user?.avatar === null
                      ? " "
                      : getImageURL(profiledata?.user?.avatar)
                  }`
                : getImageURL(businessDetailData[0]?.business_logo)
            }
            nativeVillage={
              profiledata?.user?.user_type === "individual"
                ? `${
                    profiledata?.native_village === null || undefined
                      ? ""
                      : profiledata.native_village
                  }`
                : ""
            }
            nativeDistrict={
              profiledata?.user?.user_type === "individual"
                ? `${
                    profiledata.native_district === null || undefined
                      ? ""
                      : profiledata.native_district
                  }`
                : ""
            }
            LCCIADesignation={`${
              profiledata?.user?.role?.name === null || undefined
                ? ""
                : profiledata?.user?.role?.name
            }`}
            email={`${
              profiledata?.user?.email === null || undefined
                ? ""
                : profiledata?.user?.email
            }`}
            //phone={`${profiledata.mobile === null || undefined ? "" : profiledata.mobile}`}
            expire_date={`${
              profiledata.membership_valid_till === null || undefined
                ? ""
                : profiledata.membership_valid_till
            }`}
            current_city={`${
              profiledata.current_city === null || undefined
                ? ""
                : profiledata.current_city
            }`}
            lcciaRole={
              profiledata?.user?.user_type === "individual"
                ? `${
                    profiledata.LCCIA_Designation === null ||
                    profiledata.LCCIA_Designation === undefined
                      ? ""
                      : profiledata.LCCIA_Designation
                  }`
                : "Organisation"
            }
            tag={
              profiledata?.user?.user_type === "individual"
                ? `${profiledata?.tag === null ? "" : profiledata?.tag}`
                : ""
            }
            chapter={profiledata.chapter}
          />

          {profiledata?.user?.user_type === "individual" ? (
            <div
              className="container"
              style={{
                marginBottom: "40px",
              }}
            >
              <div class="row">
                <div class="col-sm">
                  <Aboutus aboutus={profiledata.about} />

                  <WorkExperience value={profiledata.experiences} />

                  <EducationInformation value={profiledata.education_details} />
                </div>
                {/* <div class="col-sm">
    </div> */}
                <div class="col-sm">
                  <TabInfo
                    email={`${
                      profiledata?.user?.email === null || undefined
                        ? ""
                        : profiledata?.user?.email
                    }`}
                    phone={`${
                      profiledata?.mobile === null || undefined
                        ? ""
                        : profiledata?.mobile
                    }`}
                    addresss={profiledata.addresss}
                    gender={`${
                      profiledata.gender === null || undefined
                        ? ""
                        : profiledata.gender
                    }`}
                    projectsData={profiledata.projects}
                    patentsData={profiledata.patents}
                    certificatesData={profiledata.licenses_or_certifications}
                    awardsData={profiledata.honors_and_awards}
                    incentiveData={profiledata.initiatives}
                    socialtalData={profiledata.sociatal}
                    businessData={businessDetailData}
                  />
                </div>
              </div>
            </div>
          ) : (
            <OrganisationDetailPage buisnessData={businessDetailData} />
          )}
        </div>
      )}
    </>
  );
}
