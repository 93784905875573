import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { secureAxios } from "../../../_helper/auth";
import ChangePassword from "./ChangePassword";

export default function Settings() {
  const navigate = useNavigate();
  const [role, setrole] = useState();
  const [tag, settag] = useState("");

  const getData = async () => {
    await secureAxios
      .get(`/users/me?fields=role.name,profile.tag`)
      .then((resp) => {
        //console.log(resp.data?.role.name)
        console.log(resp.data);
        setrole(resp.data?.role.name);
        settag(resp.data?.profile[0].tag);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem style={{ cursor: "default" }} active>
          Settings
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>

      <>
        <MDBContainer className="mb-5">
          <h4>Settings</h4>
          <MDBRow>
            <MDBCol sm={4}>
              <MDBContainer>
                {role === "Administrator" ||
                role === "OrgAdmin" ||
                role === "AdminShadow" ? (
                  <>
                    <button
                      className="mt-3 mb-3"
                      style={{
                        backgroundColor: "#F58A2E",
                        width: "100%",
                        padding: "5px",
                        color: "white",
                        border: 0,
                      }}
                      disabled
                    >
                      Verifications
                    </button>
                    <div className="text-left">
                      <button
                        className="p-1"
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate("/profile/document-verification");
                        }}
                      >
                        Documents Verifications
                      </button>
                      <br />
                      <button
                        className=" p-1"
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate(
                            "/profile/membership/payment-verification/admin"
                          );
                        }}
                      >
                        Payment Verifications
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </MDBContainer>
            </MDBCol>
            <MDBCol sm={4}>
              <MDBContainer>
                {role === "Administrator" ||
                role === "OrgAdmin" ||
                role === "AdminShadow" ? (
                  <>
                    <button
                      className="mt-3 mb-3"
                      style={{
                        backgroundColor: "#F58A2E",
                        width: "100%",
                        padding: "5px",
                        color: "white",
                        border: 0,
                      }}
                      disabled
                    >
                      Approvals
                    </button>
                    <div className="text-left">
                      <button
                        className="p-1"
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate("/profile/approved");
                        }}
                      >
                        User Approval Notifications
                      </button>
                    </div>
                    <div className="text-left">
                      <button
                        className="p-1"
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate("/profile/organisation-approved");
                        }}
                      >
                        Organisation Approval Notifications
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {tag === null || tag === "" ? (
                  <></>
                ) : role !== "Administrator" ||
                  role !== "OrgAdmin" ||
                  role !== "AdminShadow" ? (
                  <div className="text-left ">
                    <button
                      className="p-1"
                      style={{
                        color: "#F58A2E",
                        background: "none",
                        borderRadius: "5px",
                        border: "none",
                      }}
                      onClick={() => {
                        navigate(
                          "/profile/membership/upgrade-verification/reference-membership"
                        );
                      }}
                    >
                      My Reference Approvals
                    </button>
                  </div>
                ) : (
                  <>
                    <button
                      className="mt-3 mb-3"
                      style={{
                        backgroundColor: "#F58A2E",
                        width: "100%",
                        padding: "5px",
                        color: "white",
                        border: 0,
                      }}
                      disabled
                    >
                      Approvals
                    </button>

                    <div className="text-left">
                      <button
                        className=""
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate(
                            "/profile/membership/upgrade-verification/reference-membership"
                          );
                        }}
                      >
                        My Reference Approvals
                      </button>
                    </div>
                  </>
                )}

                <div></div>
              </MDBContainer>
            </MDBCol>

            <MDBCol sm={4}>
              {role === "Administrator" ||
              role === "OrgAdmin" ||
              role === "AdminShadow" ? (
                <MDBContainer>
                  <button
                    className="mt-3 mb-3"
                    style={{
                      backgroundColor: "#F58A2E",
                      width: "100%",
                      padding: "5px",
                      color: "white",
                      border: 0,
                    }}
                    disabled
                  >
                    Maintain Setup
                  </button>
                  <div>
                    <div className="text-left">
                      <button
                        className="p-1"
                        style={{
                          color: "#F58A2E",
                          background: "none",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onClick={() => {
                          navigate(
                            "/profile/membership/upgrade-verification/setup"
                          );
                        }}
                      >
                        All Setup
                      </button>
                      <div>
                        <button
                          className="p-1"
                          style={{
                            color: "#F58A2E",
                            background: "none",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => {
                            navigate("/profile/edit-membership");
                          }}
                        >
                          Add Membership Plan
                        </button>
                      </div>
                      <div>
                        <button
                          className="p-1"
                          style={{
                            color: "#F58A2E",
                            background: "none",
                            borderRadius: "5px",
                            border: "none",
                          }}
                          onClick={() => {
                            navigate("/profile/user-tag");
                          }}
                        >
                          Set Member Tag
                        </button>
                      </div>
                    </div>
                  </div>
                </MDBContainer>
              ) : (
                <></>
              )}
            </MDBCol>

            <MDBCol sm={4}>
              <MDBContainer>
                <button
                  className="mt-3 mb-3"
                  style={{
                    backgroundColor: "#F58A2E",
                    width: "100%",
                    padding: "5px",
                    color: "white",
                    border: 0,
                  }}
                  disabled
                >
                  Utilities
                </button>
                <div className="text-left">
                  <button
                    className="p-1"
                    style={{
                      color: "#F58A2E",
                      background: "none",
                      borderRadius: "5px",
                      border: "none",
                    }}
                    onClick={() => {
                      navigate("/profile/upgrade-membership");
                    }}
                  >
                    Upgrade My Membership
                  </button>
                </div>
              </MDBContainer>
            </MDBCol>
          </MDBRow>

          <ChangePassword />
        </MDBContainer>
      </>
    </>
  );
}
