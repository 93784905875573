import React from 'react'
import { useParams } from 'react-router-dom'
import EditInfo from './BusinessProfileComponents/EditInfo';
import Navbar from './navbar';

export default function BusinessRouting() {
    const {id} = useParams();
  
    if (id === "add"){
        return (
            <>
            <Navbar />
            <body>
              <div className="insidebody">
                <div style={{ marginLeft: "10px" }}>
                <EditInfo bid={null}/>
                </div>
              </div>
            </body>
          </>

        )
       
    }else {
        return (
            <>
            <Navbar />
            <body>
              <div className="insidebody">
                <div style={{ marginLeft: "10px" }}>
                <EditInfo bid = {Number(id)}/>
                </div>
              </div>
            </body>
          </>

        )
    }
  
}
