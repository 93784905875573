import { restoreAccessToken } from "./auth";
import { BASE_URL } from "../constant";
import AvatarPhoto from '../media/avatar2.jpeg'

export function getImageURL(id) {
    if (id) {
        return `${BASE_URL}assets/${id}?&access_token=${restoreAccessToken()}`;
    } else {
        return AvatarPhoto;
    }
}