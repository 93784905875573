import React, { useEffect, useState } from "react";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
  MDBIcon,
  MDBModalBody,
  MDBModalFooter,
  MDBModalContent,
  MDBModalDialog,
  MDBModalTitle,
  MDBModal,
  MDBModalHeader,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { secureAxios } from "../../../_helper/auth";
import CreateMembershipForm from "./CreateMembershipForm";
import { useEditMembership } from "../../../_helper/MembershipAction";
import DeleteMembership from "./DeleteMembership";

export default function EditMemberShipTable() {
  const [membershipdata, setMemberShipdata] = useState([]);
  const [openeditmodal, setOpenEditModal] = useState(false);
  const [opendeletemodal, setOpenDeleteModal] = useState(false);
  const [editeddata, setEditedData] = useState({});
  const [id, setId] = useState("");
  const [getrun, setGetRun] = useState(false);

  const category_color_theme = {
    men: "#3399FF",
    women: "#EF0B90",
    organisation: "#fc9917",
    family: "#fc9917",
  };

  const getData = () => {
    secureAxios
      .get(`/items/membership_plan`)
      .then((res) => {
        setMemberShipdata(res.data);
        //setCategory([data.data])
        setGetRun(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [getrun]);

  const onChange = (data) => {
    setEditedData(data);
  };

  const editmutation = useEditMembership(id);
  const SubmitEditedData = () => {
    editmutation.mutate(editeddata);
    setGetRun(true);
    setOpenEditModal(false);
  };

  const DeleteRunStatus = (data) => {
    setGetRun(true);
  };

  //console.log(membershipdata);

  return (
    <>
      <DeleteMembership
        opendeletemodel={opendeletemodal}
        setopendeletemodel={setOpenDeleteModal}
        id={id}
        status={DeleteRunStatus}
      />
      <MDBModal show={openeditmodal} setShow={setOpenEditModal} tabIndex="-1">
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Edit Plan</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => setOpenEditModal(!openeditmodal)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              <CreateMembershipForm
                getmodal={true}
                id={id}
                onChange={onChange}
              />
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={SubmitEditedData}
              >
                {editmutation.isLoading ? (
                  <MDBSpinner color="light" size="sm" />
                ) : (
                  <>
                    <MDBIcon fas icon="save" className="px-1" />
                    Save
                  </>
                )}
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBTable
        maxHeight="200px"
        width="200px"
        small
        responsive
        align="middle"
        hover
        bordered
        style={{
          backgroundColor: "white",
        }}
      >
        <MDBTableHead
          style={{
            backgroundColor: "#FAFAFA",
            marginBottom: "5px",
          }}
        >
          <></>

          <tr>
            <th className="text-center">Name</th>
            <th className="text-center">Category</th>
            <th className="text-center">Price</th>
            <th className="text-center">One Time Fees</th>
            <th className="text-center">Voting Rights</th>
            <th className="text-center">Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {console.log("MemberShip DATA", membershipdata)}
          <>
            {membershipdata.map((value, idx) => (
              <tr>
                <td scope="row" className="text-center">
                  {value.name}
                </td>
                <td
                  scope="row"
                  className="text-center"
                  style={{ fontSize: "18px" }}
                >
                  <span
                    className="badge px-2 "
                    style={{
                      backgroundColor: `${
                        category_color_theme[value.category]
                      }`,
                      fontWeight: "400",
                    }}
                  >
                    {value.category.charAt(0).toUpperCase() +
                      value.category.slice(1)}
                  </span>
                </td>
                <td scope="row" className="text-center">
                  {value.price}
                </td>
                <td scope="row" className="text-center">
                  {value.one_time_fees}
                </td>
                <td
                  scope="row"
                  className="text-center"
                  style={{ fontSize: "18px" }}
                >
                  <span
                    className="badge px-2 "
                    style={{
                      backgroundColor: `${
                        value.voting_rights ? "#35bd52" : "#f52222"
                      }`,
                      fontWeight: "400",
                    }}
                  >
                    {value.voting_rights ? "Yes" : "No"}
                  </span>
                </td>
                <td
                  scope="row"
                  className="text-center"
                  style={{ fontSize: "18px" }}
                >
                  <div className="row text-center">
                    <div className="col">
                      <button
                        className="px-3 mb-1"
                        onClick={() => {
                          setId(value.id);
                          setOpenEditModal(!openeditmodal);
                          console.log(value.id);
                        }}
                        style={{
                          backgroundColor: "#1a21eb",
                          border: "0px",
                          borderRadius: "5px",
                        }}
                      >
                        {" "}
                        <MDBIcon
                          icon="edit"
                          size="sm"
                          className="px-1"
                          style={{
                            color: "white",
                          }}
                        />
                      </button>
                    </div>

                    <div className="col">
                      <button
                        className="px-3"
                        style={{
                          color: "white",
                          backgroundColor: "#f20a0a",
                          border: "0px",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          setId(value.id);
                          setOpenDeleteModal(!opendeletemodal);
                        }}
                      >
                        {" "}
                        <MDBIcon icon="trash" size="sm" className="px-1" />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </>
        </MDBTableBody>
      </MDBTable>
    </>
  );
}
