import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBIcon,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { useUpdateUserById, useUserUpdate } from "../../_helper/actions";
import { secureAxios } from "../../_helper/auth";
import { getImageURL } from "../../_helper/image";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImageCropper from "./crop_image/ImageCropper";

export default function UserInformation() {
  //   const [imageToCrop, setImageToCrop] = useState(undefined);
  //   const [croppedImage, setCroppedImage] = useState(undefined);
  const [cropmodel, setcropModel] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [avatar, setAvtar] = useState("");

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const user_id = searchParams.get("id");
  //   const onUploadFile = (event) => {
  //     if (event.target.files && event.target.files.length > 0) {
  //       const reader = new FileReader();

  //       reader.addEventListener("onload", () => setImageToCrop(reader.result));

  //       reader.readAsDataURL(event.target.files[0]);
  //       console.log(reader)
  //     }
  //   };

  const handleEmail = (eve) => {
    setEmail(eve.target.value);
  };

  const handleFirstName = (eve) => {
    setFirstName(eve.target.value);
  };

  const handleMiddleName = (eve) => {
    setMiddleName(eve.target.value);
  };

  const handleLastName = (eve) => {
    setLastName(eve.target.value);
  };

  const onChange1 = (data) => {
    console.log(data);
    setAvtar(data);
  };

  const getData = () => {
    secureAxios
      .get(`/users/${user_id ? user_id : "me"}`)
      .then((data) => {
        console.log(data.data);
        setFirstName(
          data?.data?.first_name === null ? "" : `${data.data?.first_name}`
        );
        setLastName(
          data?.data?.last_name === null ? "" : `${data.data.last_name}`
        );
        setMiddleName(
          data?.data?.middle_name === null ? "" : `${data.data?.middle_name}`
        );
        setEmail(data?.data?.email === null ? "" : `${data.data?.email}`);
        setAvtar(data?.data?.avatar === null ? "" : `${data.data?.avatar}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // const fileMutation = useUploadFile((data, variables, context) => {
  //   console.log(data, "Success Data");
  //   setAvtar(data.id);
  //   console.log(data.id);
  // });

  // const fileMutationdelete = useDeleteFile((data, variables, context) => {
  //   console.log(data, "Success Data");
  // });

  const mutation = useUserUpdate(() => console.log("Profile Updated"));
  const user_update_mutation = useUpdateUserById(user_id);

  const onSubmitUserInfo = () => {
    const userdata = {
      first_name: firstname,
      middle_name: middlename,
      last_name: lastname,
      email: email,
      avatar: avatar,
    };
    if (user_id) {
      user_update_mutation.mutate(userdata);
    } else {
      mutation.mutate(userdata);
    }
  };

  // const handleClick = (event) => {
  //   event.preventDefault();
  //   hiddenFileInput.current.click();
  // };

  // const onFile = (eve) => {
  //   console.log("Got Image");
  //   const file = eve.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     if (typeof file === "object" && file !== null) {
  //       const formData = new FormData();
  //       //formData.append("title", "Some title")
  //       formData.append("file", file);

  //       if (avatar !== "") {
  //         console.log(avatar);
  //         fileMutationdelete.mutate(avatar);
  //       }

  //       fileMutation.mutate(formData);
  //     }
  //   }
  // };

  return (
    <>
      <ImageCropper
        openModel={cropmodel}
        setOpenModel={setcropModel}
        avatar_data={avatar}
        onChange1={onChange1}
      />
      <h4 style={{ marginLeft: "12px" }}>User Information</h4>
      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <div className="container p-0">
                  <div className="row ">
                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4 w-100 "
                        label="First Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={`${firstname}`}
                        onChange={handleFirstName}
                        autoComplete="off"
                        autoFocus
                      />
                    </div>
                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4 w-100 "
                        label="Middle Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={`${middlename}`}
                        onChange={handleMiddleName}
                      />
                    </div>

                    <div className="col-sm">
                      <MDBInput
                        wrapperClass="mb-4  w-100"
                        label="Last Name"
                        id="formControlLg"
                        type="text"
                        size="md"
                        value={`${lastname}`}
                        onChange={handleLastName}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center ">
                  <MDBInput
                    wrapperClass="mb-4 w-100"
                    label="Email address"
                    id="formControlLg"
                    type="email"
                    size="md"
                    value={`${email}`}
                    onChange={handleEmail}
                  />
                </div>

                {
                  <img
                    src={getImageURL(avatar)}
                    className="preview-img"
                    alt="preview"
                    style={{
                      width: "80px",
                      marginTop: "",
                      marginBottom: "15px ",
                    }}
                  />
                }

                <button
                  className="mb-2"
                  onClick={() => {
                    setcropModel(!cropmodel);
                  }}
                  style={{
                    width: "12rem",
                    borderColor: "#1b5ff2",
                    color: "#1b5ff2",
                    backgroundColor: "#ffff",
                    borderRadius: "5px",
                  }}
                >
                  <MDBIcon fas icon="upload" className="px-1" />
                  Profile Photo
                </button>

                <button
                  className="mt-4 py-1"
                  style={{
                    width: "8rem",
                    color: "white",
                    backgroundColor: "#1b5ff2",
                    borderRadius: "5px",
                    border: "none",
                  }}
                  onClick={onSubmitUserInfo}
                >
                  {mutation.isLoading ? (
                    <MDBSpinner color="light" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
                {mutation.isSuccess || user_update_mutation.isSuccess ? (
                  <div
                    class="alert alert-success text-center mt-2"
                    role="alert"
                  >
                    <i class="fa fa-info-circle"></i> Data Saved Successfully!
                  </div>
                ) : (
                  ""
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
