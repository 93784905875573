import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import ContactInformation from './ContactInformation';
import PersonalInformation from './PersonalInformation';
import ProjectInformation from './ProjectInformation';
import PatentInformation from './PatentInformation';
import CertificateInformation from './CertificateInformation';
import AwardsInformation from './AwardsInformation';
import IncentiveInformation from './InitiativesInformation';
import BusinessInfo from './BusinessInfo';
import SociatalInformation from './SociatalInformation';

export default function TabInfo({
    email,
    phone,
    addresss=[],
    gender,
    projectsData=[],
    patentsData=[],
    certificatesData=[],
    awardsData=[],
    incentiveData = [],
    socialtalData = [],
    businessData = []
  

}) {
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <MDBTabs fill className='mb-3' style={{
         marginTop: "20px"
      }}>
        
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
          professional
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            Personal  
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
          business
          </MDBTabsLink>
        </MDBTabsItem>
       
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={basicActive === 'tab2'}>
              <ContactInformation 
              email={email} 
              phone={phone} 
              address={addresss}
               />
              <PersonalInformation
              gender={gender}
              />
               </MDBTabsPane>
        <MDBTabsPane show={basicActive === 'tab1'}>
            
            <ProjectInformation
            allProjectData={projectsData}
            />

            <PatentInformation
            allPatentsData={patentsData}
            />

            <CertificateInformation
            allCertificatevalue={certificatesData}
            />

            <AwardsInformation
            allAwardsvalue={awardsData}
            />

            <IncentiveInformation
            allInitiativevalue={incentiveData}
            />

            <SociatalInformation
            allsocialtalvalue={socialtalData}
            />

            
            </MDBTabsPane>

            <MDBTabsPane show={basicActive === 'tab3'}>
              {
                businessData.map((item,value)=>(

                  <BusinessInfo AllBusinessData={item}/>
                ))
              }
            

          
            
            </MDBTabsPane>
       
      </MDBTabsContent>
    </>
  );
}