import React from 'react'
import { useParams } from 'react-router-dom'

export default function SingleViewPost() {
    const {id} = useParams();

    
  return (
    <div>Under Developement</div>
  )
}
