import React from 'react'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import Navbar from '../home/navbar'
import Layout from './components/Layout'

export default function MainWall() {
    
    
  
  return (
    <>
    <Navbar/>
    <main>
        <Layout/>
    </main>
    </>
  )
}
