import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { secureAxios } from "../../../_helper/auth";
import { useSendEmail } from "../../../_helper/SendEmailAction";

export default function AdminPaymentVerification() {
  const queryClient = useQueryClient();
  const {
    isSuccess: successMembers,
    isLoading: loadingMembers,
    data: dataMembers,
  } = useQuery(["members"], () =>
    secureAxios
      .get(
        "/items/profile?fields=user.email,mobile,first_name,last_name,id,payment_verification.*&limit=-1"
      )
      .then((res) => res.data)
  );

  const updateMutation = useMutation(
    (newData) => secureAxios.patch(`items/profile/${newData.id}`, newData.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["members"]);
      },
    }
  );
  const updatePaymentMutation = useMutation(
    (newData) =>
      secureAxios.patch(
        `items/payment_verification/${newData.id}`,
        newData.data
      ),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["members"]);
      },
    }
  );

  const email_mutation = useSendEmail();

  const onApproval = (id, plan_id, user_email) => {
    console.log(id, plan_id);
    updateMutation.mutate({
      id: id,
      data: {
        plan: plan_id,
        membership_start_date: new Date().toISOString(),
      },
    });

    email_mutation.mutate({
      to: user_email,
      subject: "Membership Upgraded",
      body: "Dear Member, Your membership has been upgraded.",
    });
  };

  const Membership_till_date = () => {
    const curr_date = new Date();
    curr_date.setDate(curr_date.getDate() + 365);
    return curr_date.toISOString();
  };
  const onPaymentApproval = (id) => {
    updatePaymentMutation.mutate({
      id: id,
      data: {
        status: "approved",
      },
    });
  };
  const onPaymentRejected = (id) => {
    updatePaymentMutation.mutate(
      {
        id: id,
        data: {
          status: "rejected",
        },
      },
      {
        onSuccess: () => {
          updateMutation.mutate({
            membership_valid_till: Membership_till_date(),
          });
        },
      }
    );
  };

  const getData = async () => {
    await secureAxios
      .get(
        `/items/profile?fields=user.email,mobile,first_name,last_name,id,payment_verification.*&limit=-1`
      )
      .then((res) => {
        // setDataMember(res.data)
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <MDBTable align="middle" responsive>
        <MDBTableHead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Date</th>
            <th scope="col">Bank Name</th>
            <th scope="col">UTRS Number</th>
            <th scope="col">Amount</th>
            <th scope="col"> Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {dataMembers?.map((value, idx) => (
            <>
              <tr>
                {value.payment_verification.map((value1) =>
                  value1["status"] !== "approved" ? (
                    value1["status"] === "rejected" ? (
                      <></>
                    ) : (
                      <>
                        <td>{`${
                          value["first_name"] ? value["first_name"] : ""
                        } ${value["middle_name"] ? value["middle_name"] : ""} ${
                          value["last_name"] ? value["last_name"] : ""
                        }`}</td>
                        <td>
                          {value["user"]["email"] ? value["user"]["email"] : ""}
                        </td>
                        <td>{value["mobile"]}</td>
                        <td>
                          {value1["pay_date"]?.slice(0, 10)
                            ? value1["pay_date"]?.slice(0, 10)
                            : ""}
                        </td>
                        <td>{value1["bank_name"]}</td>
                        <td>{value1["utrs"]}</td>
                        <td>{value1["amount_paid"]}</td>

                        <td>
                          <div className="d-flex">
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#11ad2b",
                                borderRadius: "5px",

                                border: "none",
                              }}
                              onClick={() => {
                                onApproval(
                                  value.id,
                                  value1.plan_id,
                                  value["user"]["email"]
                                );
                                onPaymentApproval(value1.id);
                              }}
                            >
                              Approve
                            </button>
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#fa0a0a",
                                borderRadius: "5px",
                                marginLeft: "5px",
                                border: "none",
                              }}
                              onClick={() => {
                                onPaymentRejected(value1.id);
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </>
                    )
                  ) : (
                    <></>
                  )
                )}
              </tr>
            </>
          ))}
        </MDBTableBody>
      </MDBTable>
    </>
  );
}
