import {
  MDBBtn,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useRegister } from "../../_helper/actions";
import TaxanomyCategory from "../home/BusinessProfileComponents/TaxanomyComponent";

export default function OrganisationRegistration() {
  const navigate = useNavigate();
  const [basicModal, setBasicModal] = useState(false);
  const [islogedin, setIslogedin] = useState(true);
  const [status, setStatus] = useState(true);
  const [data, setdata] = useState({});

  const toggleShow = () => setBasicModal(!basicModal);

  const mutation = useRegister(() => setBasicModal(true));

  let arr = [];

  const onChangecattemp = (data) => {
    //console.log(data);
    arr = [];
    arr.push(data);
    console.log(arr);
  };

  const onRegistration = (eve) => {
    eve.preventDefault();
    //console.log(arr)
    const Buissness_arr =
      arr[0] === null || arr[0] === undefined
        ? []
        : arr[0].map((item) => ({ category_id: item.id }));

    console.log(Buissness_arr);

    var main_data = Object.assign({}, data, { categories: Buissness_arr });

    const org_data = {
      first_name: data.first_name,
      middle_name: data.middle_name ? data.middle_name : "",
      last_name: data.last_name,
      email: data.email,
      password: "hello123",
      role: "f2a489e0-e749-4067-ac85-537f3deb9a36",
      status: "draft",
      user_type: "organisation",
      profile: [
        {
          first_name: data.first_name,
          middle_name: data.middle_name ? data.middle_name : "",
          last_name: data.last_name,
          email: data.email,
          mobile: data.mobile,
          current_city:data.current_city
        },
      ],

      business_profiles: [main_data],
    };

    mutation.mutate(org_data);
  };

  const buisness_options = [
    {
      label: "Product",
      value: "0",
    },
    { label: "Services", value: "1" },
    { label: "Manufacturing", value: "2" },
    { label: "Outlet", value: "3" },
    { label: "Trader", value: "4" },
    { label: "Retail", value: "5" },
    { label: "Showroom", value: "6" },
    { label: "Service Centre", value: "7" },
    { label: "Construction & Real Estate", value: "8" },
    { label: "Import", value: "9" },
    { label: "Export", value: "10" },
    { label: "Producer", value: "11" },
    { label: "Financial Institution", value: "12" },
    { label: "Educational Institution", value: "13" },
    { label: "Social Institution", value: "14" },
    { label: "Industry", value: "15" },
    { label: "Trade Association", value: "16" },
    { label: "Govt/ Semi Government Org", value: "17" },
    { label: "Agriculture", value: "18" },
  ];

  const OrganisationCategory = [
    { label: "Individual", value: 1 },
    { label: "Professional", value: 2 },
    { label: "Corporate", value: 3 },
    { label: "Society", value: 4 },
    { label: "Non Government", value: 5 },
    { label: "Small Business", value: 6 },
    { label: "Micro Business", value: 7 },
    { label: "Startup", value: 8 },
  ];

  const legalStatus = [
    { label: "Propritership", value: 1 },
    { label: "Partnership", value: 2 },
    { label: "Private Limited", value: 3 },
    { label: "Public Limited", value: 4 },
    { label: "Other", value: 5 },
    { label: "LLP", value: 6 },
    { label: "Individual", value: 7 },
  ];
  const check_login = () => {
    if (localStorage.getItem("access_token")) {
      console.log("logged in");
      setIslogedin(false);
    }
  };

  useEffect(() => {
    check_login();
  }, []);

  if (!islogedin) {
    return <Navigate to="/profile/all-member"></Navigate>;
  }

  return (
    <>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Registaration Successful</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              Your registration on LCCIA portal is successful. we'll review and
              approve profile within 24 hours. you can login once approved.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  // toggleShow()
                  setBasicModal(false);
                  navigate("/login");
                }}
              >
                okay
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <form
        onSubmit={onRegistration}
        onError={(err) => {
          console.log(err);
        }}
      >
        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Business Name*"
              id="formControlLg"
              required
              type="text"
              size="md"
              onChange={(event) => {
                setdata({ ...data, business_name: event.target.value });
              }}
              value={data.business_name}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Website"
              id="formControlLg"
           
              type="url"
              size="md"
              onChange={(event) => {
                setdata({ ...data, url: event.target.value });
              }}
              value={data.url}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          <div className="form-outline flex-fill mb-0">
            <>
              <Select
                isMulti
                placeholder="Business Type*"
                wrapperClass="mb-2 w-100"
                label="Business Type"
                id="formControlLg"
                size="md"
                options={buisness_options}
                onChange={(eve) => {
                  setdata({ ...data, business_type: eve });
                }}
                value={data.business_type ? data.business_type : ""}
              />
            </>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <>
              <Select
                isMulti
                placeholder="Organisation Category*"
                wrapperClass="mb-2 w-100"
                label="Organisation Category*"
                id="formControlLg"
                size="md"
                options={OrganisationCategory}
                onChange={(eve) => {
                  setdata({ ...data, organisation_category: eve });
                }}
                value={
                  data.organisation_category ? data.organisation_category : ""
                }
              />
            </>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <>
              <Select
                isMulti
                placeholder="Legal Status*"
                wrapperClass="mb-2 w-100"
                label="Legal Status*"
                //isRtl
                //label="Gender*"
                id="formControlLg"
                size="md"
                options={legalStatus}
                onChange={(eve) => {
                  setdata({ ...data, legal_status: eve });
                }}
                value={data.legal_status ? data.legal_status : ""}
              />
            </>
          </div>
        </div>

        <div className=" d-flex flex-row alignitems-center  mb-4 w-100 ">
          <TaxanomyCategory onChange={onChangecattemp} />
        </div>

        <div className="d-flex flex-row align-items-center mb-5">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Year of Incorporation"
              id="formControlLg"
              type="date"
              required
              size="md"
              onChange={(eve) => {
                setdata({
                  ...data,
                  Year_of_incorporation: new Date(
                    eve.target.value
                  ).toISOString(),
                });
              }}
              //value={`${data.year_of_incorporation}`.slice(0,10)}
            />
          </div>
        </div>

        <h5 className="mb-4">Authorised Business Contact</h5>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="First Name*"
              id="formControlLg"
              type="text"
              required
              size="md"
              onChange={(eve) => {
                setdata({
                  ...data,
                  first_name: eve.target.value,
                });
              }}
              value={data.first_name}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Middle Name"
              id="formControlLg"
              type="text"
              size="md"
              onChange={(eve) => {
                setdata({
                  ...data,
                  middle: eve.target.value,
                });
              }}
              value={data.middle_name}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-envelope fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Last Name*"
              id="formControlLg"
              type="text"
              required
              size="md"
              onChange={(eve) => {
                setdata({
                  ...data,
                  last_name: eve.target.value,
                });
              }}
              value={data.last_name}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Current City*"
              id="formControlLg"
              type="text"
              required
              size="md"
              onChange={(eve) => {
                setdata({ ...data, current_city: eve.target.value });
              }}
              value={data.current_city}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Email address*"
              id="formControlLg"
              type="email"
              required
              size="md"
              onChange={(eve) => {
                setdata({ ...data, email: eve.target.value });
              }}
              value={data.email}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          {/* <i className="fas fa-lock fa-lg me-3 fa-fw"></i> */}
          <div className="form-outline flex-fill mb-0">
            <MDBInput
              wrapperClass="mb-2 w-100"
              label="Mobile No*"
              id="formControlLg"
              type="number"
              required
              size="md"
              onChange={(eve) => {
                setdata({ ...data, mobile: eve.target.value });
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-4">
          <button
            type="submit"
            className="btn  btn-lg w-100"
            //typeof='submit'

            //onClick={handleSubmit}
            style={{
              backgroundColor: "#F58A2E",
              border: "0px",
              borderRadius: "5px",
              color: "white",
              fontWeight: "500",
            }}
          >
            {!mutation.isLoading ? (
              "Register"
            ) : (
              <MDBSpinner color="light" size="sm" />
            )}
          </button>

        </div>
          {mutation.isError ? (
            <div class="alert alert-danger text-center" role="alert">
              <i class="fa fa-info-circle"></i> Registaration Failed! Try again
            </div>
          ) : (
            ""
          )}
      </form>
    </>
  );
}
