import { useMutation, useQuery } from "react-query";
import { secureAxios } from "../auth";
import { queryClient } from "../query";

export const useCreateLikePost = (onSuccess) => {
  const { data: dataMembers } = useQuery(["like_data"], () =>
    secureAxios
      .get(
        "/items/qd_post?fields=*.*,user_created.profile.*,liked_by.directus_users_id.*"
      )
      .then((res) => res.data)
  );
  return useMutation(
    async (data) => {
      return await secureAxios
        .patch(`/items/qd_post/${data.id}`, data.data)
        .then((res) => res.data);
    },
    {
      onSuccess: onSuccess,
  }
  );
};

export const useDisLikePost = (onSuccess) => {
    const { data: dataMembers } = useQuery(["like_data"], () =>
    secureAxios
      .get(
        "/items/qd_post?fields=*.*,user_created.profile.*,liked_by.directus_users_id.*"
      )
      .then((res) => res.data)
  );
  return useMutation(
    async (data) => {
      return await secureAxios
        .delete(`/items/qd_post_directus_users/${data}`)
        .then((res) => res.data);
    },
    {
      onSuccess: onSuccess,
  }
    
  );
};
