import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { secureAxios } from '../../../_helper/auth'
import CreatePostComponet from './CreatePost/CreatePostComponet'
import PostView from './ViewPost/PostView'

export default function PostSection({img,userCreatedData, isPosted}) {
  const [allposts,setAllPosts] = useState([]);
  const [likeaction,setLikeAction] = useState(null);
  const [dislikeaction,setDisLikeAction] = useState(null);
  const [refresh,setRefresh] = useState(false)
  const {id} = useParams();
  const getData = async() => { 
    await secureAxios.get('/items/qd_post?fields=*.*,user_created.profile.*.*,liked_by.directus_users_id.*,comments.user_created.*,comments.replies.*.*,user_created.role.*&sort[]=-date_created').then((res)=>{
      userCreatedData(res.data)
      setRefresh(false)
      setAllPosts(res.data)
      setLikeAction(false)
      setDisLikeAction(false)
     
    }).catch((err)=>{
      console.log(err)
    })
    
  }
  const islikeaction = (data) => {
  
      setLikeAction(true)
      setDisLikeAction(true)
   
  
    
  }

  const isdislikeaction = (data) => {
  
    setDisLikeAction(true)
      setLikeAction(true)
   
  }

  const isPostedComment = (data) => { 
    setRefresh(data)
    console.log(data)
  }

  console.log(likeaction,dislikeaction)

  useEffect(()=>{
    getData();
  },[likeaction,dislikeaction,refresh,isPosted])
  return (
    <>


    {/* <CreatePostComponet img={img}/> */}
    
    {
      allposts?.map((post,idx)=>(<PostView data={post} islikeaction={islikeaction} isdislikeaction={isdislikeaction} img={img} isPostedComment = {isPostedComment}/>))
    } 
    </>    
    
    
    
  )
}
