import { MDBBreadcrumb, MDBBreadcrumbItem, MDBIcon } from 'mdb-react-ui-kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function MembershipVerification() {
    const navigate = useNavigate();
  return (
    <>
     <MDBBreadcrumb>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Home</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem>
          <a style={{ cursor: "default" }}> Settings</a>
        </MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
          <a style={{ cursor: "default" }}>Membership Approvals</a>
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>

      <button
      onClick={()=>{
        navigate('/profile/membership/upgrade-verification/setup')
      }} 
      style={{
        color:'white',
        backgroundColor:'#F58A2E',
        padding:'5px',
        float:'right',
        border:0,
        borderRadius:'5px'
      }}

      
      >
        <MDBIcon fas icon="tools" className='px-1' />
        Membership Setup
      </button>
    
    </>
  )
}
