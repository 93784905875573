import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { secureAxios } from "../../../../_helper/auth";
import PostView from "../ViewPost/PostView";
import DeletePostModal from "./DeletePostModal";

export default function MyRecentPosts() {
  const [allposts, setAllPosts] = useState([]);
  const getData = async () => {
    await secureAxios
      .get(
        `/items/qd_post?fields=*.*,user_created.profile.*&filter[user_created][_eq]=${localStorage.getItem(
          "me_id"
        )}`
      )
      .then((res) => {
        console.log(res.data);
        setAllPosts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
  
      <MDBContainer className={`m-0 p-0 mb-4`}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" className="m-0 p-0">
            <MDBCard
              className="bg-white m-0 p-0 "
              style={{
                border: "1px solid #e0e0e0",
                boxShadow: "none",
              }}
            >
              <MDBCardBody className="p-2 w-100 d-flex flex-row">
                <div>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      margin:"10px",
                      fontSize: "22px",
                      fontWeight: "500",
                      color: "#545353",
                    }}
                  >
                    My Last Posts
                  </p>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      {allposts.map((post, idx) => (
        <PostView data={post} isEdit={true} />
      ))}
    </>
  );
}
