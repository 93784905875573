import React from "react";
import Avatar from "../../media/avatar2.jpeg";
import "./style/card.css";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBRipple,
} from "mdb-react-ui-kit";

export default function Card({
  name,
  current_city,
  email,
  role,
  img,
  onClick,
  lcciaRole,
  expire_date,
}) {
  var q = new Date();
  var m = q.getMonth() + 1;
  var d = q.getDay();
  var y = q.getFullYear();

  var date = new Date();
  let mydate = new Date(
    `${expire_date === "" ? "" : `${expire_date}`.slice(0, 10)}`
  );

  return (
    <MDBCard
      style={{
        width: "100%",
        margin: "10px",
        cursor: "pointer",
        padding: "15px",
        boxShadow: "none",
        border: "1px solid #e0e0e0",
      }}
      onClick={onClick}
    >
      <MDBRow className="lg-3">
        <MDBCol md="4" className=" text-center">
          <MDBCardImage
            src={img !== " " ? img : Avatar}
            alt="..."
            //fluid

            style={{
              //margin: "5px",
              borderRadius: "5px",
              width: "100%",
            }}
          />
        </MDBCol>
        <MDBCol md="8" className="cdcard">
          <MDBCardBody style={{ padding: "5px" }}>
            <MDBCardTitle>{name}</MDBCardTitle>
            <MDBCardText>
              <MDBIcon
                fas
                icon="location"
                style={{ color: "#F58A2E" }}
                size="sm"
              />{" "}
              {current_city} <br></br>
              <MDBIcon
                fas
                icon="envelope"
                size="sm"
                style={{ color: "#F58A2E" }}
              />{" "}
              {email}
              <br></br>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "28ch ",
                }}
              >
                <MDBIcon
                  fas
                  icon="user"
                  size="sm"
                  style={{ color: "#F58A2E" }}
                />{" "}
                <a>{`${lcciaRole}`}</a>
              </div>
            </MDBCardText>

            <h5>
              {/* <span class="badge " style={{ backgroundColor: "#F58A2E" }}>
                {role}
              </span> */}
              <span
                className="badge mx-3"
                style={{
                  backgroundColor: `${date <= mydate ? `#35bd52` : "#f52222"}`,
                }}
              >
                {date <= mydate ? `Active` : "InActive"}
              </span>
            </h5>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>
  );
}
