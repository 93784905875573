import {
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import Avatar from "../../../media/avatar2.jpeg";
import { getImageURL } from "../../../_helper/image";

export default function BusinessInfo({ AllBusinessData , manage}) {
  console.log(AllBusinessData);
  const businessType = AllBusinessData.business_type;
  const organisationCategory = AllBusinessData.organisation_category;
  const location =
    AllBusinessData.location === undefined ||
    AllBusinessData.location === null ||
    AllBusinessData?.location?.length === 0
      ? {}
      : AllBusinessData?.location[0];
  const allAddress =
    AllBusinessData.address === undefined || AllBusinessData.address === null
      ? []
      : AllBusinessData.address;

  return (
    <>
      <MDBCard shadow={manage === true?"none":""} style={{ margin: `${manage?"0px":"0px"}`,marginTop: "20px", padding: `${manage?"5px":"10px"}` }}>
        <MDBRow sm="12">
          <MDBCol md= {`${manage?"2":"4"}`}>
            <MDBCardImage
              src={
                AllBusinessData.business_logo === undefined || null
                  ? Avatar
                  : getImageURL(AllBusinessData.business_logo)
              }
              alt="..."
              fluid
              style={{ margin: "15px", borderRadius: "5px", width: "auto" }}
              className="bg-image hover-zoom"
            />
          </MDBCol>
          <MDBCol md="8">
            <MDBCardBody>
              <MDBCardTitle>{`${
                AllBusinessData.business_name === undefined
                  ? ""
                  : AllBusinessData.business_name
              }`}</MDBCardTitle>
              <MDBCardText>
                <a
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Business Type
                </a>
                <br></br>
                {businessType?.map((value, idx) => (
                  <MDBBadge
                    tag="a"
                    color="primary"
                    className="mr-2"
                    style={{ marginRight: "2px" }}
                  >
                    {value.label}
                  </MDBBadge>
                ))}

                <br></br>

                <a
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Organisation Category
                </a>
                <br></br>

                {organisationCategory?.map((value, idx) => (
                  <MDBBadge
                    tag="a"
                    color="primary"
                    className="mr-2"
                    style={{ marginRight: "2px" }}
                  >
                    {value.label}
                  </MDBBadge>
                ))}
                <br></br>

                <a
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Business Category
                </a>
                <br></br>

                {AllBusinessData?.categories === undefined
                  ? []
                  : AllBusinessData?.categories.map((value, idx) => (
                      <MDBBadge
                        tag="a"
                        color="primary"
                        className="mr-2"
                        style={{ marginRight: "2px" }}
                      >
                        {value?.category_id?.name}
                      </MDBBadge>
                    ))}
              </MDBCardText>

              {/* <h5><span class="badge bg-primary">{'member'}</span></h5> */}
            </MDBCardBody>
          </MDBCol>

          <p
            className="px-4"
            style={{
              fontSize: "16px",
              fontWeight: "420",
            }}
          >
            {AllBusinessData.business_description}
            <br></br>
            <a
              className=""
              style={{
                fontSize: "14px",
                fontWeight: "420",
                cursor: "pointer",
              }}
              href={`${AllBusinessData.url}`}
            >
              {AllBusinessData.url}
            </a>
          </p>

          {AllBusinessData?.Year_of_incorporation === null ||
          AllBusinessData?.Year_of_incorporation === "" ? (
            ""
          ) : (
            <p
              className="px-4"
              style={{
                fontWeight: 450,
              }}
            >
              <br></br>
              Year of Incorporation
              <MDBBadge
                tag="a"
                color="success"
                style={{
                  marginLeft: "5px",
                }}
              >
                {new Date(
                  `${AllBusinessData?.Year_of_incorporation?.slice(0, 10)}`
                ).toLocaleString("en-us", {
                  month: "short",
                  year: "numeric",
                })}
              </MDBBadge>
            </p>
          )}

          {AllBusinessData.gst === null || AllBusinessData.gst === "" ? (
            ""
          ) : (
            <p
              className="px-4"
              style={{
                fontWeight: 450,
              }}
            >
              <br></br>
              GST No
              <MDBBadge
                tag="a"
                color="success"
                style={{
                  marginLeft: "5px",
                }}
              >
                {AllBusinessData.gst}
              </MDBBadge>
            </p>
          )}

          <p
            className="px-4"
            style={{
              fontWeight: 450,
            }}
          >
            No of Employee
            <MDBBadge
              tag="a"
              color="success"
              style={{
                marginLeft: "5px",
              }}
            >
              {" "}
              {AllBusinessData.no_of_employee}
            </MDBBadge>
          </p>

          <table>
            <tr>
              <td> </td>
            </tr>
            <tr>
              <td className={"pb-2 px-4"}>
                <p><span style={{
                fontWeight: 450,
              }}>Head Office: </span>

              {location === {} ? (
                ""
              ) : (
               <span>
                  {location.street_no_1}
                  <br></br> {location.street_no_2?`${location.street_no_2},`:""}<br></br>
                  {location.city?`${location.city},`:''}{location.pincode}
                  <br></br>
                  {location.state} {location.country}
                  </span>
              )}
              </p>
              </td>
            </tr>

            <tr>
              <td className={"pb-3 px-4"} >
                <p>
                  <span style={{fontWeight:450}}>
                Address:
                </span>
              {allAddress.length===0?[]:allAddress.map((value, idx) => (
                
                
                <span>
                    {` ${value.street_no_1}`}<br></br> {value.street_no_2 === undefined?"":`${value.street_no_2},`}<br></br>
                    {value.city},{value.pincode}
                    <br></br>
                    {value.state} {value.country}
                 </span>
                  
                  
                  ))}
                  </p>
               </td>
            </tr>
          </table>
        </MDBRow>
      </MDBCard>
    </>
  );
}
