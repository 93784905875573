import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import DocAvatar from "../../../media/document_avatar.png";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  useDocumentVerification,
  usePostDocument,
  useUpdateChapter,
  useUpdateDocument,
  useUpdateReference,
} from "../../../_helper/ApplyMembershipAction";
import { secureAxios } from "../../../_helper/auth";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import { getImageURL } from "../../../_helper/image";
import "./style.css";

export default function ApplyMembership() {
  const navigate = useNavigate();
  const [avatar, setAvtar] = useState("");
  const [id, setid] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [currid, setcurrid] = useState("");

  const [doctypedata, setDocTypeData] = useState([]);
  const [chapterdata, setchapterData] = useState([]);
  const [tag_data, setTagData] = useState([]);
  const [gettageduser, setTaggedUser] = useState([]);
  const [getdocdata, setGetdocData] = useState([]);
  const [getreferenceslst, setGetReferencelst] = useState([]);
  const [getchapter, setgetchapter] = useState({});
  const [getdocid, setGetdocid] = useState([]);

  const [currtag, setCurrTag] = useState("");
  const [name, setName] = useState("");
  const [idx, setidx] = useState("");
  const [emailvalue, setEmailvalue] = useState({});
  const [docimg, setDocImg] = useState({});
  const [docimgid, setDocimgID] = useState({});
  const [docimgstatusmain, seDdocImgStatusMain] = useState({});
  const [reflocaldata, setReflocalData] = useState([]);
  const [allreferencedata, setAllReferenceData] = useState({});
  const [executiveRef, setExecutiveRef] = useState(null);
  const [zonalRef, setZonalRef] = useState(null);

  const [getdocumentdatastatus, setgetdocumentdatastatus] = useState(false);
  const [getdocumenttypestatus, setgetdocumenttypestatus] = useState(false);
  const [getrefdatastatus, setgetrefdatastaus] = useState(false);
  const [getrefdatatagstatus, setgetrefdatatagstatus] = useState(false);

  const [btnstatus, setBtnStatus] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const docimg_tem = {};
  const docimgid_tem = {};
  const docimgstatus = {};

  // console.log(currtag);
  const GetDocumentsId = async () => {
    await secureAxios
      .get(`/users/me/?fields=profile.verification_docs`)
      .then((res) => {
        //console.log(res.data.profile[0].verification_docs)
        setGetdocid(res.data?.profile[0]?.verification_docs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetDocuments = async () => {
    await secureAxios
      .get(`/users/me/?fields=profile.verification_docs.*`)
      .then((res) => {
        const data_raw = res.data.profile[0];
        const doc_length = data_raw.verification_docs;

        setGetdocData(doc_length);
        //setGetdocid([ ... getdocid,res.data.profile[0].verification_docs.id])
        setgetdocumentdatastatus(true);

        //console.log(data_raw.verification_docs)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(getdocid);

  // All Document Data ----> getdocdata
  // All Document type data ----------> doctype
  const GetDocumentData = () => {
    // return
    if (getdocumentdatastatus && getdocumenttypestatus) {
      doctypedata?.map((item, idx) =>
        getdocdata?.map((docname, idx1) =>
          item.type === docname.type &&
          (docimg_tem["doc" + idx] = docname?.file)
            ? (docimgid_tem["id" + idx] = docname.id)
              ? (docimgstatus["status" + idx] = docname.status)
              : ""
            : ""
        )
      );

      setDocImg(docimg_tem);
      setDocimgID(docimgid_tem);
      seDdocImgStatusMain(docimgstatus);
      console.log(docimgstatus);
    } else {
    }
  };

  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    managedocs(idx, data.id);
    //setAvtar(data.id);
    //console.log(data.id);
  });

  const doc_img_postmutation = usePostDocument();
  const doc_img_updatemutation = useUpdateDocument(docimgid["id" + idx]);

  const managedocs = (idx, avatar1) => {
    setDocImg({ ...docimg, ["doc" + idx]: avatar1 });
    const data = {
      type: name,
      file: avatar1,
      profile: localStorage.getItem("user_id"),
    };

    const updated_data = {
      file: avatar1,
    };

    if (docimgid["id" + idx]) {
      if (avatar !== null) {
        // console.log('deleted')
        //console.log(avatar);
        fileMutationdelete.mutate(avatar);
      }
      // console.log('Update Fuction run')

      doc_img_updatemutation.mutate(updated_data);
      setAvtar(null);
    } else {
      // console.log(data)
      // console.log('Post Function run')
      doc_img_postmutation.mutate(data, {
        onSuccess: (data) => {
          setGetdocid([...getdocid, data.id]);
        },
      });
      setAvtar(null);
    }
  };

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onFile = (eve) => {
    console.log(name);
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();

        formData.append("file", file);

        fileMutation.mutate(formData);
      }
    }
  };

  const GetDocType = async () => {
    await secureAxios
      .get(
        `items/document_type?filter[status][_eq]=true&filter[category][_eq]=${
          localStorage.getItem("gender") == "null"
            ? "organisation"
            : "individual"
        }`
      )
      .then((res) => {
        setDocTypeData(res.data);
        // console.log(res.data);
        setgetdocumenttypestatus(true);
        //setStatusFlag({...statusflag,"getdocumenttype":true})
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetChapterName = () => {
    secureAxios
      .get("items/chapters?filter[status][_eq]=true")
      .then((res) => {
        setchapterData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetTagsData = async () => {
    await secureAxios
      .get("/items/tags?filter[status][_eq]=true")
      .then((res) => {
        setTagData(res.data);
        GetTaggedUserData(res.data);
        setgetrefdatatagstatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetTaggedUserData = async (tag_data) => {
    await secureAxios
      .get(
        `/items/profile?fields=first_name,last_name,id,email,tag&filter[tag][_eq]=${tag_data}`
      )
      .then((res) => {
        setTaggedUser([res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChapterData = async () => {
    await secureAxios
      .get(`/items/profile/${localStorage.getItem("user_id")}?fields=chapter.*`)
      .then((res) => {
        setgetchapter({
          label: res.data?.chapter?.name,
          value: res.data?.chapter?.id,
          id: res.data?.chapter?.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetReferenceData = async () => {
    await secureAxios
      .get(
        `/users/?fields=email,profile.received_references.*,profile.received_references.referred_by.*&&filter[profile][id][_eq]=${localStorage.getItem(
          "user_id"
        )}`
      )
      .then((res) => {
        console.log(res.data[0].profile[0]);
        setGetReferencelst(res.data[0]?.profile[0]?.received_references);
        setgetrefdatastaus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tempreflst = [];
  const GetReferenceMemebers = () => {
    const arr = tag_data.map((item, idx) =>
      getreferenceslst?.map((element, idx1) =>
        element?.referred_by?.tag === "Zonal"
          ? setZonalRef({
              label: `${element.referred_by.first_name}${element.referred_by.last_name} (${element.referred_by.email})`,
              value: `${element.referred_by.email}`,
              prefill: true,
              id: element.referred_by.id,
            })
          : setExecutiveRef({
              label: `${element.referred_by.first_name}${element.referred_by.last_name} (${element.referred_by.email})`,
              value: `${element.referred_by.email}`,
              id: element.referred_by.id,
              prefill: true,
            })
      )
    );

    console.log(tempreflst);
    setReflocalData(tempreflst);
  };

  //console.log(gettageduser[0]);

  useEffect(() => {
    if (getdocumenttypestatus && getdocumentdatastatus) {
      GetDocumentData();
    } else {
      GetDocuments();
      GetDocumentsId();
      GetDocType();
      GetChapterName();
      GetTagsData();
      getChapterData();
      GetReferenceData();
    }

    if (getrefdatatagstatus === true && getrefdatastatus === true) {
      GetReferenceMemebers();
    }
  }, [
    getdocumentdatastatus,
    getdocumenttypestatus,
    getrefdatatagstatus,
    getrefdatastatus,
  ]);

  const chapter_arr = chapterdata?.map((item, idx) =>
    item.name
      ? {
          label: item.name,
          value: item.name,
          id: item.id,
        }
      : {}
  );

  const options_arr = gettageduser[0]?.map((item, idx) =>
    currtag === item.tag
      ? {
          label: `${item.first_name} ${item.last_name} (${item.email})`,
          value: item.email,
          referred_by: item.id,
          referent: localStorage.getItem("user_id"),
          id: item.id,
        }
      : { label: null, value: null, isdisabled: true }
  );

  const updateChaptermutation = useUpdateChapter(
    localStorage.getItem("user_id")
  );

  const OnChangeChapter = (event) => {
    setgetchapter({ label: event.label, value: event.value, id: event.id });
    updateChaptermutation.mutate({ chapter: event.id });
  };

  const updateuserReference = useUpdateReference();

  const OnChangeZonalReference = (event) => {
    const data = {
      label: event.label,
      referred_by: event.id,
      referent: localStorage.getItem("user_id"),
      value: event.value,
    };

    setZonalRef(data);
  };

  const OnChangeExecutiveReference = (event) => {
    // console.log("Event-ON", event);
    const data = {
      label: event.label,
      referred_by: event.id,
      referent: localStorage.getItem("user_id"),
      value: event.value,
    };

    setExecutiveRef(data);
  };

  // console.log("Executive REFERE", executiveRef);

  // console.log("All Refrence", allreferencedata);
  const SubmitExecutiveReferenceData = () => {
    updateuserReference.mutate(executiveRef, {
      onSuccess: () => {
        setIsButtonDisabled(false);
      },
    });
  };

  const SubmitZonalReferenceData = () => {
    updateuserReference.mutate(zonalRef, {
      onSuccess: () => {
        setIsButtonDisabled(false);
      },
    });
  };

  const mutation = useDocumentVerification();
  const OnAdminSubmit = () => {
    mutation.mutate({ keys: getdocid, data: { status: "approve" } });
  };

  // console.log(docimgstatus);
  // console.log("Lcocal DAta", reflocaldata);

  console.log(getdocid);

  return (
    <>
      <h4 style={{ marginLeft: "12px" }}>Apply For Verification</h4>
      <MDBContainer fluid className="px-2">
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard className="bg-white my-2 ">
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <div className="">
                  <h5 className="mt-4 mb-3">Upload Your Documents</h5>
                  <MDBContainer fluid style={{ marginBottom: "30px" }}>
                    <MDBRow>
                      {doctypedata?.map((ele, iidx) =>
                        docimgstatusmain["status" + iidx] === "approve" ? (
                          <p
                            style={{ color: "red" }}
                          >{`Your ${ele.type} is under verification`}</p>
                        ) : (
                          <>
                            <MDBCol sm={4}>
                              <div className="text-center">
                                {localStorage.getItem("gender") == "null" ? (
                                  ""
                                ) : (
                                  <img
                                    src={
                                      docimg["doc" + iidx] !== undefined
                                        ? getImageURL(docimg["doc" + iidx])
                                        : DocAvatar
                                    }
                                    className="preview-img mt-4 text-center"
                                    alt="preview"
                                    style={{ width: "200px" }}
                                  />
                                )}
                                <div className="mt-3">
                                  <button
                                    className="mb-2 "
                                    onClick={(eve) => {
                                      setAvtar(docimg["doc" + iidx]);
                                      setidx(iidx);
                                      setName(ele.type);
                                      handleClick(eve);
                                    }}
                                    style={{
                                      width: "80%",
                                      padding: "7px",
                                      borderColor: `${
                                        docimgstatusmain["status" + iidx] ===
                                        "approved"
                                          ? "green"
                                          : docimgstatusmain[
                                              "status" + iidx
                                            ] === "rejected"
                                          ? "red"
                                          : "#1b5ff2"
                                      }`,
                                      color: `${
                                        docimgstatusmain["status" + iidx] ===
                                        "approved"
                                          ? "green"
                                          : docimgstatusmain[
                                              "status" + iidx
                                            ] === "rejected"
                                          ? "red"
                                          : "#1b5ff2"
                                      }`,
                                      backgroundColor: "#ffff",
                                      borderRadius: "5px",
                                    }}
                                    status={fileMutation.status}
                                    disabled={
                                      docimgstatusmain["status" + iidx] ===
                                      "approved"
                                        ? true
                                        : false
                                    }
                                  >
                                    {" "}
                                    {docimgstatusmain["status" + iidx] ===
                                    "approved" ? (
                                      <MDBIcon
                                        fas
                                        icon="check-circle"
                                        className="px-1"
                                      />
                                    ) : (
                                      <MDBIcon
                                        fas
                                        icon="upload"
                                        className="px-1"
                                      />
                                    )}
                                    {fileMutation.status === "loading"
                                      ? docimgid["id" + iidx] ===
                                        docimgid["id" + idx]
                                        ? "Uploading ..."
                                        : docimgstatusmain["status" + iidx] ===
                                          "approved"
                                        ? "Approved"
                                        : `Change ${ele.type}`
                                      : docimg["doc" + iidx]
                                      ? docimgstatusmain["status" + iidx] ===
                                        "approved"
                                        ? "Approved"
                                        : `Change ${ele.type}`
                                      : `Upload Your ${ele.type}`}
                                  </button>
                                  <input
                                    ref={hiddenFileInput}
                                    onChange={onFile}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept={
                                      localStorage.getItem("gender") == "null"
                                        ? ".png, .jpeg, .jpg"
                                        : ".png, .jpg, .jpeg"
                                    }
                                  />
                                </div>
                              </div>
                            </MDBCol>
                          </>
                        )
                      )}
                    </MDBRow>
                  </MDBContainer>
                  <h5 className="mt-4 mb-3">Select Chapter* </h5>

                  <div className="d-flex flex-row align-items-center mt-4">
                    <Select
                      className="w-100"
                      placeholder={"Select Chapter"}
                      options={chapter_arr}
                      onChange={OnChangeChapter}
                      value={getchapter}
                      isSearchable
                    />
                  </div>
                  <h5 className="mt-4 mb-3">Select References* </h5>

                  <div className="d-flex flex-row align-items-center mt-4">
                    <Select
                      className="w-100"
                      onFocus={() => {
                        setBtnStatus(null);
                        setBtnStatus("Executive");
                        GetTaggedUserData("Executive");
                        setCurrTag("Executive");
                      }}
                      placeholder={`Executive Committee Reference`}
                      options={options_arr}
                      isSearchable
                      onChange={(event) => {
                        OnChangeExecutiveReference(event);
                      }}
                      value={executiveRef}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center mt-4">
                    <Select
                      className="w-100"
                      onFocus={() => {
                        setBtnStatus("Zonal");
                        GetTaggedUserData("Zonal");
                        setCurrTag("Zonal");
                      }}
                      placeholder={`Zonal Committee Reference`}
                      options={options_arr}
                      isSearchable
                      onChange={(event) => {
                        OnChangeZonalReference(event);
                      }}
                      value={zonalRef}
                    />
                  </div>

                  <button
                    className="mt-5 py-1"
                    style={{
                      width: "auto",
                      padding: "10px",
                      color: "white",
                      backgroundColor: "#1b5ff2",
                      borderRadius: "5px",
                      border: "none",
                      opacity:
                        !zonalRef ||
                        !executiveRef ||
                        !getchapter ||
                        zonalRef?.prefill ||
                        getdocid.length === 0
                          ? "0.5"
                          : "1",
                    }}
                    disabled={
                      !zonalRef ||
                      !executiveRef ||
                      !getchapter ||
                      zonalRef?.prefill ||
                      getdocid?.length === 0
                    }
                    onClick={() => {
                      SubmitExecutiveReferenceData();
                      SubmitZonalReferenceData();
                      OnAdminSubmit();
                    }}
                  >
                    {mutation.isLoading ? (
                      <MDBSpinner color="light" size="sm" />
                    ) : (
                      "Send it for Admin Approval"
                    )}
                  </button>

                  {zonalRef?.prefill && (
                    <p
                      style={{
                        color: "orange",
                      }}
                    >
                      Document Verification is under review.{" "}
                    </p>
                  )}
                  {mutation.isSuccess
                    ? navigate("/profile/upgrade-membership")
                    : ""}
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
