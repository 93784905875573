import React, { useEffect, useState } from 'react'
import { MDBInput, MDBIcon, MDBModal,MDBModalBody, MDBModalFooter, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBTextArea } from 'mdb-react-ui-kit';
import '../style/Datetime.css';


export default function AwardsModel({ awshow, awsetShow, sentAwardData, reciveData, onChangeAwardData }) {
    const [Title, setTitle] = useState("");
    const [AssociatedWith, setAssociatedWith] = useState("");
    const [IssuedBy, setIssuedBy] = useState("");
    const [IssueDate, setIssueDate] = useState("");
    const [Description, setDescription] = useState("");
   

    //console.log(reciveData)



    const handleTitle = (eve) => {
        setTitle(eve.target.value);
        onChangeAwardData({
            "title": eve.target.value,
            "in_associated_with": reciveData.in_associated_with,
            "issued_by_org": reciveData.issued_by_org,
            "issued_date": reciveData.issued_date,
            "description":reciveData.description,
            
        })

    }

    const handleAssociatedWith = (eve) => {
        setAssociatedWith(eve.target.value);
        onChangeAwardData({
            "title": reciveData.title,
            "in_associated_with": eve.target.value,
            "issued_by_org": reciveData.issued_by_org,
            "issued_date": reciveData.issued_date,
            "description":reciveData.description,
            
        })

    }

    const handleIssuedBy = (event) => {
        setIssuedBy(event.target.value)
        onChangeAwardData({
            "title": reciveData.title,
            "in_associated_with": reciveData.in_associated_with,
            "issued_by_org":event.target.value,
           
            "issued_date": reciveData.issued_date,
            "description":reciveData.description,
            
        })
        
    };




    const handleIssueDate = (event) => {
        setIssueDate(new Date(event.target.value).toISOString());
        onChangeAwardData({
            "title": reciveData.title,
            "in_associated_with": reciveData.in_associated_with,
            "issued_by_org": reciveData.issued_by_org,
            "issued_date": new Date(event.target.value).toISOString(),
            "description":reciveData.description,
            
        })

    }

    const handleDescription = (eve) => {
        setDescription(eve.target.value);
        onChangeAwardData({
            "title": reciveData.title,
            "in_associated_with": reciveData.in_associated_with,
            "issued_by_org": reciveData.issued_by_org,
           
            "issued_date": reciveData.issued_date,
            "description":eve.target.value,
            
        })

    }




    const AwardData = {
        "title": Title,
        "in_associated_with": AssociatedWith,
        "issued_by_org": IssuedBy,
        "issued_date": IssueDate,
        "description":Description,
     

    }

    
    const sent = () => {

        sentAwardData(AwardData)
        setTitle('');
        setAssociatedWith('');
        setIssuedBy('');
        setIssueDate('');
        setDescription('');
        awsetShow(!awshow)
    }






    return (
        <>

            <MDBModal show={awshow} setShow={awsetShow} tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Add Awards and honurs</MDBModalTitle>
                            <button
                                className='btn-close'
                                color='none'
                                onClick={() => awsetShow(!awshow)
                                }
                                X
                            ></button>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Title'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleTitle}
                                    value={`${reciveData.title}`}
                                    autoComplete='off'
                                    autoFocus
                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Associated With'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleAssociatedWith}
                                    value={`${reciveData.in_associated_with}`}
                                    autoComplete='off'

                                />
                            </div>
                            <div className="d-flex flex-row alignitems-center mb-3">
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Issued By'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleIssuedBy}
                                    value={`${reciveData.issued_by_org}`}
                                    autoComplete='off'

                                />
                            </div>

                       

                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    label='Issue Date'
                                    id='formControlLg'
                                    type='date'
                                    size="md"
                                    onChange={handleIssueDate}
                                    value={`${reciveData.issued_date=== undefined ? {} : reciveData.issued_date.slice(0, 10)}`}
                                    autoComplete='off'

                                />
                            </div>

                            
                            <div className="d-flex flex-row alignitems-center mb-3">
                            <MDBTextArea
                                    wrapperClass='mb-4 w-100'
                                    label='Description'
                                    id='formControlLg'
                                    type='text'
                                    size="md"
                                    onChange={handleDescription}
                                    value={`${reciveData.description}`}
                                    autoComplete='off'

                                />
                            </div>


                        </MDBModalBody>
                        <MDBModalFooter>


                            < button className='mt-4 py-1'
                                style={{ width: "8rem", color: "white", backgroundColor: "#1b5ff2", borderRadius: "5px", border: 'none' }}
                                onClick = {
                                    sent
                                }
                            >

                                <MDBIcon fas icon='save' className='px-1' />
                                Save
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>




        </>
    )
}
