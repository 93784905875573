import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import SharePost from './SharePost';

export default function ShareModel({isopen,setIsOpen,post_id}) {
  console.log(post_id)
  
  return (
    <>
      
      <MDBModal show={isopen} setShow={setIsOpen} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>Share Post</MDBModalTitle>
            </MDBModalHeader>
           
            <MDBModalBody>
                <SharePost post_id={post_id}/>
            </MDBModalBody>


          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}