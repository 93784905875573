import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { getImageURL } from "../../../../_helper/image";
import { secureAxios } from "../../../../_helper/auth";
import { useDocumentVerificationById } from "../../../../_helper/ApplyMembershipAction";

export default function ViewDocumentModal({ vshow, setvshow, type, file,id,status }) {
  console.log(id)

  const ApproveMutation = useDocumentVerificationById(id);
  const OnApproved = () => {
      ApproveMutation.mutate({status:"approved"})
      status(true)
      setvshow(!vshow);
  }

  const RejectMutation = useDocumentVerificationById(id);
  const OnRejected = () => {
    RejectMutation.mutate({status:"rejected"})
    status(true)
    setvshow(!vshow);
  }

  return (
    <>
      <MDBModal staticBackdrop tabIndex="-1" show={vshow} setShow={setvshow}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{type}</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => {
                  setvshow(!vshow);
                }}
              ></button>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <img  style={{
                width: "-webkit-fill-available"
              }} src={`${getImageURL(file)}`} />
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="px-2 py-1"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  border: 0,
                }}
                onClick={OnRejected}
              >
                Reject
              </button>
              <button
                className="px-2 py-1 mx-2"
                style={{
                  color: "white",
                  backgroundColor: "green",
                  border: 0,
                }}
                onClick = {OnApproved}
              >
                Approve
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
