import React, { useEffect, useState } from "react";
import {
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalPatentTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../style/Datetime.css";
import { getImageURL } from "../../../_helper/image";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import DocumentImg from "../../../media/document_avatar.png";

export default function PatentsModel({
  Pshow,
  PsetShow,
  sentPatentData,
  reciveData,
  onChangePatentData,
}) {
  const [PatentTitle, setPatentTitle] = useState("");
  const [ApplicationNumber, setApplicationNumber] = useState("");
  const [InventorName, setInventorName] = useState("");
  const [Status, setStatus] = useState("");
  const [IssueDate, setIssueDate] = useState("");
  const [Description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [doc_img, setDocImg] = useState("");
  const hiddenFileInput = React.useRef(null);

  //console.log(reciveData)
  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: reciveData.URL,
      doc_img: data.id,
    });

    setDocImg(data.id);
    console.log(data.id);
  });

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (doc_img !== "") {
          console.log(doc_img);
          fileMutationdelete.mutate(doc_img);
        }

        fileMutation.mutate(formData);
      }
    }
  };

  const handlePatentTitle = (eve) => {
    setPatentTitle(eve.target.value);
    onChangePatentData({
      patent_title: eve.target.value,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleApplicationNumber = (eve) => {
    setApplicationNumber(eve.target.value);
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: eve.target.value,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleInventorName = (event) => {
    setInventorName(event.target.value);
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: event.target.value,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: event.target.value,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleIssueDate = (event) => {
    setIssueDate(new Date(event.target.value).toISOString());
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: new Date(event.target.value).toISOString(),
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleDescription = (eve) => {
    setDescription(eve.target.value);
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: eve.target.value,
      URL: reciveData.URL,
    });
  };

  const handleURL = (eve) => {
    setUrl(eve.target.value);
    onChangePatentData({
      patent_title: reciveData.patent_title,
      patent_or_application_number: reciveData.patent_or_application_number,
      inventor_name: reciveData.inventor_name,
      status: reciveData.status,
      issue_date: reciveData.issue_date,
      description: reciveData.description,
      URL: eve.target.value,
    });
  };

  const InitiativeData = {
    patent_title: PatentTitle,
    patent_or_application_number: ApplicationNumber,
    inventor_name: InventorName,
    status: Status,
    issue_date: IssueDate,
    description: Description,
    doc_img: doc_img,
    URL: url,
  };

  const sent = () => {
    sentPatentData(InitiativeData);
    setPatentTitle("");
    setApplicationNumber("");
    setInventorName("");
    setStatus("");
    setIssueDate("");
    setDescription("");
    setUrl("");
    setDocImg("");
    PsetShow(!Pshow);
  };

  return (
    <>
      <MDBModal show={Pshow} setShow={PsetShow} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Patents</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => PsetShow(!Pshow)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              {
                <img
                  src={
                    reciveData.doc_img
                      ? `${getImageURL(reciveData.doc_img)}`
                      : DocumentImg
                  }
                  className="preview-img"
                  alt="preview"
                  style={{ width: "150px", marginLeft: "18px" }}
                />
              }
              <div className="d-flex flex-row alignitems-center mb-4 mt-3">
                <button
                  className="mb-2"
                  onClick={handleClick}
                  style={{
                    width: "auto",
                    padding: "7px",
                    borderColor: "#1b5ff2",
                    color: "#1b5ff2",
                    backgroundColor: "#ffff",
                    borderRadius: "5px",
                  }}
                  status={fileMutation.status}
                  disabled={fileMutation.isLoading}
                >
                  {" "}
                  <MDBIcon fas icon="upload" className="px-1" />
                  {fileMutation.status === "loading"
                    ? "Uploading Image..."
                    : reciveData.doc_img
                    ? "Change Patent Image"
                    : "Your Patent Image"}
                </button>
                <input
                  ref={hiddenFileInput}
                  onChange={onFile}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Patent Title"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handlePatentTitle}
                  value={`${reciveData.patent_title}`}
                  autoComplete="off"
                  autoFocus
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Application Number"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleApplicationNumber}
                  value={`${reciveData.patent_or_application_number}`}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Inventor Name"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleInventorName}
                  value={`${reciveData.inventor_name}`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Status"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleStatus}
                  value={`${reciveData.status}`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Issue Date"
                  id="formControlLg"
                  type="date"
                  size="md"
                  onChange={handleIssueDate}
                  value={`${
                    reciveData.issue_date === undefined
                      ? {}
                      : reciveData.issue_date.slice(0, 10)
                  }`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBTextArea
                  wrapperClass="mb-4 w-100"
                  label="Description"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleDescription}
                  value={`${reciveData.description}`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="URL"
                  id="formControlLg"
                  type="url"
                  size="md"
                  onChange={handleURL}
                  value={`${reciveData.URL}`}
                  autoComplete="off"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={sent}
              >
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
