import React from "react";

export default function ContactInformation({ email, phone, address }) {
  console.log(address);

  return (
    <>
      <div>
        <h4
          style={{
            marginTop: "20px",
            fontWeight: "500",
            color: "#8A8A8A",
          }}
        >
          Contact Information
        </h4>

        <div>
          <table
            style={{
              marginTop: "15px",
              width: "-webkit-fill-available",
            }}
          >
            <tr>
              <th></th>
              <th></th>
            </tr>

            <tr>
              <td
                style={{
                  width: "30%",
                  fontSize: "14px",
                  fontWeight: "450"
                }}
              >
                Email
              </td>
              <td style={{ fontSize: "14px",
                  fontWeight: "450"}}>{email}</td>
            </tr>
            <tr
              style={{
                marginTop: "5px",
              }}
            >
              <td
                style={{
                  width: "30%",
                  paddingTop: "20px",
                  fontSize: "14px",
                  fontWeight: "450"
                }}
              >
                Phone
              </td>
              <td style={{ fontSize: "14px",
               paddingTop: "20px",
                  fontWeight: "450"}}>+91{phone}</td>
            </tr>

            <tr>
              <td
                style={{
                  width: "30%",
                  paddingTop: "30px",
                  fontSize: "14px",
                  fontWeight: "450",
                }}
              >
                Address
              </td>
              <td style={{ fontSize: "14px",
              paddingTop: "20px",
                  fontWeight: "450"}}>
                {`${address.length === 0 ? "" : address[0]?.street_no_1} ${
                  address.length === 0 ? "" : address[0]?.street_no_2
                }`}
                ,<br></br>
                {`${address.length === 0 ? "" : address[0]?.city}, ${
                  address.length === 0 ? "" : address[0]?.pincode
                }`}
                <br></br>
                {`${address.length === 0 ? "" : address[0]?.state} ${
                  address.length === 0 ? "" : address[0]?.country
                }`}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
}
