import React, { useState } from "react";
import { Link } from "react-router-dom";
import BrandLogo from "../../media/logo.png";
import SignupTabs from "./SignupTabs";
import { MDBCheckbox } from "mdb-react-ui-kit";
import IndividualRegistration from "./IndividualRegistration";
import OrganisationRegistration from "./OrganisationRegistration";
export default function Signup() {
  const [showorganisation, setShowOrganisation] = useState(false);
  const [showindividual, setShowIndividual] = useState(false);

  return (
    <>
      <section className="vh-100" style={{ backgroundColor: "#fffff" }}>
        <div className="container h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-lg-12 col-xl-11">
              <div className="card text-black" style={{ borderRadius: "25px" }}>
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div
                      className={`${
                        !showindividual && !showorganisation
                          ? "text-left col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1"
                          : "col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1"
                      }`}
                    >
                      <p
                        className={`${
                          !showindividual && !showorganisation
                            ? "text-left"
                            : "text-center mx-md-4"
                        } h3 fw-bold mb-5 mx-1  mt-4`}
                      >
                        Become a LCCIA Member
                      </p>
                      {/* <div className="">
                      <SignupTabs/>
                      </div> */}
                      {!showindividual && !showorganisation ? (
                        <>
                          <div className="mb-5">
                            <p className="">How do you want to register? </p>
                            <MDBCheckbox
                              name="flexCheck"
                              value=""
                              id="flexCheckDefault"
                              label="Register as Individual"
                              onChange={(eve) => {
                                setShowIndividual(eve.target.checked);
                              }}
                            />
                            <MDBCheckbox
                              name="flexCheck"
                              value=""
                              id="flexCheckDefault"
                              label="Register as Organistation"
                              onChange={(eve) => {
                                setShowOrganisation(eve.target.checked);
                              }}
                            />
                          </div>
                        </>
                      ) : showindividual && !showorganisation ? (
                        <IndividualRegistration />
                      ) : (
                        <OrganisationRegistration />
                      )}
                      <p
                        className={
                          !showindividual && !showorganisation
                            ? "text-left"
                            : "text-center"
                        }
                      >
                        Already a member?{" "}
                        <Link to={`/login`}>
                          <a className="link-info">Login</a>
                        </Link>
                      </p>
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                      <img
                        style={{
                          margin: "auto",
                        }}
                        src={BrandLogo}
                        className="img-fluid"
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
