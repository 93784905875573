import {
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle,
    MDBTextArea,
  } from "mdb-react-ui-kit";
  import React, { useState } from "react";
  import Select from 'react-select';
  import { usePostTags } from "../../../_helper/MembershipSetupAction";
  
  export default function AddTagModel({ opentagmodal, setopentagmodal , onChange, value={},status }) {
      const [tag_data,settagData] = useState({});
  
      const mutation = usePostTags((data)=>{console.log('done')})
      const sent = () =>{
          status(true);
          mutation.mutate(value);
          setopentagmodal(!opentagmodal);
      }
  
      //console.log(value)
     
      //console.log(doctype_data)
    return (
      <MDBModal show={opentagmodal} setShow={setopentagmodal} tabIndex="-1">
        <MDBModalDialog scrollable>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Tag</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => {
  
                
                  settagData({})
                  setopentagmodal(!opentagmodal)}
                }
                  X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Name"
                  id="formControlLg"
                  type="text"
                  size="md"
                  autoComplete="off"
                  onChange={(event)=>{
                      //setdocTypeData(value)
  
                      //setdocTypeData({...doctype_data,type:event.target.value})
                      onChange({...value,name:event.target.value})
                  }}
                  value = {value.name === undefined ? "" :value.name} 
                />
              </div>
              <div className="d-flex flex-row alignitems-center ">
                <MDBTextArea
                  wrapperClass="mb-4 w-100"
                  label="Description"
                  id="formControlLg"
                  type="text"
                  size="md"
                  rows={3}
                  autoComplete="off"
                  onChange={(event) => {
                      //setdocTypeData(value)
  
                      //setdocTypeData({...doctype_data,description:event.target.value})
                      onChange({...value,description:event.target.value})
                  }}
                 value={value.description === undefined ? "" :value.description}
                />
              </div>
  
              <div className="d-flex flex-row alignitems-center ">
                  <Select
                  placeholder="Status"
                  isSearchable={false}
                  options={[{label:"Active",value:"0"},{label:'InActive',value:1}]}
                  className="w-100 mb-4"
                  onChange={(event)=>{
                      //setdocTypeData(value)
  
                      //setdocTypeData({...doctype_data,status:event.label==='Active'?true:false})
                      onChange({...value,status:event.label==='Active'?true:false})
                  }}
                  value={value.status === undefined || value.status === null ? "" :value.label}
                  
                  />
              </div>
  
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={sent}
                // onClick={SubmitEditedData}
              >
                {/* {editmutation.isLoading ? (
                <MDBSpinner color="light" size="sm" />
              ) : (
                <>
                  <MDBIcon fas icon="save" className="px-1" />
                  Save
                </>
              )} */}
                Save
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
  