import React from 'react'
import CreateMembershipForm from './CreateMembershipForm'

export default function MembershipForm() {
  return (
   <>
         <div className="container">
        <h4
          style={{
            fontSize: "22px",
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          Create Membership Plan
        </h4>
        <CreateMembershipForm/>
      </div>
   </>
  )
}
