import { MDBIcon } from 'mdb-react-ui-kit';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import EditMemberShipTable from './EditMembershipTable'

export default function EditMembership() {
  const navigate = useNavigate();
  return (
    <>
    <div className='container' style={{marginBottom:'20px',marginTop:'10px'}}>
      <div className='row'>
        <div className='col-md-6'>
     <h4 style={{
        fontSize: "22px",
        marginTop: "20px",
       
        marginLeft:'20px'
        
      }}>
        Edit Membership Plan
      </h4>
      </div>
      <div className='col-md-6'>
        <button className = "px-4 py-1"
        onClick={()=>{
          navigate('/profile/create-membership')
        }} 
        style={{
            backgroundColor:"#435799",
            color:'white',
            border:0,
            float:'right',
            marginBottom:'10px',
            borderRadius:'5px',
            
        }}>
          <MDBIcon icon='add' size='sm' className='px-1'/>
          Add Plan
        </button>

      </div>
      
      </div>
      
      </div>

<div style={{ height:'30rem',overflow:'auto',marginRight:'-17px',paddingLeft:'10px'}} className='container hide-scroll'>
      <EditMemberShipTable/>
      </div>

    


    </>
  )
}
