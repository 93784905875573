import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Avatar from "../../../media/avatar2.jpeg";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import { getImageURL } from "../../../_helper/image";

export default function ImageCropper({
  openModel,
  setOpenModel,
  avatar_data,
  onChange1,
}) {
  const imgRef = useRef(null);
  const [inSrc, setInSrc] = useState();
  const [crop, setCrop] = useState();
  const [out, setOut] = useState();
  const [avatar, setAvtar] = useState("");
  const [msg, setmsg] = useState();
  const [btnstatus, setbtnstatus] = useState(true);
  const hiddenFileInput = React.useRef(null);

  console.log(avatar_data);
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
    
  };

  const cropImageNow = () => {
    var img = imgRef.current;
    const canvas = document.createElement("canvas");

    try {
      const scaleX = img.naturalWidth / img.width;
      const scaleY = img.naturalHeight / img.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      const pixelRatio = window.devicePixelRatio;
      canvas.width = crop.width * pixelRatio;
      canvas.height = crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        img,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      setOut(base64Image);

      SendImage(base64Image);
    } catch {
      setmsg("Please Crop the Image and Set as Profile");
    }
  };

  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    onChange1(data.id);
    setAvtar(data.id);
    setCrop();
    setOut();
    setmsg();
    setInSrc();
    setbtnstatus(true);
    setOpenModel(!openModel);
  });

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  //console.log(out)

  function SendImage(Image64) {
    const formData = new FormData();
    console.log(Image64);
    const file = DataURIToBlob(Image64);
    formData.append("file", file);
    if (avatar_data !== "") {
      console.log(avatar);
      fileMutationdelete.mutate(avatar_data);
    }

    fileMutation.mutate(formData);
  }

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  return (
    <>
      <MDBModal
        staticBackdrop
        show={openModel}
        tabIndex="-1"
        setShow={setOpenModel}
      >
        <MDBModalDialog size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Set Your Profile</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => {
                  setCrop();
                  setOut();
                  setInSrc();
                  setmsg();
                  setbtnstatus(true);
                  setOpenModel(!openModel);
                }}
              ></button>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                {!inSrc ? (
                  avatar_data !== "" ? (
                    <img
                      id="ininimg"
                      src={getImageURL(avatar_data)}
                      alt="input"
                      ref={imgRef}
                      style={{
                        height: "300px",
                        width: "300px",
                        objectFit: "contain",
                        textAlign: "center",
                      }}
                    />
                  ) : (
                    <img
                      id="ininimg"
                      src={Avatar}
                      alt="input"
                      ref={imgRef}
                      style={{
                        height: "300px",
                        objectFit: "contain",
                      }}
                    />
                  )
                ) : (
                  <img
                    id="ininimg"
                    src={inSrc}
                    alt="input"
                    ref={imgRef}
                    style={{
                      height: "300px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </ReactCrop>
              {msg && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {msg}
                </p>
              )}

              {/* {
                    out&&
              
                <img
                  src={out}
                  alt="result"
                  style={{
                    height: "100px",
                    objectFit: "contain",
                  }}
                />
            } */}
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mb-2"
                onClick={handleClick}
                style={{
                  width: "12rem",
                  borderColor: "#1b5ff2",
                  color: "#1b5ff2",
                  backgroundColor: "#ffff",
                  borderRadius: "5px",
                  //float:'left'
                }}
              >
                {" "}
                <MDBIcon fas icon="upload" className="px-1" />
                {fileMutation.status === "loading"
                  ? "Uploading Image..."
                  : avatar_data
                  ? "Change Image"
                  : "Upload Image"}
              </button>
              <input
                ref={hiddenFileInput}
                onChange={(e) => {
                  // setImg(e.target.files[0]);
                  setbtnstatus(false);
                  setInSrc(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
              />

              <button
                className="px-3 py-1"
                style={{
                  color: "white",
                  border: 0,
                  borderRadius: "10px",
                  backgroundColor: `${btnstatus ? "#9598bf" : "#4a59f7"}`,
                }}
                disabled={btnstatus}
                onClick={() => {
                  cropImageNow();
                }}
              >
                Set as Profile
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
