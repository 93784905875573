import React from "react";

export default function Aboutus({ aboutus }) {
  console.log(aboutus);
  return (
    <>
      {aboutus === undefined || aboutus === null ? (
        ""
      ) : (
        <h4
          style={{
            marginTop: "20px",
            fontWeight: "500",
            color: "#8A8A8A",
          }}
        >
          About me
        </h4>
      )}

      {aboutus === undefined || aboutus === null || aboutus === "" ? (
        ""
      ) : (
        <p style={{ fontSize: "14px", fontWeight: "400" }}>{aboutus}</p>
      )}
    </>
  );
}
