import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import CreateComment from "./CreateComment";
import { getImageURL } from "../../../../_helper/image";

export default function ViewComment({ data, img ,post_id,isPostedStatus }) {
  const [replyInput, setReplyInput] = useState(false);

  const isPosted = (status) => {
    isPostedStatus(status)
  }

 
  return (
    <MDBRow className="flex" >
      <MDBCol>
        <MDBCardBody className="m-0 p-0" >
          <MDBRow>
            <MDBCol >
              {
                data.map((item,idx)=>(

                  item.parent_comment === null?
              <div className="d-flex flex-start mt-4">
                <MDBCardImage
                  className="rounded-circle shadow-1-strong me-2"
                  src={getImageURL(item.user_created?.avatar)}
                  alt="avatar"
                  width="35"
                  height="35"
                />

                <div className="flex-grow-1 flex-shrink-1">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-1">{`${item?.user_created?.first_name} ${item?.user_created?.middle_name} ${item?.user_created?.last_name}`} </p>
                      <a
                      style={{
                        cursor: "pointer",
                        color:`${replyInput?"#0D6EFD":""}`
                      }}
                        onClick={() => {
                       
                          setReplyInput(!replyInput);
                        }}
                      >
                        <MDBIcon fas icon="reply fa-xs" />
                        <span className="small"> reply</span>
                      </a>
                    </div>
                    <p className="small mb-0">
                     {item?.content}
                    </p>
                  </div>
                  {replyInput && <CreateComment post_id={post_id} img={img} isParentID = {item.id} isPosted={isPosted} />}
                  {
                    item.replies
                    .map((reply)=>(

                  <div className="d-flex  mt-4">
                    <a className="me-1">
                      <MDBCardImage
                        className="rounded-circle shadow-1-strong me-2"
                        src={getImageURL(item?.user_created?.avatar)}
                        alt="avatar"
                        width="35"
                        height="35"
                      />
                    </a>

                    <div className="flex-grow-1 flex-shrink-1">
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-1">{`${reply?.user_created?.first_name} ${reply?.user_created?.middle_name} ${reply?.user_created?.last_name}`}</p>
                        </div>
                        <p className="small mb-0">
                         {reply?.content}
                        </p>
                      </div>
                    </div>
                  </div>
                    ))
                  }
                </div>
              </div>
              :""
                ))
              }

            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCol>
    </MDBRow>
  );
}
