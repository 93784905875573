import {
  MDBBadge,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBTextArea,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { secureAxios } from "../../../_helper/auth";
import { useSendEmail } from "../../../_helper/SendEmailAction";

export default function ApprovedMembership() {
  const [data, setData] = useState([]);
  const [basicModal, setBasicModal] = useState(false);
  const [id, setid] = useState("");
  const [msg, setmsg] = useState("");
  const [email, setemail] = useState("");
  const queryClient = useQueryClient();
  const {
    isSuccess: successMembers,
    isLoading: loadingMembers,
    data: dataMembers,
  } = useQuery(["members"], () =>
    secureAxios
      .get(
        "/users?fields=id,user_type,status,email,profile.first_name,profile.last_name,profile.mobile,profile.native_village,profile.current_city,profile.native_district,profile.id&filter[status][_eq]=draft&filter[user_type][_neq]=organisation&limit=-1"
      )
      .then((res) => res.data)
  );
  const updateMutation = useMutation(
    (newData) => secureAxios.patch(`users/${newData.id}`, newData.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["members"]);
        setBasicModal(false);
      },
    }
  );

  const updateValidtillMutation = useMutation(
    (newData) =>
      secureAxios.patch(`items/profile/${newData.profile_id}`, newData.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["members"]);
        setBasicModal(false);
      },
    }
  );

  const Membership_till_date = () => {
    const curr_date = new Date();
    curr_date.setDate(curr_date.getDate() + 30);
    return curr_date.toISOString();
  };

  const email_mutation = useSendEmail();

  const MembershipApproval = (id, profile_id, user_email) => {
    console.log(user_email);

    updateMutation.mutate({
      id: id,
      data: {
        status: "active",
      },
    });

    updateValidtillMutation.mutate({
      profile_id: profile_id,
      data: {
        membership_valid_till: Membership_till_date(),
      },
    });

    email_mutation.mutate({
      to: user_email,
      subject: "Membership Approved",
      body: "Dear Member,<br/><br/>Your membership with lCCIA is approved now you can visit https://www.lccia.in to login to members portal and to upgrade your membership.<br/><br/>How to Login?<br/><br/>Go to >> https://www.lccia.in >> click on Login button on the top >> use your registered email ID to login>> Before please reset your password.<br/><br/>Regards,<br/>Admin LCCIA",
    });
  };

  const MembershipRejection = (user_email) => {
    console.log(user_email);
    console.log(msg);
    updateMutation.mutate({
      id: id,
      data: {
        status: "suspended",
        remark: msg,
      },
    });

    email_mutation.mutate({
      to: user_email,
      subject: "Membership Rejected",
      body: `Dear Member,<br/><br/>${msg}<br/><br/>Regards,<br/>Admin LCCIA`,
    });
  };

  const handleMessage = (eve) => {
    setmsg(eve.target.value);
  };

  // approval_mutation = useMembershipApproval((id,data) =>{
  //     console.log(data)
  // })

  // const getData = () => {
  //     secureAxios
  //         .get(
  //             'users?limit=25&fields[]=id&fields[]=first_name&fields[]=last_name&fields[]=email&fields[]=mobile&fields[]=status&fields[]=native_village&fields[]=native_district&fields[]=current_city&filter={"status":"draft"}'
  //         )
  //         .then((data) => {
  //             console.log(data.data);
  //             setData(data.data);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // };

  // useEffect(() => {
  //     getData();
  // }, []);

  return (
    <>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Rejection Message</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => {
                  setBasicModal(false);
                }}
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBTextArea
                label="Message"
                rows={4}
                onChange={handleMessage}
                value={msg}
              />
            </MDBModalBody>

            <MDBModalFooter>
              <button
                className="px-4 py-1"
                style={{
                  backgroundColor: "#F58A2E",
                  color: "white",
                  border: 0,
                  borderRadius: "5px",
                }}
                onClick={() => {
                  MembershipRejection(email);
                }}
              >
                Send
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBTable align="middle" responsive>
        <MDBTableHead>
          <tr>
            <th scope="col" className="">
              First Name
            </th>
            <th scope="col" className="">
              Last Name
            </th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">Status</th>
            <th scope="col">Native Village</th>
            <th scope="col">Native District</th>
            <th scope="col"> Current City</th>
            <th scope="col"> Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {successMembers ? (
            dataMembers.length === 0 ? (
              <h5
                style={{
                  color: "red",
                  marginTop: "10px",
                }}
              >
                No Requests are Found All Approved
              </h5>
            ) : (
              dataMembers.map((value, idx) => (
                <>
                  <tr>
                    {value.profile.map((value1, idx) => (
                      <>
                        <td>{value1["first_name"]}</td>
                        <td>{value1["last_name"]}</td>
                        <td>{value["email"]}</td>
                        <td>{value1["mobile"]}</td>
                        <td>
                          <MDBBadge color="warning" pill>
                            {value["status"] === "draft" ? "pending" : ""}
                          </MDBBadge>
                        </td>
                        <td>{value1["native_village"]}</td>
                        <td>{value1["native_district"]}</td>
                        <td>{value1["current_city"]}</td>

                        <td>
                          <div className="d-flex">
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#11ad2b",
                                borderRadius: "5px",

                                border: "none",
                              }}
                              onClick={() => {
                                MembershipApproval(
                                  value.id,
                                  value1.id,
                                  value.email
                                );
                              }}
                            >
                              Approve
                            </button>
                            <button
                              style={{
                                color: "white",
                                backgroundColor: "#fa0a0a",
                                borderRadius: "5px",
                                marginLeft: "5px",
                                border: "none",
                              }}
                              onClick={() => {
                                setmsg("");
                                setBasicModal(true);
                                setid(value.id);
                                setemail(value.email);

                                //MembershipRejection(value.id)
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </>
                    ))}
                  </tr>
                </>
              ))
            )
          ) : loadingMembers ? (
            <h2>Loading...</h2>
          ) : (
            <h2>Loading Failed</h2>
          )}
        </MDBTableBody>
      </MDBTable>
    </>
  );
}
