import { useMutation, useQuery } from "react-query";
import { publicAxios, secureAxios } from "./auth";
import { queryClient } from "./query";

export const useRegister = (onSuccess) => {
  return useMutation(
    (newItem) =>
      publicAxios
        .post("/qe_register/register", newItem)
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useMe = () => {
  return useQuery("user", () => secureAxios.get("/users/me"), {
    retry: 2,
    //token valid time
    // staleTime: 1000 * 10,
  });
};

export const useUser = (id) => {
  return useQuery("user", () => secureAxios.get(`/users/${id}`), {
    retry: 2,
    //token valid time
    // staleTime: 1000 * 10,
  });
};

export const useUserUpdate = (onSuccess) => {
  return useMutation(
    (newItem) =>
      secureAxios.patch("/users/me", newItem).then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {},
    }
  );
};

export const useUserProfileUpdate = (onSuccess) => {
  return useMutation(
    (newItem) =>
      secureAxios
        .patch(
          `/items/profile/${
            localStorage.getItem("user_id")
              ? localStorage.getItem("user_id")
              : sessionStorage.getItem("user_id")
          }`,
          newItem
        )
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useUserProfileUpdateMembership = (onSuccess) => {
  return useMutation(
    (newItem) =>
      secureAxios
        .patch(`/items/profile/${localStorage.getItem("user_id")}`, newItem)
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useUserBusinessProfileUpdate = (onSuccess) => {
  return useMutation(
    (newItem) =>
      secureAxios
        .patch(`/items/business_profiles/${newItem.id}`, newItem.data)
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useUserBusinessProfileCreate = (onSuccess) => {
  return useMutation(
    (newItem) =>
      secureAxios
        .post(`/items/business_profiles`, newItem)
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useUserTagUpdate = (id) => {
  return useMutation(
    (newItem) =>
      secureAxios
        .patch(`/items/profile/${id}`, newItem)
        .then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["items", "profile"]);
      },
    }
  );
};

export const useChangePassword = (onSuccess) => {
  return useMutation(
    (data) => secureAxios.patch("/users/me", data).then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useMembershipApproval = (id, onSuccess) => {
  return useMutation(
    (data) => secureAxios.patch(`users/${id}`, data).then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {
        onSuccess(data);
      },
    }
  );
};

export const useUpdateUserById = (id) => {
  return useMutation(
    (data) => secureAxios.patch(`users/${id}`, data).then((res) => res.data),
    {
      onSuccess: (data, variables, context) => {},
    }
  );
};
