import { useMutation } from "react-query";
import { secureAxios } from "../auth";
import { queryClient } from "../query";

export const useCreatePost = () =>{
    return useMutation(
        async(data) => {
            return await secureAxios
                .post("/items/qd_post", data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "post"]);
            },
        }
    );
}