import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useEffect } from "react";
import Linkify from "react-linkify";
import Timestamp from "react-timestamp";

import {
  useCreateLikePost,
  useDisLikePost,
} from "../../../../_helper/colabarated_wall/LikeAction";
import { getImageURL } from "../../../../_helper/image";
import CreateComment from "../Comments/CreateComment";
import ViewComment from "../Comments/ViewComment";
import CreatePostModel from "../CreatePost/CreatePostModel";
import CreateEnquire from "../Enquire/CreateEnquire/CreateEnquiry";
import ViewEnquiry from "../Enquire/ViewEnquire/ViewEnquiry";
import DeletePostModal from "../MyPosts/DeletePostModal";
import ShareModel from "../SharePost/ShareModel";
import ImagePost from "./ImagePost";
import VideoPost from "./VideoPost";

export default function PostView({
  data,
  isEdit,
  islikeaction,
  isdislikeaction,
  img,
  isPostedComment,
}) {
  const [seemore, setSeeMore] = useState(false);
  const [islike, setIslike] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [ispostmodel, setIsPostModel] = useState(false);
  const [isenquire, setIsEnquire] = useState(false);
  const [isenquirestatus, setIsEnquireStatus] = useState(false);
  const [viewenquiry, setViewEnquiry] = useState(false);
  const [post_id, setPostId] = useState("");
  const [prefilldata, setPerFillData] = useState(null);
  const [dislikepostid, setDisLikePostId] = useState("");
  const [iscomment, setIsComment] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [openshare, setIsOpenShare] = useState(false);

  const isPosted1 = (isposted) => {
    isPostedComment(isposted);
  };

  const DeletePost = (id) => {
    //delete_post.mutate(id);
    setPostId(id);

    setIsOpen(true);
  };

  const likePost = useCreateLikePost((data, variables, context) => {
    islikeaction(true);
    isdislikeaction(true);
  });
  const dislikePost = useDisLikePost((data, variables, context) => {
    isdislikeaction(true);
    islikeaction(true);
  });

  const LikePostAction = () => {
    if (!islike) {
      likePost.mutate({
        id: data?.id,
        data: {
          liked_by: [
            {
              directus_users_id: `${localStorage.getItem("me_id")}`,
            },
          ],
        },
      });

      setIslike(!islike);
      islikeaction(false);
      isdislikeaction(false);
    } else {
      console.log(dislikepostid);
      dislikePost.mutate(dislikepostid);

      setIslike(!islike);
      islikeaction(false);
      isdislikeaction(false);
    }
  };

  const func_enquireStatus = () => {
    if (data?.enquires?.length > 0) {
      data?.enquires?.map((res, idx) => {
        console.log(res);
        if (res.user_created === localStorage.getItem("me_id")) {
          setIsEnquireStatus(true);
        }
      });
    }
  };

  const likeStatus = () => {
    if (data.liked_by.length > 0) {
      data.liked_by.map((res, idx) => {
        if (res.directus_users_id?.id === localStorage.getItem("me_id")) {
          setDisLikePostId(res.id);

          setIslike(true);
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.user_created?.id === localStorage.getItem("me_id")) {
        setIsadmin(true);
      } else {
        setIsadmin(false);
      }
    }
    likeStatus();
    func_enquireStatus();
  }, []);

  return (
    <>
      <CreateEnquire
        enquiremodal={isenquire}
        setEnquireModal={setIsEnquire}
        post_id={post_id}
      />
      <ShareModel
        isopen={openshare}
        setIsOpen={setIsOpenShare}
        post_id={post_id}
      />
      <MDBContainer className={`m-0 p-0 mb-4`}>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" className="m-0 p-0">
            <MDBCard
              style={{
                border: "1px solid #e0e0e0",
                boxShadow: "none",
              }}
            >
              <MDBCardHeader className="pt-3 pb-0">
                {isEdit && (
                  <>
                    {" "}
                    <DeletePostModal
                      isopen={isopen}
                      setIsOpen={setIsOpen}
                      post_id={post_id}
                    />
                    <CreatePostModel
                      isopen={ispostmodel}
                      setIsOpen={setIsPostModel}
                      img={
                        data?.user_created?.user_type === "individual"
                          ? `${
                              data?.user_created?.avatar === null
                                ? ""
                                : getImageURL(data?.user_created?.avatar)
                            }`
                          : getImageURL(
                              data?.user_created?.business_profiles[0]
                                ?.business_logo
                            )
                      }
                      data={prefilldata}
                      isEditOpen={true}
                    />
                    <div class="dropdown">
                      <button
                        class=""
                        id="dropdownMenuButton"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          alignContent: "center",
                          backgroundColor: "transparent",
                          border: "none",
                          float: "right",
                          fontSize: "22px",
                          fontWeight: "400",
                        }}
                      >
                        ...
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setPerFillData(data);
                              setIsPostModel(true);
                            }}
                          >
                            Edit
                          </a>
                        </li>
                        <li
                          onClick={() => {
                            DeletePost(data.id);
                          }}
                        >
                          <a
                            class="dropdown-item"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Withdrawal
                          </a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                <MDBRow className="d-flex justify-content-center">
                  <MDBCol md="12" className="m-0 p-0">
                    <div className="p-2 w-100 d-flex flex-row">
                      <img
                        src={`${getImageURL(data?.user_created?.avatar)}`}
                        className="rounded-circle"
                        height="40"
                        width="40"
                        alt=""
                      />
                      <div className="col mx-3 p-0">
                        <Timestamp
                          className="text-muted"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            float: "right",
                            fontFamily: "Roboto",
                          }}
                          relative
                          date={data?.date_created}
                        />
                        <h6
                          className="row m-0 p-0"
                          style={{
                            fontFamily: "Roboto",
                          }}
                        >
                          {`${data?.user_created?.first_name} ${data?.user_created?.middle_name} ${data?.user_created?.last_name}`}
                        </h6>

                        <p
                          className="row m-0 p-0 text-muted"
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                          }}
                        >
                          {`${
                            data?.user_created?.profile[0]?.native_village
                              ? data?.user_created?.profile[0]?.native_village
                              : ""
                          } ,${
                            data?.user_created?.profile[0]?.native_district
                              ? data?.user_created?.profile[0]?.native_district
                              : ""
                          }`}
                        </p>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <div className="mt-1">
                  <Linkify
                    properties={{
                      style: {
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <p
                      style={{
                        height: `${
                          data.content
                            ? data?.content?.length <= 100
                              ? "auto"
                              : seemore
                              ? "auto"
                              : "100px"
                            : "auto"
                        }`,
                        overflow: "hidden",
                        scrollBehavior: "none",
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "rgba(0, 0, 0, 0.9)",
                      }}
                    >
                      {data.content}
                    </p>
                  </Linkify>
                  <a
                    className="pb-1"
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      fontFamily: "unset",
                      float: "right",
                      fontSize: "14px",
                    }}
                    onClick={() => setSeeMore(!seemore)}
                  >
                    <u>{`${
                      data.content >= 100
                        ? seemore
                          ? "See less"
                          : "See more"
                        : ""
                    }`}</u>
                  </a>
                </div>
              </MDBCardHeader>
              <MDBCardBody
                className="m-0 p-0"
                style={{
                  marginBottom: "5px",
                }}
              >
                {data.type === "image" && (
                  <ImagePost
                    img_src={`${getImageURL(
                      data?.files[0]?.directus_files_id
                    )}`}
                  />
                )}

                {data.type === "video" && (
                  <VideoPost video_src={`${data.youtube_url}`} />
                )}

                <div className="mt-2">
                  <a
                    className="rounded-circle px-1 flex mt-2"
                    style={{
                      justifyContent: "center",
                      backgroundColor: "#378FE9",
                      marginLeft: "12px",
                      cursor: "pointer",
                    }}
                  >
                    <MDBIcon
                      far
                      icon="thumbs-up"
                      className=""
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        fontSize: "12px",
                      }}
                    />
                  </a>
                  <a
                    className="text-muted mx-2"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {data.liked_by.length}
                  </a>
                  <p
                    className="text-muted"
                    style={{
                      float: "right",
                      fontSize: "14px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {data?.enquires?.length} Enquires
                  </p>
                  <p
                    className="text-muted"
                    style={{
                      float: "right",
                      fontSize: "14px",
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {data.comments.length} Comments
                  </p>
                </div>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">
                <div
                  className="mx-1"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      border: 0,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      outline: "none",
                      fontFamily: "unset",
                      color: `${islike ? "#378FE9" : "rgba(0, 0, 0, 0.6)"}`,
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      LikePostAction();
                    }}
                  >
                    {islike ? (
                      <MDBIcon
                        fas
                        icon="thumbs-up"
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      />
                    ) : (
                      <MDBIcon
                        far
                        icon="thumbs-up"
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      />
                    )}{" "}
                    Like
                  </button>
                  <button
                    onClick={() => {
                      setIsComment(!iscomment);
                      setViewEnquiry(false);
                    }}
                    style={{
                      border: 0,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      outline: "none",
                      fontFamily: "unset",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "500",
                    }}
                  >
                    <MDBIcon
                      far
                      icon="comment"
                      className="mx-1"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    />
                    Comment
                  </button>
                  <button
                    onClick={() => {
                      setPostId(data.id);
                      setIsOpenShare(!openshare);
                    }}
                    style={{
                      border: 0,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      outline: "none",
                      fontFamily: "unset",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontWeight: "500",
                    }}
                  >
                    <MDBIcon
                      far
                      icon="share-square"
                      className="mx-1"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    />
                    Share
                  </button>
                  <button
                    disabled={!isEdit ? isenquirestatus : false}
                    onClick={() => {
                      setPostId(data.id);
                      setIsComment(false);
                      setViewEnquiry(!viewenquiry);
                      !isEdit && setIsEnquire(!isenquire);
                    }}
                    style={{
                      border: 0,
                      backgroundColor: "transparent",

                      outline: "none",
                      fontFamily: "unset",
                      color: isenquirestatus ? "#378FE9" : "rgba(0, 0, 0, 0.6)",
                      fontWeight: "500",
                    }}
                  >
                    {/* <MDBIcon far icon="headphones-alt" className="mx-1"
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color:isenquirestatus?"#378FE9":"rgba(0, 0, 0, 0.6)"
                      }} /> */}
                    <MDBIcon
                      fas
                      icon="headphones-alt"
                      className="mx-1"
                      style={{
                        fontSize: "18px",

                        color: isenquirestatus
                          ? "#378FE9"
                          : "rgba(0, 0, 0, 0.6)",
                      }}
                    />
                    {isenquirestatus ? "Enquired" : "Enquiry"}
                  </button>
                </div>
                {isEdit &&
                  viewenquiry &&
                  data.enquires.map((res, idx) => <ViewEnquiry data={res} />)}

                {iscomment && (
                  <>
                    <CreateComment
                      post_id={data.id}
                      img={img}
                      isadmin={isadmin}
                      isParentID={undefined}
                      isPosted={isPosted1}
                    />
                    {data.comments.length === 0 ? (
                      <p className="text-muted text-center">No Comments</p>
                    ) : (
                      <ViewComment
                        post_id={data.id}
                        data={data.comments}
                        img={img}
                        isPostedStatus={isPosted1}
                      />
                    )}
                  </>
                )}
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
