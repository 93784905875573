import { MDBSpinner } from "mdb-react-ui-kit";
import React from "react";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";

import TaxanomyData from "./TaxanomyData";

const onAction = (node, action) => {
  console.log("onAction::", action, node);
};
const onNodeToggle = (currentNode) => {
  console.log("onNodeToggle::", currentNode);
};

export default function TaxanomyCategory({ onChange }) {
  const data = TaxanomyData();
  console.log(data);
  const onChange1 = (currentNode, selectedNodes) => {
    onChange(selectedNodes);
    //console.log("onChange::", selectedNodes);
  };

  return data.length <= 20 ? (
    <p style={{ color: "green" }}>
      Please wait getting all buisness categories it may take time{" "}
      <MDBSpinner size="sm" grow color="success" />
      <MDBSpinner size="sm" grow color="success" />
      <MDBSpinner size="sm" grow color="success" />
    </p>
  ) : (
    <>
      <DropdownTreeSelect
        keepOpenOnSelect
        keepTreeOnSearch
        texts={{ placeholder: "Select Buisness Category" }}
        data={data}
        onChange={onChange1}
        onAction={onAction}
        onNodeToggle={onNodeToggle}
      />
      {/* <TaxanomyData/> */}
    </>
  );
}
