import { MDBBadge } from "mdb-react-ui-kit";
import React from "react";

export default function OrganisationHomePage({ biz_data }) {
  const businessType = biz_data?.business_type;
  const organisationCategory = biz_data?.organisation_category;

  return (
    <>
      <a
        style={{
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Business Type
      </a>
      <br></br>
      {businessType?.map((value, idx) => (
        <MDBBadge
          tag="a"
          color="primary"
          className="mr-2"
          style={{ marginRight: "2px" }}
        >
          {value.label}
        </MDBBadge>
      ))}

      <br></br>

      <a
        style={{
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Organisation Category
      </a>
      <br></br>

      {organisationCategory?.map((value, idx) => (
        <MDBBadge
          tag="a"
          color="primary"
          className="mr-2"
          style={{ marginRight: "2px" }}
        >
          {value.label}
        </MDBBadge>
      ))}
      <br></br>

      <a
        style={{
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        Business Category
      </a>
      <br></br>

      {biz_data?.categories === undefined
        ? []
        : biz_data?.categories.map((value, idx) => (
            <MDBBadge
              tag="a"
              color="primary"
              className="mr-2"
              style={{ marginRight: "2px" }}
            >
              {value?.category_id?.name}
            </MDBBadge>
          ))}

      <p className="mt-2" style={{ fontSize: "14px", fontWeight: "400" }}>{`${
        biz_data?.business_description ? biz_data.business_description : ""
      }`}</p>
      <a href={biz_data?.url ? biz_data.url : ""}>
        {biz_data?.url ? biz_data.url : ""}
      </a>

      {biz_data?.Year_of_incorporation === null ||
      biz_data?.Year_of_incorporation === "" ? (
        ""
      ) : (
        <p
          style={{
            fontWeight: 450,
          }}
        >
          <br></br>
          Year of Incorporation
          <MDBBadge
            tag="a"
            color="success"
            style={{
              marginLeft: "5px",
            }}
          >
            {new Date(
              `${biz_data?.Year_of_incorporation?.slice(0, 10)}`
            ).toLocaleString("en-us", {
              month: "short",
              year: "numeric",
            })}
          </MDBBadge>
        </p>
      )}

      {biz_data?.gst === null || biz_data?.gst === "" ? (
        ""
      ) : (
        <p
          style={{
            fontWeight: 450,
          }}
        >
          <br></br>
          GST No
          <MDBBadge
            tag="a"
            color="success"
            style={{
              marginLeft: "5px",
            }}
          >
            {biz_data?.gst}
          </MDBBadge>
        </p>
      )}

      <p
        style={{
          fontWeight: 450,
        }}
      >
        No of Employee
        <MDBBadge
          tag="a"
          color="success"
          style={{
            marginLeft: "5px",
          }}
        >
          {" "}
          {biz_data?.no_of_employee}
        </MDBBadge>
      </p>
    </>
  );
}
