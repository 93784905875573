import { MDBIcon } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useMe,
  useUserProfileUpdateMembership,
  useUserUpdate,
} from "../../../_helper/actions";
import { secureAxios } from "../../../_helper/auth";
import { useChangeMembership } from "../../../_helper/MembershipAction";
import BillingButton from "./BillingButton";
import MemberShipTable from "./MemberShipTable";

export default function UpgradeMembership() {
  const [data, setdata] = useState({});
  const [selectedplan, setSelectedPlan] = useState(null);
  const [total, setTotal] = useState(selectedplan ? selectedplan.price : 0);
  const [referenceData, setReferenceData] = useState([]);
  const [datastatus, setDataStatus] = useState(false);
  const [mainrefstaus, setMainRefStaus] = useState(false);
  const [getrefstatus, setGetRefStatus] = useState(false);
  const [new_member, setNewMember] = useState(true);
  const [old_member, setOldMember] = useState(false);

  const navigate = useNavigate();
  const updateme = useUserUpdate();

  const getReferenceData = async () => {
    await secureAxios
      .get(
        `/items/profile/${localStorage.getItem(
          "user_id"
        )}?fields=received_references.*`
      )
      .then((res) => {
        // console.log("RES", res);
        setReferenceData(
          res.data.received_references === undefined
            ? []
            : res.data.received_references
        );

        setGetRefStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(referenceData);

  const ReferenceStatus = () => {
    const ref_data = referenceData?.map((element, idx) =>
      element.status === "approved" ? true : false
    );
    console.log(ref_data);
    if (ref_data.length === 0) {
      setMainRefStaus(false);
    } else {
      var allTrue = !ref_data.some((x) => x === false);
    }

    if (allTrue) {
      setMainRefStaus(true);
    } else {
      setMainRefStaus(false);
    }
  };

  const Membership_till_date = (yrs) => {
    const curr_date = new Date();
    curr_date.setDate(curr_date.getDate() + 365 * yrs);
    return curr_date.toISOString();
  };

  // console.log(mainrefstaus);

  const { data: mdata, isFetched, isLoading, isSuccess } = useMe();

  const onChange = (id) => {
    if (id) {
      secureAxios(`/items/membership_plan/${id}`)
        .then((res) => {
          console.log(res.data);
          console.log(new_member);
          if (data?.plan === null || new_member === true) {
            setSelectedPlan({
              id: id,
              category: res?.data.category,
              membership_duration_yrs: res?.data?.duration_in_years,
              name: res?.data?.name,
              price: res.data.price,
              reduction: 0,
              one_time_fees: res.data.one_time_fees,
              //total_cost: res.data.price,
              total_cost:
                Number(res.data.price) +
                Number(res.data.one_time_fees) -
                (Number(res.data.price) *
                  Number(res.data.discount_for_new_registration)) /
                  100,
            });
          } else {
            // remaining cost
            var current_plan_cost = !data?.plan?.price ? 0 : data?.plan?.price;
            //console.log(data.plan.price)
            let plan_cost = res?.data?.price;
            console.log(plan_cost);
            let cost_remaning = 0;
            let time_remaning = null;
            if (data?.membership_valid_till) {
              const plan_expiry = new Date(data?.membership_valid_till);
              const today = new Date();
              const diffDays = parseInt(
                (plan_expiry - today) / (1000 * 60 * 60 * 24),
                10
              );
              // const diffMonths = diffDays / 30;
              cost_remaning =
                (current_plan_cost === null
                  ? 0
                  : current_plan_cost * diffDays) / 365;

              plan_cost = parseInt((plan_cost * diffDays) / 365);

              time_remaning = `${Math.floor(diffDays / 30)} months ${
                diffDays % 30
              } days`;

              if (cost_remaning > current_plan_cost) {
                cost_remaning = current_plan_cost;
              }

              //------------------------------------------------

              // const reduction = currentPlan.price;
              const reduction = parseInt(cost_remaning);
              let total = plan_cost - reduction;
              setTotal(total);
              if (total < 0) {
                total = 0;
              }
              setSelectedPlan({
                id: id,
                category: res?.data.category,
                membership_duration_yrs: res?.data?.duration_in_years,
                name: res?.data?.name,
                price: plan_cost,
                reduction: reduction,
                total_cost:
                  total -
                  (total * res.data.discount_for_new_registration) / 100,
              });
              setTotal(total);
            } else {
              setTotal(0);
            }
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };

  const getData = async () => {
    await secureAxios
      .get(
        "/users/me?fields=profile.native_district,profile.native_village,profile.current_city,email,profile.first_name,profile.last,profile.middle_name,profile.mobile,profile.membership_valid_till,profile.plan.*"
      )
      .then((res) => {
        const res_data = res.data?.profile;
        //console.log(res.data)
        const email = res.data.email;
        setdata({ ...res_data[0], email });
      })
      .catch((err) => {
        console.log(err);
      });
    setDataStatus(false);
  };
  // console.log(data);

  // const getPlanData = () => {
  //   await secureAxios('memb')
  // }

  useEffect(() => {
    getData();
    onChange(mdata?.data?.last_selected_membership);
    getReferenceData();

    if (getrefstatus) {
      ReferenceStatus();
    }
  }, [getrefstatus]);

  useEffect(() => {
    onChange(mdata?.data?.last_selected_membership);
    // console.log(mdata?.data?.last_selected_membership);
  }, [isSuccess, new_member, old_member]);

  console.log(data);
  // const mutation = useChangeMembership(`${localStorage.getItem("user_id")}`);
  const mutation = useUserProfileUpdateMembership(() => {
    console.log("updated");
  });

  return (
    <>
      <h4
        style={{
          fontSize: "22px",
          marginTop: "40px",
        }}
      >
        Upgrade Membership Plan
      </h4>

      <div
        style={{
          marginTop: "25px",
          width: "auto",
          border: "1px solid #2596be",
          borderRadius: "5px",
          borderColor: "black",
          padding: "5px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            padding: "10px",
          }}
        >
          <div className="row">
            <div className="col-4">Current Plan</div>

            <div className="col-4" style={{ fontWeight: "500" }}>
              {data?.plan?.name}
            </div>
          </div>

          <div className="row">
            <div className="col-4">Expiry Date</div>

            <div className="col-8" style={{ fontWeight: "500" }}>
              {data.membership_valid_till === null ||
              data.membership_valid_till === ""
                ? ""
                : new Date(
                    `${data?.membership_valid_till?.slice(0, 10)}`
                  ).toLocaleString("en-us", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
            </div>
          </div>

          {!selectedplan ? (
            ""
          ) : (
            <div>
              <div className="row mt-2">
                <div className="col-4">
                  <h6
                    style={{
                      color: "blue",
                    }}
                  >
                    Upgrade to :
                  </h6>
                </div>

                <div className="col-8">{selectedplan?.name}</div>
              </div>

              <div className="row">
                <div className="col-4">
                  <h6
                    style={{
                      color: "blue",
                    }}
                  >
                    Plan Cost :
                  </h6>
                </div>

                <div className="col-8">{selectedplan?.price}</div>
              </div>

              <div className="row">
                <div className="col-4">
                  <h6
                    style={{
                      color: "blue",
                    }}
                  >
                    Reduction :
                  </h6>
                </div>

                <div className="col-8">{selectedplan?.reduction}</div>
              </div>

              <div className="row">
                <div className="col-4">
                  <h6
                    style={{
                      color: "blue",
                    }}
                  >
                    One time Fees :
                  </h6>
                </div>

                <div className="col-8">{selectedplan?.one_time_fees}</div>
              </div>

              <div className="row">
                <div className="col-4">
                  <h6
                    style={{
                      color: "blue",
                    }}
                  >
                    Total Pay :
                  </h6>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="option1"
                      onChange={(eve) => {
                        setNewMember(true);
                        setOldMember(false);
                      }}
                      checked={new_member}
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      New Member
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      checked={old_member}
                      onChange={(eve) => {
                        setOldMember(true);
                        setNewMember(false);
                      }}
                    />
                    <label class="form-check-label" for="inlineRadio2">
                      Old Member
                    </label>
                  </div>
                </div>

                <div className="col-8">{selectedplan?.total_cost}</div>
                <div className="col-lg">
                  {mainrefstaus === true ? ( // need to change true
                    <>
                      {" "}
                      <button
                        className="p-2"
                        style={{
                          color: "white",
                          backgroundColor: "#1b5ff2",
                          borderRadius: "5px",
                          border: "none",
                          float: "right",
                        }}
                        onClick={() => {
                          updateme.mutate(
                            { pending_amount: selectedplan?.total_cost },
                            {
                              onSuccess: () => {
                                navigate(
                                  `/profile/membership/payment-verification/${selectedplan.id}`
                                );
                              },
                            }
                          );
                        }}
                      >
                        <MDBIcon fas icon="angle-double-up" className="px-1" />{" "}
                        {`Verify ₹${selectedplan?.total_cost} to upgrade`}
                      </button>
                      {/* <BillingButton
                        name={`${data.first_name} ${data.middle_name} ${data.last_name}`} 
                        total={selectedplan?.total_cost}
                        email={`${data.email}`}
                        contact_no={`${data.mobile}`}
                        //address={`${data.native_village},${data.native_district},${data.current_city}`}
                       onSuccess={(payment_id)=>{
                          mutation.mutate({
                            plan:selectedplan?.id,
                            last_payment_id:payment_id,
                            membership_start_date:new Date().toISOString(),
                            membership_valid_till:Membership_till_date(selectedplan.membership_duration_yrs)

                            



                          })
                       }}


                      /> */}
                      <p style={{ color: "green", marginTop: "2px" }}>
                        <MDBIcon far icon="check-circle px-1" />
                        Document Verified
                      </p>
                    </>
                  ) : (
                    <>
                      <button
                        className="p-2"
                        style={{
                          color: "white",
                          backgroundColor: "#1b5ff2",
                          borderRadius: "5px",
                          border: "none",
                          float: "right",
                        }}
                        onClick={() => {
                          navigate(
                            `/profile/membership/upgrade-verification/${selectedplan.id}`
                          );
                        }}
                      >
                        <MDBIcon fas icon="angle-double-up" className="px-1" />{" "}
                        Document Verification Required
                      </button>
                      <p style={{ color: "red", marginTop: "2px" }}>
                        *Once document verification is done you can pay for
                        membership.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          marginTop: "30px",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        <MemberShipTable onChange={onChange} current_plan={data?.plan?.id} />
      </div>
    </>
  );
}
