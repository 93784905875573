import { useQuery, useMutation } from "react-query";
import { secureAxios } from "./auth";


export const useUploadFile = (onSuccess) => {
    return useMutation(
        (formData) => {
            return secureAxios.post(`/files`, formData).then((res) => res.data);
        },
        {
            onSuccess: onSuccess,
        }
    );
};

export const useDeleteFile = (onSuccess)=> {
    return useMutation(
        (id) => {
            return secureAxios.delete(`/files/${id}`).then((res) => res.data);
        },
        {
            onSuccess: onSuccess,
        }
    );
};