import React from 'react'
import { useParams } from 'react-router-dom'
import AdminPaymentVerification from './membership_plan/AdminPaymentVerification';
import PaymentVerification from './membership_plan/PaymentVerification';
import Navbar from './navbar';

export default function PaymentVerificationRouting() {
    const { id } = useParams();  
    
    if (id === 'admin'){
        return(
            <>
            <Navbar />
          <main>
                  <AdminPaymentVerification/>
                  </main>
          </>
        )

    }else{
        return(
            <>
            <Navbar/>
           <main>
                  <PaymentVerification/>
                  </main>
          </>
        )
    }
}
