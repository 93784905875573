import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import { useCreateComment } from "../../../../_helper/colabarated_wall/CreateCommentAction";
import ".././style/layout.css";

export default function CreateComment({ post_id,img,isadmin,isParentID,isPosted }) {
  const [comment,setComment] = useState("");

  const comment_mutation = useCreateComment();

  const onPost = () => {

   
    comment_mutation.mutate(
      isParentID?{
      post: post_id,
      content: comment,
      is_author:isadmin,
      parent_comment:isParentID
    }: {
      post:post_id,
      content: comment,
      is_author:isadmin,
      parent:null
    });

    isPosted(true)


    setComment("");
  }

  

  console.log(isParentID)



  return (
    <>
      <MDBContainer className="m-0 p-0">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12" className="m-0 p-0">
            <MDBCard
              className="bg-white m-0 p-0 "
              style={{
                boxShadow: "none",
              }}
            >
              <MDBCardBody className="p-2 w-100 d-flex flex-row">
                <div>
                  <img
                    src={img}
                    className="rounded-circle my-1"
                    height="40"
                    width="40"
                    alt=""
                  />
                </div>
                <input
                  className="comment-input px-3"
                  placeholder="Write a comment..."
                  value={comment}
                  onChange={(e)=>{
                    setComment(e.target.value);
                  }}
                  style={{
                    border: "1px solid #C0BEBE",
                    width: "100%",
                    borderRadius: "12px",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                    textAlign: "left",
                    margin: "8px",
                  }}
                />
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {
          comment.length>0 && 
        <button
          className="px-3"
          style={{
            backgroundColor: "#0D6EFD",
            color: "white",
            border: "none",
            borderRadius: "8px",
            padding: "3px",
            fontSize: "12px",
            fontWeight: "400",
            textAlign: "left",
            marginLeft: "10px",

            cursor: "pointer",
          }}
          onClick = {()=>{
            onPost();
          }}
        >
          Post
        </button>
        }
      </MDBContainer>
    </>
  );
}
