import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function WallMenuCard() {
    const navigate = useNavigate();
  return (
    <MDBCard className='m-0 p-0' style={{
        border: "1px solid #e0e0e0",
        boxShadow:"none"
      }}>
        <MDBCardBody className='m-0'>
            <div className='px-4' style={{
                fontSize:"16px",
                fontWeight:"400",
                color:"#407ee3",
                marginTop:"10px",
                marginLeft:"5px"
            
            }}>
                
                <p style={{
                    cursor:"pointer"

                }}
                onClick={()=>{
                    navigate('/_home/my_posts')
                }}
                >My Posts</p>
                

                <p 
                onClick={()=>{
                    navigate('/profile/view')
                }}
                style={{
                    cursor:"pointer"
                    
                }}>My Profile</p>
                {/* <p
                onClick={()=>{
                    navigate("/profile/edit-profile")
                }} 
                style={{
                    cursor:"pointer"
                }}>Edit Profile</p> */}
                <p 
                onClick={()=>{
                    navigate('/profile/manage-business-profile')
                }}
                style={{
                    cursor:"pointer"
                }}>My Business</p>
               <p
                onClick={()=>{
                    navigate('/profile/upgrade-membership')
                }} 
                style={{
                    cursor:"pointer"
                    
                }}>My Membership</p>
                
                </div>
        </MDBCardBody>
    </MDBCard>
  )
}
