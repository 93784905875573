import React, { useState } from "react";
import {
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../style/Datetime.css";
import { getImageURL } from "../../../_helper/image";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import DocumentImg from "../../../media/document_avatar.png";

export default function ProjectModel({
  prshow,
  prsetShow,
  sentProjectData,
  reciveData,
  onChangeProjectData,
}) {
  const [Name, setName] = useState("");
  const [AssoicatedOrg, setAssoicatedOrg] = useState("");
  const [Description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [doc_img, setDocImg] = useState("");
  const hiddenFileInput = React.useRef(null);
  //console.log(reciveData)
  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    onChangeProjectData({
      name: reciveData.name,
      assoicated_org: reciveData.assoicated_org,
      description: reciveData.description,
      URL: reciveData.URL,
      doc_img: data.id,
    });

    setDocImg(data.id);
    console.log(data.id);
  });

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });

  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (doc_img !== "") {
          console.log(doc_img);
          fileMutationdelete.mutate(doc_img);
        }

        fileMutation.mutate(formData);
      }
    }
  };

  const handleName = (eve) => {
    setName(eve.target.value);
    onChangeProjectData({
      name: eve.target.value,
      assoicated_org: reciveData.assoicated_org,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleAssoicatedOrg = (eve) => {
    setAssoicatedOrg(eve.target.value);
    onChangeProjectData({
      name: reciveData.name,
      assoicated_org: eve.target.value,
      description: reciveData.description,
      URL: reciveData.URL,
    });
  };

  const handleDescription = (eve) => {
    setDescription(eve.target.value);
    onChangeProjectData({
      name: reciveData.name,
      assoicated_org: reciveData.assoicated_org,
      description: eve.target.value,
      URL: reciveData.URL,
    });
  };

  const handleURL = (eve) => {
    setUrl(eve.target.value);
    onChangeProjectData({
      name: reciveData.name,
      assoicated_org: reciveData.assoicated_org,
      description: reciveData.description,
      URL: eve.target.value,
    });
  };

  const ProjectData = {
    name: Name,
    assoicated_org: AssoicatedOrg,
    description: Description,
    URL: url,
    doc_img: doc_img,
  };

  const sent = () => {
    sentProjectData(ProjectData);
    setName("");
    setAssoicatedOrg("");
    setDescription("");
    setUrl("");
    setDocImg("");
    prsetShow(!prshow);
  };

  return (
    <>
      <MDBModal show={prshow} setShow={prsetShow} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Projects</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => prsetShow(!prshow)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              {
                <img
                  src={
                    reciveData.doc_img
                      ? `${getImageURL(reciveData.doc_img)}`
                      : DocumentImg
                  }
                  className="preview-img"
                  alt="preview"
                  style={{ width: "150px", marginLeft: "18px" }}
                />
              }
              <div className="d-flex flex-row alignitems-center mb-4 mt-3">
                <button
                  className="mb-2"
                  onClick={handleClick}
                  style={{
                    width: "auto",
                    padding: "7px",
                    borderColor: "#1b5ff2",
                    color: "#1b5ff2",
                    backgroundColor: "#ffff",
                    borderRadius: "5px",
                  }}
                  status={fileMutation.status}
                  disabled={fileMutation.isLoading}
                >
                  {" "}
                  <MDBIcon fas icon="upload" className="px-1" />
                  {fileMutation.status === "loading"
                    ? "Uploading Image..."
                    : reciveData.doc_img
                    ? "Change Project Image"
                    : "Your Project Image"}
                </button>
                <input
                  ref={hiddenFileInput}
                  onChange={onFile}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Name"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleName}
                  value={`${reciveData.name}`}
                  autoComplete="off"
                  autoFocus
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Assoicated Org"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleAssoicatedOrg}
                  value={`${reciveData.assoicated_org}`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBTextArea
                  wrapperClass="mb-4 w-100"
                  label="Description"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleDescription}
                  value={`${reciveData.description}`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="URL"
                  id="formControlLg"
                  type="url"
                  size="md"
                  onChange={handleURL}
                  value={`${reciveData.URL}`}
                  autoComplete="off"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={sent}
              >
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
