import React from "react";

export default function PersonalInformation({ gender }) {
  return (
    <>
      {gender === undefined || null ? (
        ""
      ) : (
        <h4
          style={{
            marginTop: "20px",
            fontWeight: "500",
            color: "#8A8A8A",
          }}
        >
          Personal Information
        </h4>
      )}

      <div>
        <table
          style={{
            marginTop: "10px",
            width: "-webkit-fill-available",
          }}
        >
          

          <tr className="mt-3" style={{paddingTop:"10px"}}>
            <td
              style={{
                width: "30%",
                fontSize: "14px",
                fontWeight: "450"
                
               
              }}
            >
              Gender
            </td>
            <td style={{ fontSize: "14px",
                  fontWeight: "450"}}>{gender}</td>
          </tr>
        </table>
      </div>
    </>
  );
}
