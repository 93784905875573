import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BrandLogo from "../../media/logo.png";
import {
  useForgetPassword,
  useSendForgetPasswordLink,
} from "../../_helper/ForgetPasswordActions";

export default function ForgetPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  const [data, setData] = useState({});

  const sendLinkMutation = useSendForgetPasswordLink(() => {
    console.log("Sended");
  });
  const fogetPasswordMutation = useForgetPassword(() => {
    console.log("Sended");
  });

  const SubmitData = (eve) => {
    eve.preventDefault();
    if (token) {
      const payload = {
        token: token,
        password: data.password,
      };
      fogetPasswordMutation.mutate(payload);

      //console.log(payload)
    } else {
      const payload = {
        email: data.email,
      };
      sendLinkMutation.mutate(payload);
      //console.log(payload)
    }
  };

  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol col="12">
            <MDBCard
              className="bg-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "500px",
                borderTop: "8px solid #F58A2E",
              }}
            >
              <MDBCardBody className="p-5 w-100 d-flex flex-column">
                <h3 className="fw-bold mb-2 text-center mb-5 mt-2">
                  {" "}
                  <img
                    src={BrandLogo}
                    style={{
                      width: "10%",
                      marginRight: "10px",
                    }}
                  />{" "}
                  Members Portal LCCIA Forget Password
                </h3>
                {!token ? (
                  <form>
                    <div className="d-flex flex-row align-items-center mb-4">
                      <i
                        className="fas fa-envelope fa-lg me-3 fa-fw "
                        style={{ color: "#aaa" }}
                      ></i>

                      <MDBInput
                        wrapperClass="mb-2 w-100"
                        label="Registered Email Address"
                        id="formControlLg"
                        type="email"
                        size="lg"
                        required
                        autoFocus
                        onChange={(eve) => {
                          setData({ ...data, email: eve.target.value });
                        }}
                        value={data.email}
                      />
                    </div>

                    <button
                      className=" w-100 py-2 mb-3"
                      style={{
                        backgroundColor: "#F58A2E",
                        border: "0px",
                        borderRadius: "5px",
                        color: "white",
                        fontWeight: "500",
                      }}
                      type="sumbit"
                      onClick={SubmitData}
                    >
                      {!sendLinkMutation.isLoading ? (
                        "Get Reset Password Link"
                      ) : (
                        <MDBSpinner color="light" size="sm" />
                      )}
                    </button>
                    {sendLinkMutation.isSuccess ? (
                      <div
                        class="alert alert-success text-center mt-2"
                        role="alert"
                      >
                        <i class="fa fa-info-circle"></i> Please Check Your
                        Mail!
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                ) : (
                  <form>
                    <div className="d-flex flex-row align-items-center mb-4">
                      <i
                        className="fas fa-envelope fa-lg me-3 fa-fw "
                        style={{ color: "#aaa" }}
                      ></i>

                      <MDBInput
                        wrapperClass="mb-2 w-100"
                        label="New Password"
                        id="formControlLg"
                        type="password"
                        size="lg"
                        required
                        onChange={(eve) => {
                          setData({ ...data, password: eve.target.value });
                        }}
                        value={data.password}
                        autoFocus
                      />
                    </div>

                    <button
                      className=" w-100 py-2 mb-3"
                      style={{
                        backgroundColor: "#F58A2E",
                        border: "0px",
                        borderRadius: "5px",
                        color: "white",
                        fontWeight: "500",
                      }}
                      type="sumbit"
                      onClick={SubmitData}
                    >
                      {!fogetPasswordMutation.isLoading ? (
                        "Set New Password"
                      ) : (
                        <MDBSpinner color="light" size="sm" />
                      )}
                    </button>

                    {fogetPasswordMutation.isSuccess ? (
                      <div
                        class="alert alert-success text-center mt-2"
                        role="alert"
                      >
                        <i class="fa fa-info-circle"></i> New Password Updated
                        Successfully Please login!
                      </div>
                    ) : (
                      ""
                    )}

                    {fogetPasswordMutation.isError ? (
                      <div
                        class="alert alert-danger text-center mt-2"
                        role="alert"
                      >
                        <i class="fa fa-info-circle"></i> Sorry link expired please try again! 
                        
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
