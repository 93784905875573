import React, { useEffect, useState } from "react";
import {
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBTable,
  MDBTextArea,
} from "mdb-react-ui-kit";
import "../style/Datetime.css";
import { getImageURL } from "../../../_helper/image";
import { useDeleteFile, useUploadFile } from "../../../_helper/files";
import DocumentImg from "../../../media/document_avatar.png"

export default function InitiativeModel({
  Inshow,
  InsetShow,
  sentInitiativeData,
  reciveData,
  onChangeInitiativeData,
}) {
  const [Title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [NoEndDate, setNoEndDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [url, setUrl] = useState("");
  const [organisation_name, setOrganisationName] = useState("");
  const [doc_img, setDocImg] = useState("");
  const hiddenFileInput = React.useRef(null);

  console.log(reciveData)

  const fileMutation = useUploadFile((data, variables, context) => {
    console.log(data, "Success Data");
    onChangeInitiativeData({
        title: reciveData.title,
        description: reciveData.description,
        start_date: reciveData.start_date,
        no_end_date: reciveData.no_end_date,
        end_date: reciveData.end_date,
        organisation_name: reciveData.organisation_name,
        doc_img:data.id,
        URL: reciveData.URL,
      });
    setDocImg(data.id);
    console.log(data.id);
  });

  const fileMutationdelete = useDeleteFile((data, variables, context) => {
    console.log(data, "Success Data");
  });


  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const onFile = (eve) => {
    console.log("Got Image");
    const file = eve.target.files[0];
    console.log(file);
    if (file) {
      if (typeof file === "object" && file !== null) {
        const formData = new FormData();
        //formData.append("title", "Some title")
        formData.append("file", file);

        if (doc_img !== "") {
          console.log(doc_img);
          fileMutationdelete.mutate(doc_img);
        }

        fileMutation.mutate(formData);
      }
    }
  };

  const handleTitle = (eve) => {
    setTitle(eve.target.value);
    onChangeInitiativeData({
      title: eve.target.value,
      description: reciveData.description,
      start_date: reciveData.start_date,
      no_end_date: reciveData.no_end_date,
      end_date: reciveData.end_date,
      organisation_name: reciveData.organisation_name,
      URL: reciveData.URL,
    });
  };

  const handleDescription = (eve) => {
    setDescription(eve.target.value);
    onChangeInitiativeData({
      title: reciveData.title,
      description: eve.target.value,
      start_date: reciveData.start_date,
      no_end_date: reciveData.no_end_date,
      end_date: reciveData.end_date,
      organisation_name: reciveData.organisation_name,
      URL: reciveData.URL,
    });
  };

  const handleStartDate = (event) => {
    setStartDate(new Date(event.target.value).toISOString());
    onChangeInitiativeData({
      title: reciveData.title,
      description: reciveData.description,
      start_date: new Date(event.target.value).toISOString(),
      no_end_date: reciveData.no_end_date,
      end_date: reciveData.end_date,
      organisation_name: reciveData.organisation_name,
      URL: reciveData.URL,
    });
  };

  const handleNoEndDate = (event) => {
    setNoEndDate(new Date(event.target.value).toISOString());
    onChangeInitiativeData({
      title: reciveData.title,
      description: reciveData.description,
      start_date: reciveData.start_date,
      no_end_date: new Date(event.target.value).toISOString(),
      end_date: reciveData.end_date,
      organisation_name: reciveData.organisation_name,
      URL: reciveData.URL,
    });
  };

  const handleEndDate = (event) => {
    setEndDate(new Date(event.target.value).toISOString());
    onChangeInitiativeData({
      title: reciveData.title,
      description: reciveData.description,
      start_date: reciveData.start_date,
      no_end_date: reciveData.no_end_date,
      organisation_name: reciveData.organisation_name,
      end_date: new Date(event.target.value).toISOString(),
      URL: reciveData.URL,
    });
  };
  const handleURL = (eve) => {
    setUrl(eve.target.value);
    onChangeInitiativeData({
      title: reciveData.title,
      description: reciveData.description,
      start_date: reciveData.start_date,
      no_end_date: reciveData.no_end_date,
      end_date: reciveData.end_date,
      organisation_name: reciveData.organisation_name,
      URL: eve.target.value,
    });
  };

  const handleorganisationname = (eve) => {
    setOrganisationName(eve.target.value);
    onChangeInitiativeData({
      title: reciveData.title,
      description: reciveData.description,
      start_date: reciveData.start_date,
      no_end_date: reciveData.no_end_date,
      end_date: reciveData.end_date,
      URL: reciveData.url,
      organisation_name: eve.target.value,
    });
  };

  const InitiativeData = {
    title: Title,
    description: Description,
    start_date: StartDate,
    end_date: EndDate,
    URL: url,
    doc_img:doc_img,
    organisation_name: organisation_name,
  };

  const sent = () => {
    sentInitiativeData(InitiativeData);

    setTitle("");
    setDescription("");
    setStartDate("");
    setNoEndDate("");
    setEndDate("");
    setUrl("");
    setDocImg("");
    setOrganisationName("");
    InsetShow(!Inshow);
  };

  return (
    <>
      <MDBModal show={Inshow} setShow={InsetShow} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Add Initiative</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => InsetShow(!Inshow)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              {
                <img
                  src={reciveData.doc_img?`${getImageURL(reciveData.doc_img)}`:DocumentImg}
                  className="preview-img"
                  alt="preview"
                  style={{ width: "150px", marginLeft: "18px" }}
                />
              }
              <div className="d-flex flex-row alignitems-center mb-4 mt-3">
                <button
                  className="mb-2"
                  onClick={handleClick}
                  style={{
                    width: "auto",
                    padding: "7px",
                    borderColor: "#1b5ff2",
                    color: "#1b5ff2",
                    backgroundColor: "#ffff",
                    borderRadius: "5px",
                  }}
                  status={fileMutation.status}
                  disabled={fileMutation.isLoading}
                >
                  {" "}
                  <MDBIcon fas icon="upload" className="px-1" />
                  {fileMutation.status === "loading"
                    ? "Uploading Image..."
                    : reciveData.doc_img
                    ? "Change Initiative Document Image"
                    : "Your Initiative Document Image"}
                </button>
                <input
                  ref={hiddenFileInput}
                  onChange={onFile}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Title"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleTitle}
                  value={`${reciveData.title}`}
                  autoComplete="off"
                  autoFocus
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Associate organisation"
                  id="formControlLg"
                  type="text"
                  size="md"
                  onChange={handleorganisationname}
                  value={`${reciveData.organisation_name}`}
                  autoComplete="off"
                  autoFocus
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Start Date"
                  id="formControlLg"
                  type="date"
                  size="md"
                  onChange={handleStartDate}
                  value={`${
                    reciveData.start_date === undefined
                      ? {}
                      : reciveData.start_date.slice(0, 10)
                  }`}
                  autoComplete="off"
                />
              </div>

              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="End Date"
                  id="formControlLg"
                  type="date"
                  size="md"
                  onChange={handleEndDate}
                  value={`${
                    reciveData.end_date === undefined
                      ? {}
                      : reciveData.end_date.slice(0, 10)
                  }`}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3">
                <MDBTextArea
                  wrapperClass="mb-4 w-100"
                  label="Description"
                  id="formControlLg"
                  type="text"
                  size="md"
                  rows={4}
                  onChange={handleDescription}
                  value={`${reciveData.description}`}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-row alignitems-center mb-3 form-outline">
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="URL"
                  id="formControlLg"
                  type="url"
                  size="md"
                  onChange={handleURL}
                  value={`${reciveData.URL}`}
                  autoComplete="off"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "#1b5ff2",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={sent}
              >
                <MDBIcon fas icon="save" className="px-1" />
                Save
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
