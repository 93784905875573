import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardSubTitle,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../media/avatar2.jpeg";
import {
  useApproveReference,
  useUpdateReference,
} from "../../../_helper/ApplyMembershipAction";


export default function RefereceCardApproval({
  mname,
  img,
  email,
  current_city,
  nativeVillage,
  nativeDistrict,
  lcciaRole,
  tag,
  docData = [],
  id,
  rstatus,
}) {
  const [rid, setrid] = useState(id);
  const navigate = useNavigate();

  //console.log(arr)
  const status = () => {
    console.log(docData)
    const arr = docData.map((element, idx) =>
    
      element.status === "draft" || element.status === "rejected" || element.status === "approve" ? false : true
    );
    return arr.some((item)=>item===true);
    //console.log(arr)
  };

  const mutation = useApproveReference(rid);

  function OnApproveUser() {
    mutation.mutate({ status: "approved" });
    //console.log(id)
  }

  return (
    <>
      <MDBContainer fluid>
        <MDBRow className="">
          <MDBCard
            style={{
              width: "100%",
              margin: "10px",
              padding: "15px",
            }}
          >
            <MDBRow className="lg-3">
              <MDBCol sm="3">
                <MDBCardImage
                  src={img !== " " ? img : Avatar}
                  alt="..."
                  fluid
                  style={{
                    borderRadius: "5px",
                    width: "100%",
                  }}
                />
              </MDBCol>
              <MDBCol xl="1"></MDBCol>
              <MDBCol sm="4">
                <MDBCardBody style={{ padding: "6px" }}>
                  <MDBCardTitle>
                    {" "}
                    {`${mname}`} <br></br>
                    <MDBCardSubTitle>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "350",
                          color: "#808080",
                          marginTop: "10px",
                        }}
                      >{`${nativeVillage}, ${nativeDistrict}`}</p>
                    </MDBCardSubTitle>
                  </MDBCardTitle>

                  <MDBCardText>
                    <div className="row py-1">
                      <div className="col-1">
                        <MDBIcon
                          fas
                          icon="location"
                          style={{ color: "#F58A2E" }}
                          size="sm"
                        />
                      </div>
                      <div className="col"> {`${current_city}`}</div>
                    </div>

                    <div className="row ">
                      <div className="col-1">
                        <MDBIcon
                          fas
                          icon="envelope"
                          size="sm"
                          style={{ color: "#F58A2E" }}
                        />
                      </div>

                      <div className="col"> {`${email}`}</div>
                    </div>

                    <div className="row py-1">
                      <div className="col-1">
                        <MDBIcon
                          fas
                          icon="user"
                          size="sm"
                          style={{ color: "#F58A2E" }}
                        />
                      </div>{" "}
                      <div className="col">
                        <span className="">{`${lcciaRole}`}</span>
                      </div>
                    </div>

                    {tag === "" || tag === null || tag === undefined ? (
                      ""
                    ) : (
                      <div className="row py-1">
                        <div className="col-1">
                          <MDBIcon
                            fas
                            icon="user-tag"
                            size="sm"
                            style={{ color: "#F58A2E" }}
                          />
                        </div>{" "}
                        <div className="col">
                          <span className="">{`${tag}`}</span>
                        </div>
                      </div>
                    )}

                    <div className="">
                      <button
                        className="mt-2"
                        style={{
                          color: "white",
                          backgroundColor: `${
                            status() === true ? "#F58A2E" : "#e3cfc1"
                          }`,
                          borderRadius: "3px",
                          border: "none",
                        }}
                        onClick={() => {
                          setrid(id);
                          OnApproveUser(id);
                        }}
                        disabled={status() === true ? false : true}
                      >
                        {rstatus !== null ? "Already Approved" : "Approve User"}
                      </button>
                      {mutation.isSuccess?navigate('/profile/setting'):""}
                      {!status() ? (
                        <p
                          className="mt-1"
                          style={{
                            color: "red",
                            fontSize: "14px",
                          }}
                        >
                          {rstatus === null
                            ? "*User's Document Verification is not compeleted from admin side, Once it done approve button will enable."
                            : ""}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </MDBCardText>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    </>
  );
}
