import React from 'react'

export default function CertificateInformation({ allCertificatevalue }) {
    console.log(allCertificatevalue)
    return (
        <div>
                      {
                    !allCertificatevalue  || allCertificatevalue?.length === 0 ?
                    ""
              
                    :
                    <h4
                    style={{

                        marginTop: "20px",
                        fontWeight: "500",
                        color: "#8A8A8A"

                    }}>
                    Certificates Information</h4>
}

            {
                allCertificatevalue === null || undefined ? [] : allCertificatevalue.map((value, idx) => (
                    <div>
                        <h4
                            style={{
                                marginTop: "10px",
                                fontSize: "16px",
                                fontWeight: "450",

                            }}
                        >
                            {value.name}


                        </h4>

                        <p
                            style={{

                                fontSize: "14px",
                                fontWeight: "400",
                                
                            }}
                        > {value.issuing_organization}
                            <br></br>
                           
                            <h6
                                style={{
                                    marginTop: "5px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    
                                }}
                            >
                                 { new Date(`${value.issue_date.slice(0,10)}`).toLocaleString('en-us',{month:'short', year:'numeric'})} - { new Date(`${value.expiration_date.slice(0,10)}`).toLocaleString('en-us',{month:'short', year:'numeric'})}
                                 <span> {value.certification_id}</span>
                            </h6>
                            <a href={value.URL}>{value.URL}</a>
                        </p>
                      


                    </div>

                ))
            }

        </div>
    )
}
