import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow } from 'mdb-react-ui-kit'
import React, { useState } from 'react'
import { useChangePassword } from '../../../_helper/actions';

export default function ChangePassword() {
    const [nPassword,setnPassword] = useState("");
    const [cPassword,setcPassword] = useState("");


    const handleNewPassword = (eve) =>{
        setnPassword(eve.target.value)
    }

    const handleConfirmPassword = (eve) =>{
        setcPassword(eve.target.value)
    }

    const passwordMutation = useChangePassword(() => {
        console.log("Password Changed");

       
    });


    const OnSubmitPassword = () =>{

        if (nPassword !== cPassword){

        }else{

            passwordMutation.mutate({"password":nPassword})
        }
        
    }


    return (
        <>
            <h4 style={{
                fontSize: "22px",
                marginTop: "40px"
            }}>
                Change Password
            </h4>

            <MDBContainer fluid>
                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>

                        <MDBCard className='bg-white my-2 '>
                            <MDBCardBody className='p-5 w-100 d-flex flex-column'>
                                <div className="d-flex flex-row alignitems-center mb-4">

                                    <MDBInput
                                        wrapperClass='mb-2 w-100'
                                        label='New Password'
                                        id='formControlLg'
                                        type='password'
                                        size="md"
                                        value={`${nPassword}`}
                                        onChange={handleNewPassword}
                                        autoComplete='off'
                                        autoFocus
                                    />
                              



                                </div>
                                <div className="d-flex flex-row align-items-center mb-4">

                                    <MDBInput
                                        wrapperClass='mb-2 w-100'
                                        label='Confirm Password'
                                        id='formControlLg'
                                        type='password'
                                        size="md"
                                        value={`${cPassword}`}
                                        onChange={handleConfirmPassword}
                                    />

                                </div>

                           

                                < button
                                    className='mt-4 py-1'
                                    style={{ width: "12rem", color: "white", backgroundColor: "#F58A2E", borderRadius: "5px", border: 'none' }}
                                    onClick={OnSubmitPassword}
                                    >
                                    <MDBIcon fas icon='key' className='px-1' />
                                    Change Password

                                </ button >
                                {
                                    passwordMutation.isSuccess ? <div class="alert alert-success text-center mt-2" role="alert">
                                    <i class="fa fa-info-circle"></i> Password changed Successfully!
                                </div> :""
                                }




                            </MDBCardBody>

                        </MDBCard>


                    </MDBCol>

                </MDBRow>

            </MDBContainer>

        </>
    )
}
