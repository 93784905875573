import React from 'react'

export default function PatentInformation({ allPatentsData }) {
    console.log(allPatentsData)
    return (
        <div>
            {
                    allPatentsData === undefined || allPatentsData ===  null || allPatentsData?.length === 0 ?
                    ""
              
                    :
                    <h4
                    style={{

                        marginTop: "20px",
                        fontWeight: "500",
                        color: "#8A8A8A"

                    }}>
                    Patents Information</h4>
}


            {
                allPatentsData === null || undefined ? [] : allPatentsData.map((value, idx) => (
                    <div>
                        <h4
                            style={{
                                marginTop: "10px",
                                fontSize: "16px",
                                fontWeight: "450",

                            }}
                        >
                            {value.patent_title}


                        </h4>

                        <p
                            style={{

                                fontSize: "14px",
                                fontWeight: "400",
                               
                            }}
                        > {value.inventor_name}
                            <br></br>
                            <a href={value.URL}>{value.URL}</a>
                        </p>

                        <p style={{
                            fontSize: "14px",
                            justifyContent: "flex-start",
                            marginRight: "5px",
                            marginTop: "10px"


                        }}>
                           {value.description}
                        </p>
                    </div>

                ))
            }

        </div>
    )
}
