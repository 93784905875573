import React from 'react'
import { useMutation } from 'react-query';
import { secureAxios } from './auth';
import { queryClient } from './query';

export const usePostDocumentType = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/document_type/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document_type"]);
            },
        }
    );
};

export const usePostChapter = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/chapters/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "document_type"]);
            },
        }
    );
};

export const usePostTags = () => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .post(`/items/tags/`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "tags"]);
            },
        }
    );
};

export const useUpdateDocumentType = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/document_type/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "chapters"]);
            },
        }
    );
};

export const useUpdateChapter = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/chapters/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "chapters"]);
            },
        }
    );
};

export const useUpdateTag = (id) => {
    return useMutation(
        async(data) => {
            return await secureAxios
                .patch(`/items/tags/${id}`, data)
                .then((res) => res.data);
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["items", "tags"]);
            },
        }
    );
};

