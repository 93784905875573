import React from "react";

export default function IncentiveInformation({ allInitiativevalue }) {
  //console.log(allInitiativevalue)
  return (
    <div>
      {allInitiativevalue === undefined ||
      allInitiativevalue === null ||
      allInitiativevalue?.length === 0 ? (
        ""
      ) : (
        <h4
          style={{
            marginTop: "20px",
            fontWeight: "500",
            color: "#8A8A8A",
          }}
        >
          Initiatives
        </h4>
      )}

      {allInitiativevalue === null || undefined
        ? []
        : allInitiativevalue.map((value, idx) => (
            <div>
              <h4
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "450",
                }}
              >
                {value.title}
              </h4>

              <p>
                <h6
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "400",
                   
                  }}
                >
                  {/* {new Date(`${value.start_date.slice(0, 10)}`).toLocaleString('en-us', { month: 'short', year: 'numeric' })} - {new Date(`${value.end_date.slice(0, 10)}`).toLocaleString('en-us', { month: 'short', year: 'numeric' })} */}
                  {new Date(`${value.start_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? ""
                    : new Date(
                        `${value.start_date.slice(0, 10)}`
                      ).toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                      })}{"- "}
                   {" "}
                  {new Date(`${value.end_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? new Date(`${value.start_date.slice(0, 10)}`).toLocaleString(
                      "en-us",
                      { month: "short", year: "numeric" }
                    ) === "Invalid Date"
                      ? "":"Present"
                    : new Date(`${value.end_date.slice(0, 10)}`).toLocaleString(
                        "en-us",
                        { month: "short", year: "numeric" }
                      )}
                  <br></br> <a href={value.URL}>{value.URL}</a>
                </h6>
              </p>

              <p
                style={{
                  fontSize: "14px",
                  justifyContent: "flex-start",
                  marginRight: "5px",
                  marginTop: "10px",
                }}
              >
                {value.description}
              </p>
            </div>
          ))}
    </div>
  );
}
