import { MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import EditInfo from "./BusinessProfileComponents/EditInfo";

export default function EditBusinessProfile() {
  const navigate = useNavigate();

  return (
    <>
      <div className="d-flex">
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}>Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}>My Profile</a>
          </MDBBreadcrumbItem>

          <MDBBreadcrumbItem active>
            <a
              style={{
                cursor: "default",
              }}
            >
              Edit Business Profile
            </a>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </div>
      <EditInfo />
    </>
  );
}
