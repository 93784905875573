import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardLink,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardSubTitle,
  MDBIcon,
} from "mdb-react-ui-kit";
import useScrollPosition from "./ViewPost/useScrollPosition";
import { getImageURL } from "../../../_helper/image";

export default function ProfileCard({
  usersData,
  mname,
  expire_date,
  img,
  email,
  current_city,
  LCCIADesignation,
  nativeVillage,
  nativeDistrict,
  lcciaRole,
  tag,
  chapter,
}) {
  const [data, setData] = useState({});
  const [curr_pos, setCurr_pos] = useState(0);
  const [curr_idx, setCurrIdx] = useState(0);
  const [prevcurr, setPrevCurrent] = useState(0);
  const [trackscroll, setTrackScroll] = useState(0);

  console.log("USERSSS", usersData);

  var q = new Date();
  var m = q.getMonth() + 1;
  var d = q.getDay();
  var y = q.getFullYear();

  var date = new Date();
  let mydate = new Date(
    `${
      data?.user_created?.profile[0]?.membership_valid_till === null
        ? ""
        : `${data?.user_created?.profile[0]?.membership_valid_till}`.slice(
            0,
            10
          )
    }`
  );

  const myscrollpostion = useScrollPosition();

  //console.log(usersData)

  useEffect(() => {
    //setData(usersData[curr_idx])
    // console.log(curr_pos)

    if (myscrollpostion >= curr_pos && curr_pos - myscrollpostion <= 400) {
      if (usersData.length > 0 && curr_idx < usersData.length) {
        setCurr_pos(curr_pos + 400);

        setCurrIdx(curr_idx + 1);
        setData(usersData[curr_idx]);
      }
    }
  }, [myscrollpostion, usersData, data]);

  return (
    <MDBCard
      style={{ padding: "5px", border: "1px solid #e0e0e0", boxShadow: "none" }}
    >
      <div className="text-center mb-1">
        <MDBCardImage
          position="top"
          alt="..."
          src={`${getImageURL(data?.user_created?.avatar)}`}
          style={{ width: "13rem", height: "12rem", marginTop: "1rem" }}
        />
      </div>
      <MDBCardBody className="m-0 p-4">
        <MDBCardTitle>
          {`${data?.user_created?.first_name} ${data?.user_created?.middle_name} ${data?.user_created?.last_name}`}{" "}
          <br></br>
          {console.log(data?.user_created?.first_name)}
          <MDBCardSubTitle>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "350",
                color: "#808080",
                marginTop: "10px",
              }}
            >{`${`${
              data?.user_created?.profile[0]?.native_village === null ||
              data?.user_created?.profile[0]?.native_village === undefined
                ? ""
                : data?.user_created?.profile[0]?.native_village
            }`} ${`${
              data?.user_created?.profile[0]?.native_district === null ||
              data?.user_created?.profile[0]?.native_district === undefined
            }`}`}</p>
          </MDBCardSubTitle>
        </MDBCardTitle>

        <MDBCardText>
          {data?.user_created?.profile[0]?.current_city ? (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  fas
                  icon="location"
                  style={{ color: "#F58A2E" }}
                  size="sm"
                />
              </div>
              <div className="col">
                {" "}
                {`${
                  data?.user_created?.profile[0]?.current_city === null ||
                  data?.user_created?.profile[0]?.current_city === undefined
                    ? ""
                    : data?.user_created?.profile[0]?.current_city
                }`}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row ">
            <div className="col-1">
              <MDBIcon
                fas
                icon="envelope"
                size="sm"
                style={{ color: "#F58A2E" }}
              />
            </div>

            <div className="col">
              {" "}
              {`${
                data?.user_created?.email === null ||
                data?.user_created?.email === undefined
                  ? ""
                  : data?.user_created?.email
              }`}
            </div>
          </div>

          <div className="row py-1">
            <div className="col-1">
              <MDBIcon fas icon="user" size="sm" style={{ color: "#F58A2E" }} />
            </div>{" "}
            <div className="col">
              <span className="">{`${
                data?.LCCIA_Designation ? data?.LCCIA_Designation : "Member"
              }`}</span>
            </div>
          </div>

          {data?.user_created?.profile[0]?.tag === "" ||
          data?.user_created?.profile[0]?.tag === null ||
          data?.user_created?.profile[0]?.tag === undefined ? (
            ""
          ) : (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  fas
                  icon="user-tag"
                  size="sm"
                  style={{ color: "#F58A2E" }}
                />
              </div>{" "}
              <div className="col">
                <span className="">{`${
                  data?.user_created?.profile[0]?.tag === null
                    ? ""
                    : data?.user_created?.profile[0]?.tag
                }`}</span>
              </div>
            </div>
          )}

          {data?.user_created?.profile[0]?.chapter === null ||
          data?.user_created?.profile[0]?.chapter === undefined ? (
            ""
          ) : (
            <div className="row py-1">
              <div className="col-1">
                <MDBIcon
                  size="sm"
                  fas
                  icon="book"
                  style={{ color: "#F58A2E" }}
                />
              </div>
              <div className="col">
                <span>{`${data?.user_created?.profile[0]?.chapter?.name}`}</span>
              </div>
            </div>
          )}
        </MDBCardText>

        <h5
          style={{
            fontSize: "18px",
          }}
        >
          <span
            class="badge"
            style={{
              backgroundColor: "#F58A2E",
            }}
          >
            {`${
              data?.user_created?.role?.name === null || undefined
                ? ""
                : data?.user_created?.role?.name
            }`}
          </span>
          <span
            className="badge mx-2"
            style={{
              backgroundColor: `${date <= mydate ? `#35bd52` : "#f52222"}`,
            }}
          >
            {date <= mydate ? `Active` : "Inactive"}
          </span>
        </h5>
      </MDBCardBody>
    </MDBCard>
  );
}
