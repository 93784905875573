import { MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit'
import React from 'react'
import { useDeleteMembership } from '../../../_helper/MembershipAction'

export default function DeleteMembership({opendeletemodel,setopendeletemodel,id ,status}) {

  const mutation  =  useDeleteMembership(id)

  const deleteData = () => { 
    mutation.mutate()
    status(true)
    setopendeletemodel(!opendeletemodel)
    
  }

  return (
    <>
    <MDBModal show={opendeletemodel} setShow={setopendeletemodel} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete Plan</MDBModalTitle>
              <button
                className="btn-close"
                color="none"
                onClick={() => setopendeletemodel(!opendeletemodel)}
                X
              ></button>
            </MDBModalHeader>
            <MDBModalBody>
              Are you want to delete it?
            </MDBModalBody>
            <MDBModalFooter>
             

              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "blue",
                  borderRadius: "5px",
                  border: "none",
                }}
                //onClick={SubmitEditedData}
              >
             
                  <>
                    <MDBIcon fas icon="" className="px-1" />
                    No
                  </>
              
              </button>

              <button
                className="mt-4 py-1"
                style={{
                  width: "8rem",
                  color: "white",
                  backgroundColor: "red",
                  borderRadius: "5px",
                  border: "none",
                }}
                onClick={deleteData}
              >
             
                  <>
                    <MDBIcon fas icon="trash" className="px-1" />
                    Yes
                  </>
              
              </button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  )
}
