import React from "react";

export default function EducationInformation({ value = [] }) {
  return (
    <>
      <div>
        {value === undefined || value === null || value?.length === 0 ? (
          ""
        ) : (
          <h4
            style={{
              marginTop: "20px",
              fontWeight: "500",
              color: "#8A8A8A",
            }}
          >
            Education Information
          </h4>
        )}

        {value === null || undefined
          ? []
          : value.map((data, idx) => (
              <div>
                <h4
                  style={{
                    marginTop: "10px",
                    fontSize: "16px",
                    fontWeight: "450",
                  }}
                >
                  {data.school}
                  {console.log(data)}
                </h4>

                <h6
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "400",
                  
                  }}
                >
                  {/* { new Date(`${data.start_date.slice(0,10)}`).toLocaleString('en-us',{month:'short', year:'numeric'})} - { new Date(`${data.end_date.slice(0,10)}`).toLocaleString('en-us',{month:'short', year:'numeric'})} */}
                  {new Date(`${data.start_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? ""
                    : new Date(
                        `${data.start_date.slice(0, 10)}`
                      ).toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                      })}{" "}
                  -{" "}
                  {new Date(`${data.end_date.slice(0, 10)}`).toLocaleString(
                    "en-us",
                    { month: "short", year: "numeric" }
                  ) === "Invalid Date"
                    ? "Present"
                    : new Date(`${data.end_date.slice(0, 10)}`).toLocaleString(
                        "en-us",
                        { month: "short", year: "numeric" }
                      )}
                </h6>
                <h6
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: "400",
                   
                  }}
                >
                  {data.degree} - {data.field_of_study}
                </h6>

                <p
                  style={{
                    fontSize: "14px",
                    justifyContent: "flex-start",
                    marginRight: "5px",
                  }}
                >
                  {data.activities}
                </p>
              </div>
            ))}
      </div>
    </>
  );
}
