import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBSpinner,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  resetTokens,
  restoreRefreshToken,
  secureAxios,
  setAuthToken,
} from "../../_helper/auth";
import { getImageURL } from "../../_helper/image";
import Card from "./Card";
import Search from "./search/Search";

export default function AllMember() {
  const [membersdata, setMembersData] = useState([]);
  const [memberoutputdata, setMemberOutputData] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  const navigate = useNavigate();

  const getMeData = async () => {
    await secureAxios
      .get("/users/me?fields=*.*")
      .then((data) => {
        localStorage.setItem("user_id", data?.data?.profile[0].id);
        localStorage.setItem(
          "business_id",
          data?.data?.business_profiles[0].id
        );
        localStorage.setItem("me_id", data?.data?.id);
        localStorage.setItem("gender", data?.data?.profile[0].gender);

        if (!localStorage.getItem("access_token")) {
          localStorage.getItem("user_id", data?.data?.profile[0].id);
          localStorage.getItem(
            "business_id",
            data?.data?.business_profiles[0].id
          );
        }

        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        resetTokens();
        navigate("/login");
        return <Navigate to="/login" />;
      });
  };

  const getData = async () => {
    await secureAxios
      .get(
        "/users?fields=*,role.name,profile.*,business_profiles.business_name&filter[hidden][_eq]=false&filter[status][_eq]=active&limit=-1"
      )
      .then((resp) => {
        setMembersData(resp.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getMeData();
  }, []);

  const outputdata = (data) => {
    if (data.length == 0) {
      setMemberOutputData(membersdata);
    } else {
      setMemberOutputData(data);
    }

    //setMembersData([...membersdata,res.document])
  };

  return (
    <>
      <MDBContainer
        fluid
        style={{
          marginLeft: "5px",
        }}
      >
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}> Home</a>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <a style={{ cursor: "default" }}>All Members</a>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>

        <div>
          <h4>All Members</h4>
        </div>

        <div className="d-flex flex-row alignitems-center mb-4">
          <div className="" style={{ marginLeft: "auto" }}>
            <Search inputdata={membersdata} outputdata={outputdata} />
          </div>
        </div>
      </MDBContainer>
      {isLoading ? (
        <div className="text-center">
          <MDBSpinner color="success" size="xl" />
        </div>
      ) : (
        <MDBContainer fluid style={{ marginBottom: "30px" }}>
          <MDBRow>
            {memberoutputdata?.map((value, idx) => (
              // <div className=''
              // onClick={()=>{
              //     navigate(`/profile/${value.id}`)
              // }}>
              <MDBCol sm={6}>
                <Card
                  name={
                    value.user_type === "organisation"
                      ? `${value.business_profiles[0].business_name}`
                      : `${value.first_name === null ? "" : value.first_name} ${
                          value.middle_name === null ? "" : value.middle_name
                        } ${value.last_name === null ? "" : value.last_name}`
                  }
                  img={`${
                    value.avatar === null ? " " : getImageURL(value.avatar)
                  }`}
                  role={`${value.role?.name === null ? "" : value.role?.name}`}
                  current_city={`${
                    value?.profile[0]?.current_city === null
                      ? " "
                      : value?.profile[0]?.current_city
                  }`}
                  email={`${value.email === null ? " " : value.email}`}
                  expire_date={`${
                    value?.profile[0]?.membership_valid_till === null
                      ? " "
                      : value?.profile[0]?.membership_valid_till
                  }`}
                  onClick={() => {
                    localStorage.setItem("user_bid", value?.id);
                    // value.user_type === 'organisation'?navigate(`/profile/organisation/${value?.profile[0]?.id}`):
                    navigate(`/profile/${value?.profile[0]?.id}`);
                  }}
                  lcciaRole={`${
                    value?.profile[0]?.LCCIA_Designation === null ||
                    value?.profile[0]?.LCCIA_Designation === undefined ||
                    value?.profile[0]?.LCCIA_Designation === ""
                      ? value.user_type === "organisation"
                        ? "Organisation"
                        : "Member"
                      : value?.profile[0]?.LCCIA_Designation
                  }`}
                />
              </MDBCol>
              // </div>
            ))}
          </MDBRow>
        </MDBContainer>
      )}
    </>
  );
}
