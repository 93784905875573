import React from 'react'
import Login from './login'

export default function Index() {
  return (
    <>
    <Login/>
       </>)

}
