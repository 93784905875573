import React from "react";

export default function ImagePost({img_src}) {
  return (
    <>
      <img
        className="w-100 h-100  "
        src={img_src}
      />
    </>
  );
}
